import React from 'react';
import styled from 'styled-components/macro';

interface IconProps {
  color?: string;
  size?: string;
  name: string;
}

const StyledIcon = styled.i<IconProps>`
  color: ${props => (props.color ? props.color : 'inherit')};
  font-size: ${props => (props.size ? props.size : 'inherit')};
  display: inline-flex;
  padding: 0px;
`;

const Icon: React.FC<IconProps> = ({ name, size, ...rest }) => (
  <StyledIcon className="material-icons" size={size} {...{ ...rest, name }}>
    {name}
  </StyledIcon>
);

export default Icon;
