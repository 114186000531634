import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import format from 'date-fns/format';
import commonEn from './en/common.json';
import commonEs from './es/common.json';
import commonCn from './cn/common.json';
import formsEn from './en/forms.json';
import formsEs from './es/forms.json';
import formsCn from './cn/forms.json';
import caseOverviewEn from './en/caseOverview.json';
import caseOverviewEs from './es/caseOverview.json';
import caseOverviewCn from './cn/caseOverview.json';
import resultsEn from './en/results.json';
import resultsEs from './es/results.json';
import resultsCn from './cn/results.json';
import tooltipsEn from './en/tooltips.json';
import tooltipsEs from './es/tooltips.json';
import tooltipsCn from './cn/tooltips.json';

i18n.use(initReactI18next).init({
  lng: typeof window !== 'undefined' ? localStorage.getItem('lang') : undefined,
  fallbackLng: 'en',
  ns: {
    namespaces: ['common', 'caseOverview', 'forms', 'tooltips'],
    defaultNS: 'common',
  },
  resources: {
    en: {
      common: commonEn,
      forms: formsEn,
      caseOverview: caseOverviewEn,
      results: resultsEn,
      tooltips: tooltipsEn,
    },
    es: {
      common: commonEs,
      forms: formsEs,
      caseOverview: caseOverviewEs,
      results: resultsEs,
      tooltips: tooltipsEs,
    },
    cn: {
      common: commonCn,
      forms: formsCn,
      caseOverview: caseOverviewCn,
      results: resultsCn,
      tooltips: tooltipsCn,
    },
  },
  interpolation: {
    escapeValue: false,
    format: (value, formatting) => {
      if (value instanceof Date) {
        return format(value, formatting);
      }
      return value;
    },
  },
});

export default i18n;
