import gql from 'graphql-tag';

export const ASSIGN_EXPERT = gql`
  mutation update_expert($caseId: Int, $expertId: Int) {
    update_case(
      where: { id: { _eq: $caseId } }
      _set: { expertId: $expertId }
    ) {
      returning {
        id
        expertId
      }
    }
  }
`;

export const UPDATE_CASE_STATUS = gql`
  mutation update_case_status($caseStatus: caseStatus_enum, $caseId: Int) {
    update_case(
      where: { id: { _eq: $caseId } }
      _set: { status: $caseStatus }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_CASE = gql`
  mutation add_case(
    $objects: [case_insert_input!]!
    $updateColumns: [case_update_column!]!
    $removeConcavePiece: concavePiece_bool_exp!
    $noConcave: Boolean!
    $removeMantlePiece: mantlePiece_bool_exp!
    $noMantle: Boolean!
  ) {
    delete_concavePiece(where: $removeConcavePiece) @include(if: $noConcave) {
      returning {
        id
      }
    }
    delete_mantlePiece(where: $removeMantlePiece) @include(if: $noMantle) {
      returning {
        id
      }
    }
    insert_case(
      objects: $objects
      on_conflict: { constraint: case_pkey, update_columns: $updateColumns }
    ) {
      returning {
        id
        name
        partSets {
          id
          solution
          supplierName
          totalCrushingCapacity
          crusherWearLifePerConcaveSetTons
          crusherWearLifePerConcaveSetHours
        }
      }
    }
  }
`;

export const UPSERT_LIGHT_CASE = gql`
  mutation upsertLightCase(
    $objects: [case_insert_input!]!
    $updateColumns: [case_update_column!]!
  ) {
    insert_case(
      objects: $objects
      on_conflict: { constraint: case_pkey, update_columns: $updateColumns }
    ) {
      returning {
        id
        name
      }
    }
  }
`;

export const ADD_SOLUTION = gql`
  mutation add_or_update_solution(
    $objects: [case_insert_input!]!
    $updateColumns: [case_update_column!]!
    $removeConcavePiece: concavePiece_bool_exp!
    $noConcave: Boolean!
    $removeMantlePiece: mantlePiece_bool_exp!
    $noMantle: Boolean!
  ) {
    delete_concavePiece(where: $removeConcavePiece) @include(if: $noConcave) {
      returning {
        id
      }
    }
    delete_mantlePiece(where: $removeMantlePiece) @include(if: $noMantle) {
      returning {
        id
      }
    }
    insert_case(
      objects: $objects
      on_conflict: { constraint: case_pkey, update_columns: $updateColumns }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation removeAttachment($where: attachment_bool_exp!) {
    delete_attachment(where: $where) {
      affected_rows
    }
  }
`;

export const DELETE_CASE = gql`
  mutation deleteCase($caseIds: case_bool_exp!) {
    delete_case(where: $caseIds) {
      returning {
        id
      }
    }
  }
`;

export const UPSERT_CASE = gql`
  mutation upsert_case(
    $objects: [case_insert_input!]!
    $updateColumns: [case_update_column!]!
  ) {
    insert_case(
      objects: $objects
      on_conflict: { constraint: case_pkey, update_columns: $updateColumns }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPSERT_FOLLOW_UP = gql`
  mutation upsertFollowUp(
    $objects: [followUp_insert_input!]!
    $onConflict: followUp_on_conflict
  ) {
    insert_followUp(objects: $objects, on_conflict: $onConflict) {
      returning {
        id
        measuredCapacity
        installationDate
        measuredWearLifeHours
        removalDate
        measuredWearLifeTonnes
        mantleChangeDate
        target
        feedback
        attachments {
          url
          size
          originalname
          mimetype
          id
          container
          blob
        }
        cases {
          id
        }
      }
    }
  }
`;

export const ASSIGN_FOLLOW_UP_TO_CASE = gql`
  mutation assignFollowUpToCase($caseId: Int!, $followUpId: Int!) {
    update_case(
      where: { id: { _eq: $caseId } }
      _set: { followUpId: $followUpId }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_MINING_PRODUCT = gql`
  mutation deleteMiningProduct($id: Int!) {
    delete_miningProduct(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPSERT_SETTINGS = gql`
  mutation upsertSettings(
    $object: settings_insert_input!
    $updateColumns: [settings_update_column!]!
  ) {
    insert_settings_one(
      object: $object
      on_conflict: { constraint: settings_pkey, update_columns: $updateColumns }
    ) {
      id
    }
  }
`;

export const ADD_SETTINGS_TO_CASE = gql`
  mutation addSettingsToCase($caseId: Int!, $settingsId: Int!) {
    update_case_by_pk(
      pk_columns: { id: $caseId }
      _set: { settingsId: $settingsId }
    ) {
      id
    }
  }
`;
