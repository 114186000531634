export const abrasivenessData = [
  {
    label: 'non abrasive',
    labelIntl: 'tooltips:nonAbrasive',
    value: '0-100',
    bgColor: 'yellow',
  },
  {
    label: 'slightly abrasive',
    labelIntl: 'tooltips:slightlyAbrasive',
    value: '100-600',
    bgColor: '#FFC31E',
  },
  {
    label: 'medium abrasive',
    labelIntl: 'tooltips:mediumAbrasive',
    value: '600-1200',
    bgColor: 'orange',
  },
  {
    label: 'abrasive',
    labelIntl: 'tooltips:abrasive',
    value: '1200-1700',
    bgColor: 'red',
    color: 'white',
  },
  {
    label: 'very abrasive',
    labelIntl: 'tooltips:veryAbrasive',
    value: '-20',
    bgColor: 'black',
    color: 'white',
  },
];

export const crushabilityData = [
  {
    label: 'very easy',
    labelIntl: 'tooltips:veryEasy',
    value: '50-',
    bgColor: 'yellow',
  },
  {
    label: 'easy',
    labelIntl: 'tooltips:easy',
    value: '40-50',
    bgColor: '#FFC31E',
  },
  {
    label: 'medium',
    labelIntl: 'tooltips:medium',
    value: '30-40',
    bgColor: 'orange',
  },
  {
    label: 'difficult',
    labelIntl: 'tooltips:difficult',
    value: '20-30',
    bgColor: 'red',
    color: 'white',
  },
  {
    label: 'very difficult',
    labelIntl: 'tooltips:veryDifficult',
    value: '-20',
    bgColor: 'black',
    color: 'white',
  },
];

export const bondWorkIndexData = [
  {
    label: 'very easy',
    labelIntl: 'tooltips:veryEasy',
    value: '0-7',
    bgColor: 'yellow',
  },
  {
    label: 'easy',
    labelIntl: 'tooltips:easy',
    value: '7-10',
    bgColor: '#FFC31E',
  },
  {
    label: 'medium',
    labelIntl: 'tooltips:medium',
    value: '10-14',
    bgColor: 'orange',
  },
  {
    label: 'difficult',
    labelIntl: 'tooltips:difficult',
    value: '14-18',
    bgColor: 'red',
    color: 'white',
  },
  {
    label: 'very difficult',
    labelIntl: 'tooltips:veryDifficult',
    value: '18-',
    bgColor: 'black',
    color: 'white',
  },
];

export const co2EmissionData = {
  src: '/carbon-footprint-travel-mode.png',
  width: '500px',
};
