import React, { useEffect } from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components/macro';
import { Box, Flex } from 'rebass';
import idx from 'idx';
import { useTranslation } from 'react-i18next';

import { Field as FieldInterface } from '../../../ts/interfaces';
import { Case, MantlePiece } from '../../../generated/graphql';

const propTypes = {
  input: shape({}).isRequired,
  arrayFields: arrayOf(shape({})).isRequired,
  label: string.isRequired,
  values: shape({}).isRequired,
};

const SectionTitle = styled.p`
  align-self: center;
  letter-spacing: 1.5px;
  font-size: ${({ theme }) => theme.typography.fontSizeH4};
  font-weight: bolder;
  white-space: nowrap;
`;

const FlexWithBorder = styled(Flex)<{ borderBottom?: boolean }>`
  border-top: 1px solid
    ${({ borderBottom, theme }) =>
      borderBottom ? 'transparent' : theme.colors.divider};
  border-bottom: 1px solid
    ${({ borderBottom, theme }) =>
      borderBottom ? theme.colors.divider : 'transparent'};
`;

interface MantleArrayInputCC {
  input: { name: string; value: any; onChange: any };
  label: string;
  arrayFields: FieldInterface[];
  values: Case;
  currency?: string;
  disabled?: boolean;
}
const MantleArrayInput = ({
  input: { name, value, onChange },
  label,
  arrayFields,
  values: { currency, partSets },
  disabled,
  ...rest
}: MantleArrayInputCC) => {
  const currentNumberOfMantlePieces = idx(
    partSets,
    _ => _[0].numberOfMantlePieces,
  );

  const { t } = useTranslation('forms');

  useEffect(() => {
    if (currentNumberOfMantlePieces === 1) {
      onChange(
        value?.length
          ? value.filter((piece: MantlePiece) => piece.type !== 'upper')
          : [{ type: 'lower' }],
      );
    }
    if (currentNumberOfMantlePieces === 2) {
      if (!value?.length) {
        onChange([{ type: 'upper' }, { type: 'lower' }]);
        return;
      }
      if (value?.length === 2) {
        return;
      }
      onChange([{ type: 'upper' }, ...value]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNumberOfMantlePieces]);

  return (
    <FieldArray name={name} subscription={{}}>
      {({ fields }) =>
        fields.map((cname, index) => (
          <FlexWithBorder
            width={1}
            flexWrap="wrap"
            key={cname}
            pt={3}
            alignItems="center"
            flexDirection={['column', 'column', 'row']}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
          >
            <Box width={[1, 1, 1, 1]}>
              <SectionTitle>
                {fields?.length === 2 && index === 0
                  ? t('forms:labels.upper', 'Upper')
                  : t('forms:labels.lower', 'Lower')}{' '}
                {label}
              </SectionTitle>
            </Box>
            <Flex width={1} flexWrap="wrap" mx={[0, -2]}>
              {arrayFields.map(
                ({
                  name: fieldName,
                  label: fieldLabel,
                  labelIntl: fieldLabelIntl,
                  endAdornment,
                  ...i
                }) => (
                  <Box
                    key={i.id || fieldName}
                    p={[0, 2]}
                    width={i.width}
                    style={{ display: i.hidden ? 'none' : 'block' }}
                  >
                    <Field
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...i}
                      label={t(fieldLabelIntl || '', fieldLabel)}
                      name={`${cname}.${fieldName}`}
                      // We get currency either from FormSpy values (if on data collection phase),
                      // or from passed case data 'currency' prop.
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...((currency || rest.currency) &&
                      endAdornment &&
                      endAdornment.includes('€')
                        ? {
                            endAdornment: endAdornment.replace(
                              '€',
                              (currency || rest.currency)!,
                            ),
                          }
                        : { endAdornment })}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...(disabled && { disabled })}
                    />
                  </Box>
                ),
              )}
            </Flex>
          </FlexWithBorder>
        ))
      }
    </FieldArray>
  );
};

MantleArrayInput.propTypes = propTypes;

export default MantleArrayInput;
