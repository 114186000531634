import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components/macro';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Text } from 'rebass';
import qs from 'query-string';
import idx from 'idx';
import { Tabs, Tab } from '@material-ui/core';
import { History } from 'history';
import { useTranslation } from 'react-i18next';

import colors from '../../theme/colors';
import { formFields } from '../../data/fieldData';
import { useStoreState } from '../../ts/typedHooks';

const propTypes = {
  history: shape({}).isRequired,
};

const StepTabs = styled(Tabs)`
  background-color: #fff;

  .MuiTabs-flexContainer {
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
      justify-content: space-evenly;
    }
  }

  .MuiTab-wrapper {
    flex-direction: row;
    font-weight: 400;
  }

  .MuiTabs-indicator {
    height: 5px;
  }
`;

const CircularProgressWrapper = styled.span<{ active?: boolean }>`
  color: ${({ active }) => (active ? colors.black : '#ccc')};
  margin-right: 10px;
  text-align: center;
  position: relative;
`;

const StepNumber = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
`;

const StepName = styled(Text)`
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.06em;
`;

interface Props {
  history: History;
}

const DataCollectionFormSteps = ({ history }: Props) => {
  const realCrusherType = useStoreState(state => state.crusherType);
  const crusherType = realCrusherType === 'pg' ? 'pg' : 'cc';
  const { t } = useTranslation('common');

  const steps = crusherType
    ? formFields[crusherType].map(({ id, title, titleIntl }) => ({
        id,
        step: id,
        title,
        titleIntl,
      }))
    : [];
  const queryStr = qs.parse(history.location.search);
  const activeStep = Number(idx(queryStr, _ => _.step)) || 1;

  const handleOnClick = (_: React.ChangeEvent<{}>, value: number) => {
    history.push(`?step=${value}`);
  };

  return (
    <StepTabs value={activeStep} variant="scrollable" onChange={handleOnClick}>
      {steps.length &&
        steps.map(step => (
          <Tab
            key={step.id}
            value={step.id}
            label={
              <>
                <CircularProgressWrapper active={step.id === activeStep}>
                  <CircularProgress
                    variant="static"
                    color={step.id === activeStep ? 'secondary' : 'inherit'}
                    thickness={2.2}
                    size={30}
                    value={step.id * 20}
                  />
                  <StepNumber>{step.id}</StepNumber>
                </CircularProgressWrapper>
                <StepName>{t(step.titleIntl || '', step.title)}</StepName>
              </>
            }
          />
        ))}
    </StepTabs>
  );
};

DataCollectionFormSteps.propTypes = propTypes;

export default DataCollectionFormSteps;
