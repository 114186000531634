import React from 'react';
import { shape } from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import Close from '@material-ui/icons/Close';
import { darken } from 'polished';

import { useTranslation } from 'react-i18next';
import colors from '../../theme/colors';
import Hamburger from '../Hamburger';
import withAuth from '../../containers/withAuth';
import logoUrl from '../../images/metso-main-logo-pack-20230621/Metso_Logo_Black_RGB.svg';
import FormSteps from '../DataCollectionFormSteps';

const propTypes = {
  match: shape({}).isRequired,
  history: shape({}).isRequired,
  user: shape({}),
};

const defaultProps = {
  user: {},
};

const StyledAppBar = styled(AppBar)`
  flex-shrink: 0;
  min-height: 64px !important;
`;
export const StyledIconButton = styled(IconButton)`
  margin-right: 20px;
`;

const drawerWidth = 280;

const StyledDrawer = styled(Drawer)`
  background: ${colors.turquoise};
  > div {
    background: ${colors.turquoise};
    width: ${drawerWidth}px;
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  justify-content: flex-end;
  min-height: 64px !important;
  background: ${darken(0.2, colors.turquoise)};
`;

const StyledToolbar = styled(Toolbar)`
  min-height: 64px !important;
  background: white;
  flex-wrap: wrap;
`;

const Logo = styled(Link)`
  img {
    width: 100%;
    max-width: 60px;
    height: auto;
    display: block;
    padding: 0;
    margin-right: 1rem;
    user-select: none;
    margin-bottom: 2px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const TextContent = styled.div`
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  order: 2;
  width: 100%;
  margin-top: ${({ theme }) => theme.muiTheme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.muiTheme.spacing(2)}px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    order: 0;
    margin-top: 0;
    margin-bottom: 0;
    width: auto;
  }
`;

const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  color: white;
`;

const renderBackButton = (
  { backUrl = null, backButton = null },
  history,
  t,
) => {
  if (backUrl) {
    return (
      <StyledIconButton
        component={Link}
        to={backUrl}
        color="inherit"
        aria-label={t('buttons.back', 'Back')}
      >
        <BackIcon />
      </StyledIconButton>
    );
  }
  if (backButton) {
    return backButton();
  }
  return (
    <StyledIconButton
      onClick={() => {
        if (window.location.hostname.includes('localhost')) {
          history.push('/');
        } else {
          window.location = '/';
        }
      }}
      color="inherit"
      aria-label={t('buttons.close', 'Close')}
    >
      <Close />
    </StyledIconButton>
  );
};

const NavBar = ({ user, match, history }) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = React.useState(false);
  const nav = useStoreState((state) => state.nav);
  const showFormSteps =
    !match.url.includes('light') &&
    !match.url.includes('follow-up') &&
    (match.url.includes('create') || match.url.includes('edit'));

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (match && match.url && match.url === '/login') {
    return null;
  }
  return (
    <>
      <StyledAppBar color="default" position="fixed">
        <StyledToolbar>
          <>
            {nav.showHamburger ? (
              <StyledIconButton
                onClick={handleDrawerOpen}
                color="inherit"
                aria-label={t('mainMenu', 'Main menu')}
              >
                <MenuIcon />
              </StyledIconButton>
            ) : (
              renderBackButton(nav, history, t)
            )}
            <TextContent>
              {match.url === '/' && (
                <Logo to="/">
                  <img src={logoUrl} alt="Metso Logo" />
                </Logo>
              )}
              {nav.content}
            </TextContent>
            {nav.buttons && <ButtonContainer>{nav.buttons}</ButtonContainer>}
          </>
        </StyledToolbar>
        {showFormSteps && <FormSteps history={history} />}
      </StyledAppBar>
      <StyledDrawer variant="persistent" anchor="left" open={open}>
        <DrawerHeader>
          <IconButton
            onClick={handleDrawerClose}
            aria-label={t('buttons.closeMenu')}
          >
            <StyledChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Hamburger handleClose={handleDrawerClose} user={user} />
        <Divider />
      </StyledDrawer>
    </>
  );
};

NavBar.propTypes = propTypes;
NavBar.defaultProps = defaultProps;

export default withAuth(NavBar);
