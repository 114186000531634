import React from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';
import { handleAuthentication } from '../../utils/auth.ts';

const StyledCallback = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
`;

const Callback = ({ history }) => {
  handleAuthentication(history);
  return (
    <StyledCallback>
      <h1>Logging in</h1>
    </StyledCallback>
  );
};

Callback.propTypes = {
  history: shape({}).isRequired,
};

export default Callback;
