const formatDate = (
  value?: Date | string | null,
  options?: {
    locale?: string;
    fallbackValue?: string;
  } & Intl.DateTimeFormatOptions,
) => {
  const { locale, fallbackValue, ...intlOptions } = options || {};
  const langCode =
    locale ||
    (typeof window !== 'undefined' && localStorage.getItem('lang')) ||
    (typeof window !== 'undefined' && navigator?.language) ||
    'en-US';

  return value
    ? new Intl.DateTimeFormat(langCode, intlOptions).format(new Date(value))
    : fallbackValue || '';
};

export default formatDate;
