import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { darken } from 'polished';
import { Flex, Text } from 'rebass';
import { shape, func } from 'prop-types';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import LangSwitcher from '../LangSwitcher';
import colors from '../../theme/colors';
import { isAuthenticated, logOut } from '../../utils/auth';
import navLinks from './navLinks';

const NavItems = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0px;
  overflow-y: auto;
  flex-direction: column;
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 9;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  will-change: transform;
  transition: transform 0.3s;
`;

const LogoutButton = styled(Button)`
  background: transparent;
  color: white;
  width: 130px;
  border-color: white;
  &:hover {
    color: black;
    background: white;
  }
`;

const NavItem = styled.li`
  text-decoration: none;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  padding: 20px;
  display: flex;
  color: ${colors.white};
  &:hover {
    text-decoration: none;
    color: ${colors.white};
    background: ${darken(0.05, colors.green)};
  }
  &.selected {
    background: ${colors.primaryColor};
    color: ${colors.white};
  }
`;

const Profile = styled(Flex)`
  background: ${darken(0.2, colors.green)};
`;

const ProfileAvatar = styled.div`
  color: ${colors.white};
  background: ${colors.turquoiseGreen};
  border-radius: 50%;
  display: flex;
  width: 46px;
  height: 46px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 1px;
`;

const ButtonWrapper = styled.div`
  padding: 20px;
  width: 200px;
`;

const User = styled(Text)`
  color: ${colors.white};
  font-size: 1rem;
  font-weight: lighter;
`;

const Hamburger = ({ user, handleClose }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Profile flexDirection="column" pt={3} pb={3} pl={3}>
        <ProfileAvatar>
          {user && user.initials ? (
            user.initials
          ) : (
            <span role="img" aria-label="No data">
              🙂
            </span>
          )}
        </ProfileAvatar>
        <User mt={2} ml={1}>
          {user && user.firstName} {user && user.lastName}
        </User>
        <LangSwitcher />
      </Profile>
      <NavItems>
        {isAuthenticated()
          ? navLinks.length &&
            navLinks.map(link => (
              <NavItem key={link.to}>
                <StyledLink
                  to={link.to}
                  onClick={handleClose}
                  exact
                  activeClassName="selected"
                >
                  {t(link.text)}
                </StyledLink>
              </NavItem>
            ))
          : null}
      </NavItems>
      {isAuthenticated() && (
        <ButtonWrapper>
          <LogoutButton
            size="small"
            variant="outlined"
            fullWidth={false}
            onClick={() => logOut()}
          >
            {t('logout')}
          </LogoutButton>
        </ButtonWrapper>
      )}
    </>
  );
};

Hamburger.propTypes = {
  user: shape({}),
  handleClose: func.isRequired,
};

Hamburger.defaultProps = {
  user: {},
};

export default Hamburger;
