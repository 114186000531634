import { isApolloError } from 'apollo-client';
import { isAxiosError } from 'axios';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

export function parseError(err: unknown) {
  let message = i18n.t('common:errors.unknownError');

  if (typeof err === 'string') {
    message = err;
  } else if (ValidationError.isError(err)) {
    message = err.message;
  } else if (
    err &&
    typeof err === 'object' &&
    'message' in err &&
    typeof err.message === 'string'
  ) {
    message = err.message;
  } else if (err instanceof Error && isApolloError(err)) {
    message = err.message;
  } else if (isAxiosError(err)) {
    message = err.message;
  }

  return message;
}

export function toastError(err: unknown, customMessage?: string) {
  const message = customMessage ?? parseError(err);

  console.error(err);
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
  });
}

export function toastSuccess(message: string) {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
  });
}
