import React from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

import Input from '../Input';

const CustomNumberInput = (p: any) => {
  const {
    i18n: { language = 'en' },
  } = useTranslation();

  return (
    <NumberFormat
      onValueChange={c => p.input.onChange(c)}
      value={p.input.value}
      customInput={Input}
      // Adjust thousand and decimal separators for Spanish language
      // to prevent wrong number formatting in form fields.
      thousandSeparator={language.includes('es') ? '.' : ' '}
      decimalSeparator={language.includes('es') ? ',' : '.'}
      onBlur={() => p.input.onBlur()}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...p}
    />
  );
};

export default CustomNumberInput;
