import styled, { css } from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import colors from '../../../theme/colors';

const NumberButton = styled(Button)<{ selected?: boolean }>`
  max-width: 100px;
  background: ${({ selected }) =>
    selected ? colors.black : colors.extraLightGray};
  color: ${({ selected }) => (selected ? colors.white : colors.gray)};
  border: 5px solid ${colors.extraLightGray};

  &:hover {
    background: ${colors.green};
    color: ${colors.white};
  }

  ${({ selected }) =>
    selected &&
    css`
      &:disabled {
        background: ${colors.bgLightGray};
      }
    `}
`;

export default NumberButton;
