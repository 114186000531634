/**
 * @param {number} value Value to format.
 * @param {string} currency Currency's ISO code (f.ex. USD).
 * @param {string} style The formatting style to use , the default is "currency".
 * @param {string} languageCode Locale's code (f.ex. fi-FI)
 * @param {number} minimumFractionDigits The minimum number of fraction digits to use.
 */
export const formatNumber = (
  value: number | null | undefined,
  options: Omit<Intl.NumberFormatOptions, 'currency'> & {
    languageCode?: string;
    currency?: string | null | undefined;
  },
) => {
  const numberValue = value === undefined || value === null ? 0 : value;

  const { languageCode, minimumFractionDigits, currency, ...rest } =
    options || {};
  const langCode =
    languageCode ||
    (typeof window !== 'undefined' && localStorage.getItem('lang')) ||
    (typeof window !== 'undefined' && navigator?.language) ||
    'en-US';
  const formatter = new Intl.NumberFormat(langCode, {
    style: 'currency',
    currency: currency || 'EUR',
    maximumFractionDigits: minimumFractionDigits || 2,
    minimumFractionDigits,
    ...rest,
  });
  return formatter.format(numberValue);
};
export enum profitType {
  POSITIVE_GOOD = 1,
  NEGATIVE_GOOD = -1,
  ALL_GOOD = 0,
}

/**
 * Add + in case positive and if BAD value, return '-'
 * @param value
 * @param decimals
 * @param options
 * @returns value prefixed with '+', or '-' if value not considered positive change
 */
export const formatValue = (
  value: number | null | undefined,
  {
    goodBad = profitType.ALL_GOOD,
    decimals = 1,
    ...options
  }: { goodBad?: profitType; decimals: number; [key: string]: any },
) => {
  const numberValue = value === undefined || value === null ? 0 : value;

  if (goodBad !== profitType.ALL_GOOD && goodBad * numberValue < 0) {
    return '-';
  }

  return `${numberValue > 0 ? '+' : ''}${formatNumber(numberValue ?? 0, {
    style: 'decimal',
    maximumFractionDigits: decimals,
    ...options,
  })}`;
};

/**
 * Parses value of NumberFormat component from 'react-number-format'
 *
 * @param val string
 */
export const parseNumberInput = (val: any): string | null =>
  val.floatValue !== undefined ? val.floatValue : null;
/**
 * Formats value for currency fields.
 *
 * @param val string
 */
export const roundCurrency = (val: any) =>
  val === null ? '' : formatNumber(Number(val), { minimumFractionDigits: 2 });
