import { useState } from 'react';
import { IconButton, Box, Menu, MenuItem } from '@material-ui/core';
import { Translate } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const LangSwitcher = () => {
  const [menuEl, setMenuEl] = useState(null);
  const { i18n, t } = useTranslation(['common']);
  const languages = ['en', 'es', 'cn'];

  const handleMenuClose = () => {
    setMenuEl(null);
  };
  const handleClick = (lang: 'en' | 'es' | 'cn') => {
    i18n.changeLanguage(lang);
    handleMenuClose();
    localStorage.setItem('lang', lang);
  };

  return (
    <Box>
      <IconButton
        aria-label={t('buttons.selectLanguage', 'Select language')}
        color="primary"
        onClick={(e: any) => setMenuEl(e.currentTarget)}
      >
        <Translate />
      </IconButton>
      <Menu anchorEl={menuEl} open={Boolean(menuEl)} onClose={handleMenuClose}>
        {languages.map((lang) => (
          <MenuItem
            key={lang}
            onClick={() => {
              handleClick(lang as 'en' | 'es' | 'cn');
            }}
          >
            {lang}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LangSwitcher;
