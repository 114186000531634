import React from 'react';
import { string, shape } from 'prop-types';
import styled from 'styled-components/macro';
import { Flex, Text } from 'rebass';

import Icon from '../../Icon/index.tsx';
import { StyledLabel, Error } from '../Input/index.tsx';
import { allColors } from '../../../theme/colors';

const StyledTextarea = styled.textarea`
  color: ${props => (props.color ? props.color : allColors.black)};
  font-size: 0.9rem;
  border-radius: 2px;
  border: 1px solid
    ${props => (props.borderColor ? props.borderColor : allColors.lineLight)};
  display: inline-flex;
  resize: none;
  margin-bottom: 5px;
  padding: 10px;
  width: 100%;
  &:disabled {
    border: 1px solid
      ${props => (props.borderColor ? props.borderColor : allColors.lineLight)};
    background: ${props =>
      props.backgroundColor
        ? props.backgroundColor
        : allColors.backgroundMidGrey};
  }
  &:focus {
    outline: 0;
    border: 1px solid
      ${props => (props.borderColor ? props.borderColor : allColors.lineDark)};
  }
  background: ${({ error }) =>
    error ? allColors.lightTerracotta : allColors.white};
  ${({ error }) => error && `border-color: ${allColors.darkTerracotta};`};
`;

const Label = styled(Text)`
  flex: 1;
  font-weight: lighter;
`;

const Textarea = ({
  input: { checked, ...restInput },
  meta,
  label,
  ...rest
}) => (
  <Flex flexDirection={rest.flexDirection || 'row'} width={[1]} flexWrap="wrap">
    {rest.boldLabel ? (
      <StyledLabel>{label}</StyledLabel>
    ) : (
      <Label width={[1, 1 / 3]} pr={5} mb={[4, 0]}>
        {label}
      </Label>
    )}
    <Flex flexDirection="column" width={[1, 2 / 3]}>
      <StyledTextarea
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restInput}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        rows="4"
        error={Boolean(meta && meta.touched && meta.error)}
      />
      <Error>
        {meta && meta.touched && meta.error && (
          <>
            <Icon name="error_outline" color={allColors.darkTerracotta} />{' '}
            {meta.error}
          </>
        )}
      </Error>
    </Flex>
  </Flex>
);

Textarea.defaultProps = {
  error: null,
  label: '',
  input: undefined,
  meta: null,
};

Textarea.propTypes = {
  error: string,
  meta: shape({}),
  input: shape({}),
  label: string,
};

export default Textarea;
