import queryString from 'query-string';
import jwt from 'jsonwebtoken';
import LogRocket from 'logrocket';
import idx from 'idx';

export const ID_TOKEN = 'idToken';
export const ACCESS_TOKEN = 'accessToken';
export const EXPIRES_AT = 'expiresAt';
export const USER = 'user';

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  initials?: string;
  role: string;
}

interface AuthResult {
  idToken: string;
  expiresAt: string;
  user: User;
}

// eslint-disable-next-line
export const setSession = (
  authResult: AuthResult,
  history?: any,
  url?: string,
) => {
  LogRocket.identify(authResult.user.id, {
    name: `${idx(authResult, _ => _.user.firstName)} ${idx(
      authResult,
      _ => _.user.lastName,
    )}`,
    email: idx(authResult, _ => _.user.email) || '',
    // Add your own custom user variables here, ie:
    // subscriptionType: 'pro'
  });
  localStorage.setItem(ID_TOKEN, authResult.idToken);
  localStorage.setItem(EXPIRES_AT, authResult.expiresAt);
  if (authResult.user) {
    localStorage.setItem(USER, JSON.stringify(authResult.user));
  }
  // navigate to the home route
  if (history) {
    return history.replace(url || '/');
  }
};

const getInitials = (
  firstName: string,
  lastName: string,
  email: string,
): string => {
  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  }
  if (firstName) {
    return `${firstName.charAt(0)}`;
  }
  if (email) {
    return `${email.charAt(0)}`;
  }
  return '🙂';
};

export const handleAuthentication = (history: any) => {
  const { idToken, expiresAt, url } = queryString.parse(history.location.hash);
  if (history && idToken && expiresAt) {
    if (typeof idToken !== 'string') {
      return {};
    }
    const {
      sub,
      firstname,
      lastname,
      email: emailA,
      ...rest
    }: any = jwt.decode(idToken);
    const expAt = Array.isArray(expiresAt) ? expiresAt[0] : expiresAt;
    const urlStr = (Array.isArray(url) ? url[0] : url) || '';
    const lastName = lastname || null;
    const firstName = firstname || null;
    const email = emailA && emailA.length && emailA[0];
    const initials = getInitials(firstName, lastName, email);
    const role = idx(
      rest,
      _ => _['https://hasura.io/jwt/claims']['x-hasura-default-role'],
    );
    const allowed =
      idx(
        rest,
        _ => _['https://hasura.io/jwt/claims']['x-hasura-allowed-countries'],
      ) || '';
    const allowedCountries = allowed
      .slice(1, -1)
      .split(',')
      .filter(Boolean);
    const user = {
      id: sub,
      firstName,
      lastName,
      email,
      initials,
      role,
      allowedCountries,
    };
    return setSession(
      {
        idToken,
        expiresAt: expAt,
        user,
      },
      history,
      urlStr,
    );
  }
  // eslint-disable-next-line
  console.error(`Couldn't login, try again!`);
  return history.replace('/login');
};

export const getTokens = () => {
  const idToken = localStorage.getItem(ID_TOKEN) || '';
  const expiresAt = localStorage.getItem(EXPIRES_AT) || '';
  const userStr = localStorage.getItem(USER) || '{}';
  const user = JSON.parse(userStr);
  return {
    idToken,
    expiresAt,
    user,
  };
};

export const logOut = () => {
  // Clear access token and ID token from local storage
  localStorage.removeItem(ID_TOKEN);
  localStorage.removeItem(EXPIRES_AT);
  localStorage.removeItem(USER);
  return window.location.replace('/login');
};

export const isAuthenticated = () => {
  // Check whether the current time is past the
  // access token's expiry time and if ID_TOKEN and ACCESS_TOKEN exists
  const expAtStr = localStorage.getItem(EXPIRES_AT) || '{}';
  const expiresAt = JSON.parse(expAtStr);
  return (
    localStorage.getItem(EXPIRES_AT) &&
    localStorage.getItem(ID_TOKEN) &&
    Math.round(Date.now() / 1000) < expiresAt
  );
};

export const getUser = () => {
  const { user } = getTokens();
  const authenticated = isAuthenticated();
  return {
    user,
    isAuthenticated: authenticated,
  };
};
