import { lighten, darken } from 'polished';

// TODO these colors are from Choplight, figure out are they needed.
export const allColors = {
  green: '#005E57',
  terracotta: '#E05314',
  midGreen: '#00A877',
  brightGreen: '#98C000',
  brightOrange: '#FC9000',
  turquoiseGreen: '#009FA0',
  warmYellow: '#FFB807',
  backgroundLightGrey: '#F0F0F0',
  backgroundDarkGrey: '#6E6C70',
  backgroundMidGrey: '#E7E7E7',
  textDark: '#313230',
  textDarker: '#000000',
  textMedium: '#4B494C',
  textLight: '#9E9E9E',
  linkColorCorporate: '#009FA0',
  linkColorIndustry: '#FC9000',
  black: '#000000',
  lineDark: '#C6C6C6',
  lineLight: '#E5E5E5',
  red: '#AD271D',
  darkTerracotta: '#C73612',
  lightTerracotta: '#FEECE8',
  white: '#ffffff',
  purple: '#4E2098',
};

// TODO these colors are from Choplight, figure out are they needed.
export const chartColors = [
  // allColors.midGreen,
  // allColors.terracotta,
  // allColors.turquoiseGreen,
  // allColors.warmYellow,
  // allColors.red,
  // allColors.brightOrange,
  // '#202E78',
  '#7C4DFF',
  '#4DD0E1',
  '#FFB807',
  '#7AC6E8',
  '#F06292',
  '#FFAB91',
];

export default {
  green: '#00E2B2',
  darkGreen: darken(0.2, '#00E2B2'),
  turquoise: '#00E2B2',
  // not orange anymore but maybe something like purple
  orange: '#E5097C',
  lightOrange: lighten(0.1, '#E5097C'),
  extraLightGray: '#F0F0F0',
  lightGray: '#E7E7E7',
  bgLightGray: '#C6C6C6',
  bgExtraLightGray: '#F4F4F4',
  darkGray: '#6E6C70',
  bgDarkGray: '#979797',
  gray: '#9E9E9E',
  textBlack: '#313230',
  textMedium: '#4B494C',
  black: '#000000',
  divider: '#E5E5E5',
  white: '#ffffff',
  terracotta: '#EB2814',
  darkTerracotta: darken(0.2, '#EB2814'),
  bgError: '#EB5757',
  lightTerracotta: lighten(0.2, '#EB2814'),
  purple: '#E5097C',
};
