import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from '../../utils/auth';

interface PrivateRoute {
  component: any;
  path: string;
  exact?: boolean;
}

const PrivateRoute: React.FC<PrivateRoute> = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Fragment>
          <Component {...props} />
        </Fragment>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
