import React from 'react';
import { Field } from 'react-final-form';

type Props = {
  when: string;
  is: boolean;
  children: any;
};

const ConditionalField = ({ when, is, children }: Props) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }: { input: any }) => (value === is ? children : null)}
  </Field>
);

export default ConditionalField;
