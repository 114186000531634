import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components/macro';
import { Flex, Text, Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';

import RenderField from '../../RenderField';
import { DataCollectionSection, Field } from '../../../ts/interfaces';
import { formatNumber } from '../../../utils/formatNumber';
import { MantlePiece } from '../../../generated/graphql';

const propTypes = {
  title: string.isRequired,
  subsections: arrayOf(shape({})).isRequired,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledPaper = styled(({ noMargin, ...props }) => <Paper {...props} />)`
  padding: ${props => props.theme.muiTheme.spacing(2)}px;
  padding-top: ${props => props.theme.muiTheme.spacing(2)}px;
  margin-bottom: ${props =>
    props.noMargin ? 0 : props.theme.muiTheme.spacing(2)}px;

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    padding: ${props => props.theme.muiTheme.spacing(3)}px;
    padding-top: ${props => props.theme.muiTheme.spacing(4)}px;
    margin-bottom: ${props =>
      props.noMargin ? 0 : props.theme.muiTheme.spacing(4)}px;
    width: 100%;
    background: ${props => (props.background ? props.background : '')};
    display: flex;
    flex-wrap: wrap;
  }
`;

const P = styled.p`
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 1rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-bottom: 0;
  }
`;

interface ConeMantleConfiguration {
  title: string;
  titleIntl: string;
  subsections: (Omit<DataCollectionSection, 'fields'> & { fields: Field[] })[];
  disabledAfterAnalysis?: boolean;
}

const ConeMantleConfiguration = ({
  title,
  titleIntl,
  subsections,
  disabledAfterAnalysis,
}: ConeMantleConfiguration) => {
  const { t } = useTranslation('forms');
  const [mantlePieces, ...mantleInputs] = subsections?.[0]?.fields;
  const { values } = useFormState();

  const totalMantlePiecesPrice = (
    values?.partSets?.[0]?.mantlePieces || []
  ).reduce((total: number, item: MantlePiece) => {
    return total + (item?.price || 0);
  }, 0);

  return (
    <StyledPaper elevation={0}>
      <Flex
        width={1}
        justifyContent="space-between"
        alignItems="center"
        flexDirection={['column', 'column', 'row']}
        mb={[2]}
      >
        {title && (
          <Text
            px={2}
            mb={[2, 3, 0]}
            fontSize="1.7rem"
            fontFamily="Arial"
            fontWeight="lighter"
            color="green"
          >
            {t(titleIntl, title)}
          </Text>
        )}
        <P>
          {t(
            'forms:labels.totalPriceOfMantlesSet',
            'Total price of mantles set',
          )}{' '}
          {formatNumber(totalMantlePiecesPrice, { currency: values?.currency })}
        </P>
        <RenderField
          marginBottom={1}
          disabled={disabledAfterAnalysis}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...mantlePieces}
        />
      </Flex>
      <Box width={1}>
        {mantleInputs.map(field => (
          <RenderField
            key={field.name}
            disabled={disabledAfterAnalysis}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
          />
        ))}
      </Box>
    </StyledPaper>
  );
};

ConeMantleConfiguration.propTypes = propTypes;

export default ConeMantleConfiguration;
