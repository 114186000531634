import React from 'react';
import styled from 'styled-components/macro';
import { Tooltip as MuiTooltip, makeStyles } from '@material-ui/core';
import { Text, Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import Icon from '../Icon';
import {
  abrasivenessData,
  crushabilityData,
  bondWorkIndexData,
  co2EmissionData,
} from '../../data/tooltipData';

const useStyles = makeStyles({
  imageTooltip: {
    maxWidth: 520,
  },
  default: { display: 'inline-flex' },
});

const IconHolder = styled.span`
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 1.25rem;
  transition: color ease-in-out 0.2s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;
const Img = (width: string) => styled.img`
  width: ${width};
`;

const abrasiveness = (t: TFunction) => (
  <Box width={200}>
    {abrasivenessData.map(({ label, labelIntl, value, bgColor, color }) => (
      <Flex key={label} justifyContent="space-between" bg={bgColor} px={1}>
        <Text color={color || 'black'}>{t(labelIntl, label)}</Text>
        <Text color={color || 'black'}>{value}</Text>
      </Flex>
    ))}
  </Box>
);

const co2emission = (t: TFunction) => {
  const Image = Img(co2EmissionData.width);
  return <Image src={co2EmissionData.src} alt={t('co2EmissionsTooltip')} />;
};

const crushability = (t: TFunction) => (
  <Box width={200}>
    {crushabilityData.map(({ label, labelIntl, value, bgColor, color }) => (
      <Flex key={label} justifyContent="space-between" bg={bgColor} px={1}>
        <Text color={color || 'black'}>{t(labelIntl, label)}</Text>
        <Text color={color || 'black'}>{value}</Text>
      </Flex>
    ))}
  </Box>
);

const bondWorkIndex = (t: TFunction) => (
  <Box width={200}>
    {bondWorkIndexData.map(({ label, labelIntl, value, bgColor, color }) => (
      <Flex key={label} justifyContent="space-between" bg={bgColor} px={1}>
        <Text color={color || 'black'}>{t(labelIntl, label)}</Text>
        <Text color={color || 'black'}>{value}</Text>
      </Flex>
    ))}
  </Box>
);

interface Tooltip {
  tooltip: string;
  tooltipIntl?: string;
  type?: string;
}

const getTooltip = (tooltip: string, t: TFunction, tooltipIntl?: string) => {
  let title;
  let style: 'default' | 'imageTooltip' = 'default';
  switch (tooltip) {
    case 'abrasiveness':
      title = abrasiveness(t);
      break;
    case 'crushability':
      title = crushability(t);
      break;
    case 'co2emission':
      title = co2emission(t);
      style = 'imageTooltip';
      break;
    case 'bondWorkIndexOfOre':
      title = bondWorkIndex(t);
      break;
    default:
      title = t(tooltipIntl || '', tooltip);
  }
  return { title, style };
};
const Tooltip: React.FC<Tooltip> = ({ tooltip, tooltipIntl, type }) => {
  const { t } = useTranslation('tooltips');
  const classes = useStyles();
  const { title, style } = getTooltip(tooltip, t, tooltipIntl);
  return (
    <MuiTooltip title={title} classes={{ tooltip: classes[style] }}>
      <IconHolder>
        <Icon name={type === 'disclaimer' ? 'warning' : 'help'} />
      </IconHolder>
    </MuiTooltip>
  );
};

export default Tooltip;
