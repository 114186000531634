import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import styled from 'styled-components/macro';
import { Close } from '@material-ui/icons';

import 'react-toastify/dist/ReactToastify.css';

const CloseButton = () => <Close />;

const MetsoToasts = styled(ToastContainer)`
  &.Toastify__toast-container {
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
      width: 386px;
    }
  }
  &.Toastify__toast-container--top-center {
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
      margin-left: -193px;
    }
  }
  .Toastify__toast {
    align-items: center;
  }
  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.colors.bgError};
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.colors.green};
  }
  .Toastify__toast-body {
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.typography.fontSizeTag};
    letter-spacing: 0.1em;
    font-weight: bold;
  }
  .Toastify__progress-bar {
    top: 0;
    bottom: auto;
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

export default () => (
  <MetsoToasts closeButton={<CloseButton />} transition={Slide} />
);
