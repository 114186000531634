// @ts-nocheck
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: null,
  };

  // static getDerivedStateFromError(error: Error) {
  //   return { hasError: true };
  // }

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <>
          <h2>
            An error occured while rendering application. Please try again.
          </h2>
          <p>{this?.state?.error && this?.state?.error.toString()}</p>
          <p>{this?.state?.errorInfo?.componentStack}</p>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
