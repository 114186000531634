import { useState } from 'react';
import {
  string,
  number,
  shape,
  bool,
  InferProps,
  oneOfType,
  func,
} from 'prop-types';
import { Input, Checkbox, InputAdornment } from '@material-ui/core';
import styled, { css } from 'styled-components/macro';
import { Flex } from 'rebass';

import Icon from '../../Icon';
import Tooltip from '../../Tooltip';
import { allColors } from '../../../theme/colors';

const propTypes = {
  type: string,
  error: string,
  meta: shape({
    touched: bool,
    error: string,
  }),
  input: shape({
    name: string,
    value: oneOfType([string, number]),
  }),
  label: string,
  startAdornment: string,
  endAdornment: string,
  borderLeft: bool,
  tooltip: string,
  tooltipIntl: string,
  value: oneOfType([string, number]),
  defaultValue: oneOfType([string, number]),
  onChange: func,
  labelColor: string,
  mandatoryDesign: bool,
  mandatoryValueStudy: bool,
  placeholder: string,
  additionalInfo: string,
};

const defaultProps = {
  type: 'text',
  error: null,
  label: '',
  input: undefined,
  meta: null,
  startAdornment: '',
  endAdornment: '',
  borderLeft: false,
  tooltip: undefined,
  tooltipIntl: undefined,
  mandatoryDesign: false,
  mandatoryValueStudy: false,
  labelColor: undefined,
  placeholder: undefined,
  additionalInfo: undefined,
};

interface InputProps {
  checkbox: boolean;
  color: string;
  borderColor: string;
  backgroundColor: string;
}

const StyledInput = styled(Input)<InputProps>`
  display: flex;
  color: ${(props: InputProps) =>
    props.color ? props.color : allColors.black};
  border-radius: 2px;
  ${(props: InputProps) => (!props.checkbox ? 'align-items: baseline;' : '')}
  font-size: 0.875rem;
  margin-bottom: 5px;
  border-radius: 0px;
  ${(props: InputProps) => (props.checkbox ? 'border: none;' : '')};
  border: 1px solid
    ${(props: InputProps) =>
      props.borderColor ? props.borderColor : allColors.lineLight};
  &:disabled {
    border: 1px solid
      ${(props: InputProps) =>
        props.borderColor ? props.borderColor : allColors.lineLight};
    background: ${(props: InputProps) =>
      props.backgroundColor
        ? props.backgroundColor
        : allColors.backgroundMidGrey};
  }
  input {
    ::placeholder {
      opacity: 0.3;
    }
  }
  &:focus {
    outline: 0;
    border: 1px solid
      ${(props) => (props.borderColor ? props.borderColor : allColors.lineDark)};
  }
  background: ${(props) =>
    props.error ? allColors.lightTerracotta : allColors.white};
  ${(props) =>
    props.disabled ? `background: ${allColors.backgroundMidGrey};` : ''}
  ${(props) => props.error && `border-color: ${allColors.darkTerracotta};`};
`;

type BorderedFlexProps = {
  borderLeft?: boolean | null;
};

const BorderedFlex = styled(Flex)<BorderedFlexProps>`
  ${({ borderLeft }) =>
    borderLeft &&
    css`
      border-top: 1px solid ${({ theme }) => theme.colors.divider};
      padding-top: ${({ theme }) => theme.muiTheme.spacing(2)}px;

      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints[1]}) {
        border-top: none;
        padding-top: 0;
        border-left: 1px solid ${({ theme }) => theme.colors.divider};
      }
    `};
`;

type LabelProps = {
  labelColor?: string;
};

export const StyledLabel = styled.label<LabelProps>`
  color: ${(props) => (props.labelColor ? props.labelColor : allColors.black)};

  :not(.checkbox) {
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 600;
    margin-bottom: 3px;
  }
`;

const StartAdornment = styled(InputAdornment)`
  margin-left: 8px;
`;

const EndAdornment = styled(InputAdornment)`
  margin-right: 8px;
`;

export const Asterisk = styled.span`
  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.orange};
`;

export const Error = styled.p`
  i {
    font-size: 1.2rem;
    margin-right: 5px;
  }
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  align-items: center;
  color: ${allColors.darkTerracotta};
  margin-bottom: 8px;
  font-size: 0.75rem;
`;

export const AdditionalInfo = styled.p`
  color: ${allColors.textLight};
  font-size: 0.7rem;
  width: 100%;
  text-align: right;
`;

const CustomInput = ({
  input,
  meta,
  label,
  endAdornment,
  startAdornment,
  borderLeft,
  tooltip,
  tooltipIntl,
  type,
  labelColor,
  mandatoryDesign,
  mandatoryValueStudy,
  additionalInfo,
  ...rest
}: InferProps<typeof propTypes>) => {
  const [checked, setCheckbox] = useState(false);

  const renderSwitch = (inputType: string) => {
    switch (inputType) {
      case 'text':
      case 'email':
      case 'number':
        return (
          // @ts-ignore
          <StyledInput
            aria-label={label ?? undefined}
            disableUnderline
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...input}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            endAdornment={
              endAdornment ? (
                <EndAdornment position="end">{endAdornment}</EndAdornment>
              ) : null
            }
            startAdornment={
              startAdornment ? (
                <StartAdornment position="start">
                  {startAdornment}
                </StartAdornment>
              ) : null
            }
            error={Boolean(meta && meta.touched && meta.error)}
          />
        );
      case 'file':
        return null;
      default:
        return (
          // @ts-ignore
          <Checkbox
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...input}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            value={input && input.value}
            checked={checked}
            onChange={() => setCheckbox(!checked)}
          />
        );
    }
  };
  return (
    <BorderedFlex
      width={1}
      flexDirection={
        type === 'checkbox' || type === 'radio' ? 'row-reverse' : 'column'
      }
      justifyContent={
        type === 'checkbox' || type === 'radio' ? 'flex-end' : 'flex-start'
      }
      alignItems={
        // eslint-disable-next-line
        type === 'checkbox' || type === 'radio' ? 'baseline' : 'inherit'
      }
      borderLeft={borderLeft}
      pl={borderLeft ? 2 : 0}
    >
      <Flex alignItems="center">
        {label && (
          <StyledLabel
            labelColor={labelColor as string}
            className={
              type === 'checkbox' || type === 'radio' ? 'checkbox' : ''
            }
            htmlFor={(input && input.name) || ''}
          >
            {label}
            {mandatoryDesign && <Asterisk>*</Asterisk>}
            {mandatoryValueStudy && <Asterisk color="green">*</Asterisk>}
          </StyledLabel>
        )}
        {tooltip && (
          <Tooltip tooltip={tooltip} tooltipIntl={tooltipIntl ?? undefined} />
        )}
      </Flex>

      {renderSwitch(type as string)}

      <Error>
        {meta && meta.touched && meta.error ? (
          <>
            <Icon name="error_outline" color={allColors.darkTerracotta} />{' '}
            {meta.error}
          </>
        ) : (
          <AdditionalInfo>{additionalInfo}</AdditionalInfo>
        )}
        &nbsp;
      </Error>
    </BorderedFlex>
  );
};

CustomInput.propTypes = propTypes;
CustomInput.defaultProps = defaultProps;

export default CustomInput;
