import { lazy } from 'react';

import Login from './containers/Login';
import Callback from './components/Callback';
import StatisticsPage from './components/Statistics';

const FrontPage = lazy(() => import('./components/CasesPage'));
const NewCase = lazy(() => import('./containers/NewCase'));
const NewCaseLight = lazy(() => import('./containers/NewCaseLight'));
const CaseOverview = lazy(() => import('./containers/CaseOverview'));
const CaseDebug = lazy(() => import('./containers/CaseDebug'));
const SolutionsPage = lazy(() => import('./containers/SolutionPage'));
const ResultsPage = lazy(() => import('./components/Results'));
const ValueStudyCase = lazy(() => import('./containers/ValueStudyCase'));
const FollowUp = lazy(() => import('./containers/FollowUp'));
const SupportPage = lazy(() => import('./components/SupportPage'));
const SettingsPage = lazy(() => import('./components/SettingsPage'));

const routes = [
  {
    path: '/',
    exact: true,
    component: FrontPage,
    private: true,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/callback',
    component: Callback,
  },
  {
    path: '/case',
    exact: true,
    component: FrontPage,
    private: true,
  },
  {
    path: '/case/create/:crusherType/light',
    component: NewCaseLight,
    exact: true,
    private: true,
  },
  {
    path: '/case/create/:crusherType',
    component: NewCase,
    exact: true,
    private: true,
  },
  {
    path: '/cases/:caseId/edit/light',
    component: NewCaseLight,
    exact: true,
    private: true,
  },
  {
    path: '/cases/:caseId/edit',
    component: NewCase,
    exact: true,
    private: true,
  },
  {
    path: '/case/:caseId',
    component: CaseOverview,
    private: true,
    exact: true,
  },
  {
    path: '/case/:caseId/value-study',
    component: ValueStudyCase,
    private: true,
    exact: true,
  },
  {
    path: '/case/:caseId/solution',
    component: SolutionsPage,
    private: true,
    exact: true,
  },
  {
    path: '/case/:caseId/results',
    component: ResultsPage,
    private: true,
    exact: true,
  },
  {
    path: '/case/:caseId/follow-up/edit',
    component: FollowUp,
    private: true,
    exact: true,
  },
  {
    path: '/case/:caseId/follow-up',
    component: FollowUp,
    private: true,
    exact: true,
  },
  {
    path: '/case/:caseId/settings',
    component: SettingsPage,
    private: true,
    exact: true,
  },
  {
    path: '/case/:caseId/debug',
    component: CaseDebug,
    exact: true,
    private: true,
  },
  {
    path: '/support',
    component: SupportPage,
  },
  {
    path: '/statistics',
    component: StatisticsPage,
  },
];

export default routes;
