import React from 'react';
import { arrayOf, node, shape } from 'prop-types';
import { Flex } from 'rebass';

import ErrorBoundary from '../ErrorBoundary';

const propTypes = {
  location: shape({}),
  children: arrayOf(node).isRequired,
};

const defaultProps = {
  location: {},
};

const Layout = ({ location, children }) => {
  let paddingTop;
  if (
    !location.pathname.includes('light') &&
    !location.pathname.includes('follow-up') &&
    (location.pathname.includes('create') || location.pathname.includes('edit'))
  ) {
    paddingTop = [157, 120];
  } else if (location.pathname.includes('login')) {
    paddingTop = 0;
  } else {
    paddingTop = [6, 5];
  }

  return (
    <Flex pt={paddingTop} flexDirection="column" flex={1}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Flex>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
