import React, { useState, useEffect } from 'react';
import { arrayOf, shape } from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import SelectInput from '../SelectInput';
import onePieceMantleEmpty from '../../../images/1-piece-mantle-empty.svg';
import onePieceMantle from '../../../images/1-piece-mantle.svg';
import twoPiecesLowerMantle from '../../../images/2-pieces-lower-mantle.svg';
import twoPiecesUpperMantle from '../../../images/2-pieces-upper-mantle.svg';
import threePiecesLowerMantle from '../../../images/3-pieces-lower-mantle.svg';
import threePiecesMiddleMantle from '../../../images/3-pieces-middle-mantle.svg';
import threePiecesUpperMantle from '../../../images/3-pieces-upper-mantle.svg';

import { Field as FieldInterface } from '../../../ts/interfaces';

const propTypes = {
  selectFields: arrayOf(shape({})).isRequired,
};

const StyledSelect = styled(({ numberOfFields, lineWidth, ...props }) => (
  <SelectInput {...props} />
))`
  &:before {
    @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      content: '';
      position: absolute;
      width: ${({ numberOfFields, lineWidth }) =>
        180 + numberOfFields * 5 - lineWidth * 20}px;
      height: 1px;
      display: block;
      background: ${({ theme }) => theme.colors.bgLightGray};
      top: 50%;
      transform: translateX(-105%);
    }
  }

  &:focus-within {
    &:before {
      background: ${({ theme }) => theme.colors.green};
    }
  }
`;

interface PgMantlesPerSetFormProps {
  selectFields: FieldInterface[];
  numberOfMantlePieces: number | undefined;
  disabledAfterAnalysis?: boolean;
}

const PgMantlesPerSetForm = ({
  selectFields,
  numberOfMantlePieces,
  disabledAfterAnalysis,
  ...rest
}: PgMantlesPerSetFormProps) => {
  const [mantleIconSrc, setMantleIconSrc] = useState(onePieceMantleEmpty);
  const { t } = useTranslation('forms');

  useEffect(() => {
    switch (numberOfMantlePieces) {
      case 1: {
        setMantleIconSrc(onePieceMantle);
        break;
      }
      case 2: {
        setMantleIconSrc(twoPiecesUpperMantle);
        break;
      }
      default:
        setMantleIconSrc(threePiecesUpperMantle);
        break;
    }
  }, [numberOfMantlePieces]);

  const handleFocus = (name: string) => () => {
    if (
      name === 'partSets[0].middleMantlesPerSet' &&
      numberOfMantlePieces === 3
    ) {
      setMantleIconSrc(threePiecesMiddleMantle);
    }
    if (name === 'partSets[0].lowerMantlesPerSet') {
      setMantleIconSrc(
        numberOfMantlePieces === 2
          ? twoPiecesLowerMantle
          : threePiecesLowerMantle,
      );
    }
    if (name === 'partSets[0].upperMantlesPerSet') {
      setMantleIconSrc(
        numberOfMantlePieces === 2
          ? twoPiecesUpperMantle
          : threePiecesUpperMantle,
      );
    }
  };

  /**
   * Render form fields according to number of mantle pieces.
   */
  const renderFormFields = () => {
    switch (numberOfMantlePieces) {
      case 1: {
        const field = selectFields.find(
          field => field.name === 'partSets[0].middleMantlesPerSet',
        );
        if (field) {
          return (
            <Field
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              label={t(field.labelIntlSingle || '', field.labelSingle)}
              // @ts-ignore
              component={StyledSelect}
              lineWidth={0}
              numberOfFields={numberOfMantlePieces}
              onFocus={handleFocus(field.name)}
              disabled={disabledAfterAnalysis}
            />
          );
        }
        return null;
      }

      case 2:
        const fields = selectFields.filter(
          field => field.name !== 'partSets[0].middleMantlesPerSet',
        );

        if (fields.length) {
          return (
            <>
              {fields.map((field, i) => (
                <Field
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  label={t(field.labelIntl || '', field.label)}
                  lineWidth={i}
                  numberOfFields={numberOfMantlePieces}
                  key={field.name}
                  // @ts-ignore
                  component={StyledSelect}
                  onFocus={handleFocus(field.name)}
                  disabled={disabledAfterAnalysis}
                />
              ))}
            </>
          );
        }
        return null;

      case 3:
        return (
          selectFields.map(({ component, ...field }, i) => (
            <Field
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              label={t(field.labelIntl || '', field.label)}
              lineWidth={i}
              numberOfFields={numberOfMantlePieces}
              key={field.name}
              // @ts-ignore
              component={StyledSelect}
              onFocus={handleFocus(field.name)}
              disabled={disabledAfterAnalysis}
            />
          )) || null
        );

      default:
        return null;
    }
  };

  return (
    <>
      {!!numberOfMantlePieces && (
        <Flex
          width={[1, 2 / 3]}
          flexDirection="column"
          justifyContent={['end']}
          mx="auto"
          pb={[3, 4]}
        >
          <Flex alignItems="center" flexDirection={['column', 'row']}>
            <Flex flex={1} justifyContent="center" mb={[4, 0]}>
              <img src={mantleIconSrc} alt="mantle config icon" />
            </Flex>
            <Flex flex={1} flexDirection="column">
              {renderFormFields()}
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

PgMantlesPerSetForm.propTypes = propTypes;

export default PgMantlesPerSetForm;
