import colors from './colors';
import typography from './typography';
import muiTheme from './muiTheme';

export default {
  breakpoints: ['40em', '52em', '64em', '80em', '105em'],
  colors,
  typography,
  muiTheme,
};
