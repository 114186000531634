import dotenv from 'dotenv';

dotenv.config();

const NODE_ENV = process.env.NODE_ENV || 'development';

const __DEV__ = NODE_ENV === 'development';

const FILE_UPLOAD_SETTINGS = {
  maxFileSize: 1000 * 1000 * 200,
  allowedExtensions:
    'image/*, video/*, .xls, .xlsm, .xlsx, .doc, .docx, .pdf, .csv, .txt, .xml, .xmls, .stl, .brn',
  maxFiles: 10,
};

export { __DEV__, FILE_UPLOAD_SETTINGS };
