import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Flex, Box } from 'rebass';
import styled from 'styled-components/macro';
import { IconButton, Button } from '@material-ui/core';
import { Add, DeleteSharp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDeleteMiningProductMutation } from '../../../generated/graphql';
import { Field as FieldInterface } from '../../../ts/interfaces';
import { toastError } from '../../../utils/toasts';
import formatDate from '../../../utils/formatDate';

const Label = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeHeading300};
  font-weight: lighter;
  white-space: nowrap;
`;

interface Props {
  fields: FieldInterface[];
  remove: (name: string, index: number) => void;
  push: (name: string, value: {}) => void;
  disabledAfterAnalysis?: boolean;
}

const MiningProductsInput: React.FC<Props> = ({
  fields: topFields,
  disabledAfterAnalysis,
}) => {
  const { t } = useTranslation(['common', 'forms']);
  const [deleteMiningProduct] = useDeleteMiningProductMutation();

  const handleDelete = async (fields: any, index: number) => {
    const productToRemove = fields.value[index];
    // Delete product from database if it exists.
    if (typeof productToRemove.id !== 'undefined') {
      try {
        await deleteMiningProduct({
          variables: {
            id: productToRemove.id,
          },
        });

        fields.remove(index);
      } catch (err) {
        toastError(err);
      }
    } else {
      // Or just remove it from products array on frontend.
      fields.remove(index);
    }
  };

  return (
    <>
      {topFields.map(({ name, label, labelIntl, arrayFields }) => (
        <FormSpy subscription={{ values: true }} key={name}>
          {({ values }) => (
            <FieldArray name={name} key={name}>
              {({ fields }) => (
                <>
                  {fields.map((cname, index) => (
                    <Box width={1} key={cname}>
                      <Box p={2}>
                        <Label>
                          {t(labelIntl || '', label!)}
                          {` ${index + 1}`}
                        </Label>
                      </Box>
                      <Flex
                        flexWrap={['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap']}
                        width={1}
                      >
                        {arrayFields &&
                          arrayFields
                            .filter(
                              (f) =>
                                // remove field "nameOther"
                                // unless the value for the name dropdown
                                // is 'other'
                                f.name !== 'nameOther' ||
                                fields.value[index].name === 'other',
                            )
                            .map(
                              ({
                                name: fieldName,
                                endAdornment,
                                enabledAfterAnalysis,
                                labelIntl: labelIntlArrayField,
                                disabled,
                                ...i
                              }) => (
                                <Box
                                  key={i.id || fieldName}
                                  p={2}
                                  width={i.width}
                                  style={{
                                    display: i.hidden ? 'none' : 'block',
                                  }}
                                >
                                  <Field
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...i}
                                    name={`${cname}.${fieldName}`}
                                    label={t(
                                      labelIntlArrayField || '',
                                      i.label,
                                    )}
                                    disabled={
                                      disabled ||
                                      (disabledAfterAnalysis &&
                                        !enabledAfterAnalysis)
                                    }
                                    additionalInfo={
                                      fieldName === 'price' &&
                                      fields.value[index].priceUpdatedAt
                                        ? t('common:strings.updatedAt', {
                                            date: formatDate(
                                              fields.value[index]
                                                .priceUpdatedAt,
                                            ),
                                          })
                                        : null
                                    }
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...(endAdornment &&
                                    endAdornment.includes('€') &&
                                    values &&
                                    values.currency
                                      ? {
                                          endAdornment: t(
                                            `forms:adornments.${endAdornment}`,
                                            endAdornment,
                                          ).replace('€', values.currency),
                                        }
                                      : {
                                          endAdornment: t(
                                            `forms:adornments.${endAdornment}`,
                                            endAdornment,
                                          ),
                                        })}
                                  />
                                </Box>
                              ),
                            )}
                        <Box alignSelf="center">
                          <IconButton
                            style={{
                              visibility:
                                Number(fields?.length) === 1 && index === 0
                                  ? 'hidden'
                                  : 'visible',
                            }}
                            onClick={() => handleDelete(fields, index)}
                            disabled={disabledAfterAnalysis}
                          >
                            <DeleteSharp />
                          </IconButton>
                        </Box>
                      </Flex>
                    </Box>
                  ))}
                  <Box flex={0}>
                    {Number(fields?.length) < 3 && (
                      <Button
                        color="secondary"
                        disabled={
                          (fields && Number(fields?.length) > 2) ||
                          disabledAfterAnalysis ||
                          false
                        }
                        onClick={() => fields.push({})}
                      >
                        <Add />
                        {t('buttons.addProduct', 'Add product')}
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </FieldArray>
          )}
        </FormSpy>
      ))}
    </>
  );
};

export default MiningProductsInput;
