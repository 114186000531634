import React from 'react';
import { Box, Text } from 'rebass';
import { useTranslation } from 'react-i18next';

const StatisticsPage = () => {
  const { t } = useTranslation('common');
  return (
    <Box px={[2, 3, 4]} py={[2, 2, 3]}>
      <Text>{t('common:stirngs.underDevelopment', 'Under development..')}</Text>
    </Box>
  );
};

export default StatisticsPage;
