import { countries } from 'countries-list';
// Currency symbol don't have types
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import currencyToSymbolMap from 'currency-symbol-map/map';

export interface Option {
  label: string;
  labelIntl?: string;
  value: string | number | boolean;
}

const nullOption = {
  value: '',
  label: 'Select one',
  labelIntl: 'forms:options.selectOne',
};

export const countryOptions = [
  nullOption,
  ...(Object.keys(countries) as Array<keyof typeof countries>).map(key => ({
    value: key,
    label: countries[key].name,
    labelIntl: countries[key].name,
    currency: countries[key].currency,
  })),
];

export const fasteningMethodOptions: Option[] = [
  nullOption,
  {
    value: 'metalToMetal',
    label: 'Metal-to-Metal',
    labelIntl: 'forms:options.fasteningMethod.metalToMetal',
  },
  {
    value: 'pinStyle',
    label: 'Pin style',
    labelIntl: 'forms:options.fasteningMethod.pinStyle',
  },
  {
    value: 'keySegment',
    label: 'Key Segment',
    labelIntl: 'forms:options.fasteningMethod.keySegment',
  },
];

export const configrationOptions: Option[] = [
  nullOption,
  {
    value: 'single',
    label: 'Single',
    labelIntl: 'forms:options.configration.single',
  },
  {
    value: 'double',
    label: 'Double',
    labelIntl: 'forms:options.configration.double',
  },
];

export const miningProducts: Option[] = [
  nullOption,
  {
    value: 'bauxite',
    label: 'Bauxite',
    labelIntl: 'forms:options.mineral.bauxite',
  },
  {
    value: 'chromium',
    label: 'Chromium',
    labelIntl: 'forms:options.mineral.chromium',
  },
  {
    value: 'clay',
    label: 'Clay',
    labelIntl: 'forms:options.mineral.clay',
  },
  {
    value: 'coal',
    label: 'Coal',
    labelIntl: 'forms:options.mineral.coal',
  },
  {
    value: 'coke',
    label: 'Coke',
    labelIntl: 'forms:options.mineral.coke',
  },
  {
    value: 'copper',
    label: 'Copper',
    labelIntl: 'forms:options.mineral.copper',
  },
  {
    value: 'dolomite',
    label: 'Dolomite',
    labelIntl: 'forms:options.mineral.dolomite',
  },
  {
    value: 'gold',
    label: 'Gold',
    labelIntl: 'forms:options.mineral.gold',
  },
  {
    value: 'iron ore',
    label: 'Iron Ore',
    labelIntl: 'forms:options.mineral.ironOre',
  },
  {
    value: 'kaolim',
    label: 'Kaolim',
    labelIntl: 'forms:options.mineral.kaolim',
  },
  {
    value: 'lead',
    label: 'Lead',
    labelIntl: 'forms:options.mineral.lead',
  },
  {
    value: 'magnesite',
    label: 'Magnesite',
    labelIntl: 'forms:options.mineral.magnesite',
  },
  {
    value: 'manganese',
    label: 'Manganese',
    labelIntl: 'forms:options.mineral.manganese',
  },
  {
    value: 'molybdenum',
    label: 'Molybdenum',
    labelIntl: 'forms:options.mineral.molybdenum',
  },
  {
    value: 'nickel',
    label: 'Nickel',
    labelIntl: 'forms:options.mineral.nickel',
  },
  {
    value: 'platinum',
    label: 'Platinum',
    labelIntl: 'forms:options.mineral.platinum',
  },
  {
    value: 'potash',
    label: 'Potash',
    labelIntl: 'forms:options.mineral.potash',
  },
  {
    value: 'silver',
    label: 'Silver',
    labelIntl: 'forms:options.mineral.silver',
  },
  {
    value: 'slag',
    label: 'Slag',
    labelIntl: 'forms:options.mineral.slag',
  },
  {
    value: 'taconite',
    label: 'Taconite',
    labelIntl: 'forms:options.mineral.taconite',
  },
  {
    value: 'tin',
    label: 'Tin',
    labelIntl: 'forms:options.mineral.tin',
  },
  {
    value: 'tungsten',
    label: 'Tungsten',
    labelIntl: 'forms:options.mineral.tungsten',
  },
  {
    value: 'zinc',
    label: 'Zinc',
    labelIntl: 'forms:options.mineral.zinc',
  },
  {
    value: 'other',
    label: 'Other',
    labelIntl: 'forms.options.mineral.other',
  },
];

const currenciesList = [
  'AUD',
  'BRL',
  'CAD',
  'CLP',
  'CNY',
  'EUR',
  'RUB',
  'SAR',
  'SEK',
  'USD',
  'ZAR',
];

export const currencyOptions = [
  nullOption,
  ...currenciesList.map(curr => ({
    value: curr,
    label: `${curr} (${currencyToSymbolMap[curr]})`,
    labelIntl: `${curr} (${currencyToSymbolMap[curr]})`,
  })),
];

export const yesNoOptions: Option[] = [
  nullOption,
  {
    value: true,
    label: 'Yes',
    labelIntl: 'forms:options.yes',
  },
  {
    value: false,
    label: 'No',
    labelIntl: 'forms:options.no',
  },
];

export const grindingFactorOptions: Option[] = [
  nullOption,
  {
    value: 1.3,
    label: 'Dry',
    labelIntl: 'forms:options.dry',
  },
  {
    value: 1.0,
    label: 'Wet',
    labelIntl: 'forms:options.wet',
  },
];

export const millingCircuitFactorOptions: Option[] = [
  nullOption,
  {
    value: 1.0,
    label: 'Closed',
    labelIntl: 'forms:options.closed',
  },
  {
    value: 1.7,
    label: 'Open 98%',
    labelIntl: 'forms:options.open98',
  },
  {
    value: 1.57,
    label: 'Open 95%',
    labelIntl: 'forms:options.open95',
  },
  {
    value: 1.46,
    label: 'Open 92%',
    labelIntl: 'forms:options.open92',
  },
  {
    value: 1.4,
    label: 'Open 90%',
    labelIntl: 'forms:options.open90',
  },
  {
    value: 1.2,
    label: 'Open 80%',
    labelIntl: 'forms:options.open80',
  },
  {
    value: 1.1,
    label: 'Open 70%',
    labelIntl: 'forms:options.open70',
  },
  {
    value: 1.05,
    label: 'Open 60%',
    labelIntl: 'forms:options.open60',
  },
  {
    value: 1.035,
    label: 'Open 50%',
    labelIntl: 'forms:options.open50',
  },
];

export const mantlePieceOptions: Option[] = [
  {
    value: 0,
    label: 'Select one',
    labelIntl: 'forms:options.selectOne',
  },
  { value: 1, label: '1', labelIntl: '1' },
  { value: 2, label: '2', labelIntl: '2' },
  { value: 3, label: '3', labelIntl: '3' },
  { value: 4, label: '4', labelIntl: '4' },
  { value: 5, label: '5', labelIntl: '5' },
  { value: 6, label: '6', labelIntl: '6' },
];

export const feedMethodOptions: Option[] = [
  nullOption,
  {
    value: 'dumpTruck',
    label: 'Dump truck',
    labelIntl: 'forms:options.feedMethod.dumpTruck',
  },
  {
    value: 'apron',
    label: 'Apron',
    labelIntl: 'forms:options.feedMethod.apron',
  },
  {
    value: 'loader',
    label: 'Loader',
    labelIntl: 'forms:options.feedMethod.loader',
  },
  {
    value: 'railWagon',
    label: 'Rail wagon',
    labelIntl: 'forms:options.feedMethod.railWagon',
  },
  {
    value: 'beltFeeder',
    label: 'Belt Feeder',
    labelIntl: 'forms:options.feedMethod.beltFeeder',
  },
  {
    value: 'beltFeederFromSurgeBin',
    label: 'Belt Feeder From Surge Bin',
    labelIntl: 'forms:options.feedMethod.beltFeederFromSurgeBin',
  },
  {
    value: 'screen',
    label: 'Screen',
    labelIntl: 'forms:options.feedMethod.screen',
  },
  {
    value: 'vibratingFeeder',
    label: 'Vibrating Feeder',
    labelIntl: 'forms:options.feedMethod.vibratingFeeder',
  },
  {
    value: 'binWithRoundHole',
    label: 'Bin With a Round Hole (bin fed by screen)',
    labelIntl: 'forms:options.feedMethod.binWithRoundHole',
  },
  {
    value: 'screenDischarge',
    label: 'Screen Discharge',
    labelIntl: 'forms:options.feedMethod.screenDischarge',
  },
];

export const followUpTargetOptions: Option[] = [
  nullOption,
  {
    value: 'met',
    label: 'Met',
    labelIntl: 'forms:options.followUp.met',
  },
  {
    value: 'not met',
    label: 'Not met',
    labelIntl: 'forms:options.followUp.notMet',
  },
  {
    value: 'exceeded',
    label: 'Exceeded',
    labelIntl: 'forms:options.followUp.exceeded',
  },
];

export const statusOptions: Option[] = [
  {
    label: 'Value study',
    labelIntl: 'valueStudy',
    value: 'valueStudy',
  },
  {
    label: 'Data collection',
    labelIntl: 'dataCollection',
    value: 'dataCollection',
  },
  {
    label: 'Analysis & re-engineetring',
    labelIntl: 'analysis',
    value: 'analysis',
  },
  {
    label: 'Market area review',
    labelIntl: 'marketReview',
    value: 'marketReview',
  },
  {
    label: 'Follow up',
    labelIntl: 'followUp',
    value: 'followUp',
  },
];
