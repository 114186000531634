const navLinks = [
  {
    to: '/',
    text: 'cases',
  },
  {
    to: '/support', // TODO: add proper support page.
    text: 'support',
  },
];

export default navLinks;
