import { shape } from 'prop-types';
import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import idx from 'idx';
import { useTranslation } from 'react-i18next';

import bgImage from './login-bg.svg';
import logoUrl from '../../images/metso-main-logo-pack-20230621/Metso_Logo_Black_RGB.svg';

const urlPrefix =
  process.env.NODE_ENV !== 'production' ? 'http://localhost:5002' : '';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  background-image: url(${bgImage});
`;

const Title = styled.h1`
  font-size: 2.25rem;
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 30px;
`;

const TitleSmall = styled.p`
  font-size: 1.375rem;
  font-weight: 600;
  margin-bottom: 0px;
`;

const Logo = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 80px;
`;

const BuildId = styled.div`
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  color: rgba(255, 255, 255, 1);
`;

const propTypes = {
  history: shape({}).isRequired,
};

const LoginContainer = ({ history }) => {
  const { t } = useTranslation('common');
  const returnTo = idx(history, (_) => _.location.state.from.pathname);
  return (
    <Container>
      <Logo alt="Metso Logo" src={logoUrl} />
      <TitleSmall>{t('common:strings.welcomeTo', 'Welcome to')}</TitleSmall>
      <Title>Crusher Wears Value Optimizer</Title>
      <Button
        id="loginBtn"
        variant="contained"
        color="primary"
        size="large"
        href={`${urlPrefix}/auth/metso${
          history.action === 'REPLACE' && returnTo !== '/'
            ? `?returnTo=${returnTo}`
            : ''
        }`}
      >
        {t('common:buttons.loginWithMetso', 'Login with Metso Login')}
      </Button>
      <BuildId>{process.env.REACT_APP_BUILD_ID}</BuildId>
    </Container>
  );
};

LoginContainer.propTypes = propTypes;

export default LoginContainer;
