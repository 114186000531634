import React from 'react';
import {
  number,
  string,
  oneOfType,
  arrayOf,
  func,
  bool,
  InferProps,
} from 'prop-types';
import { Box, Flex } from 'rebass';
import { FormSpy, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const propTypes = {
  subscribeToValues: bool,
  endAdornment: string,
  parse: func,
  width: oneOfType([string, arrayOf(string), number, arrayOf(number)]),
  name: string.isRequired,
  hidden: bool,
  disabled: oneOfType([bool, func]),
  validate: func,
  type: string,
  marginBottom: number,
  currency: string,
  crusherType: string,
  label: string,
  labelIntl: string,
  flexDirection: oneOfType([string, arrayOf(string)]),
  withoutLine: bool,
  onChange: func,
};

const defaultProps = {
  subscribeToValues: false,
  endAdornment: '',
  parse: (val: string) => val || null,
  width: null,
  hidden: false,
  disabled: false,
  type: undefined,
  currency: undefined,
  label: '',
  labelIntl: '',
  validate: undefined,
  onChange: undefined,
};

const RenderField = ({
  subscribeToValues,
  endAdornment,
  parse,
  width,
  name,
  hidden,
  disabled,
  validate,
  type,
  label,
  labelIntl,
  currency,
  onChange,
  ...field
}: InferProps<typeof propTypes>) => {
  const { t } = useTranslation('forms');
  return (
    <Box
      width={width as any}
      key={name}
      px={2}
      style={{ display: hidden ? 'none' : 'block' }}
    >
      <Flex alignItems="center" width={1}>
        <Box width={1}>
          <FormSpy subscription={{ values: subscribeToValues } as any}>
            {({ values, ...rest }) => (
              <Field
                name={name}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(validate ? { validate } : {})}
                type={type as any}
                width={1}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                values={values}
                label={t(labelIntl || '', label!)}
                currency={currency}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(endAdornment &&
                endAdornment.includes('€') &&
                (values?.currency || currency)
                  ? {
                      endAdornment: t(
                        `adornments.${endAdornment}`,
                        endAdornment!,
                      ).replace('€', values?.currency || currency),
                    }
                  : {
                      endAdornment: t(
                        `adornments.${endAdornment}`,
                        endAdornment!,
                      ),
                    })}
                parse={parse || undefined}
                disabled={
                  disabled && typeof disabled === 'function'
                    ? disabled(values)
                    : disabled
                }
                {...(onChange
                  ? {
                      onChange: onChange(rest.form),
                    }
                  : {})}
              />
            )}
          </FormSpy>
        </Box>
      </Flex>
    </Box>
  );
};

RenderField.defaultProps = defaultProps;
RenderField.propTypes = propTypes;

export default RenderField;
