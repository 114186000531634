import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

const fadeOut = keyframes`
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const LoadingWrapper = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  background: white;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
`;
const LoadingDot = styled.span`
  display: flex;
  align-items: center;
  padding-left: 1px;
  padding-right: 1px;
  font-size: 3rem;
  animation: ${fadeOut} 1.4s linear infinite;
  animation-fill-mode: both;
  animation-delay: ${props => (props.delay ? props.delay : '0')};
`;

const Loading = () => (
  <LoadingWrapper data-testid="loading-text">
    <LoadingDot data-testid="loading-dot">•</LoadingDot>
    <LoadingDot delay="0.2s">•</LoadingDot>
    <LoadingDot delay="0.4s">•</LoadingDot>
  </LoadingWrapper>
);

export default Loading;
