import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => {
  return {
    black: {
      backgroundColor: theme.colors.black,
      borderRadius: '0',
      color: '#fff',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: theme.colors.orange,
      },
    },
    orange: {
      backgroundColor: theme.colors.orange,
      borderRadius: '0',
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.colors.black,
      },
    },
    gray: {
      backgroundColor: theme.colors.gray,
      borderRadius: '0',
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.colors.orange,
      },
    },
    turquoise: {
      backgroundColor: theme.colors.turquoise,
      color: '#fff',
      margin: '0 auto',
    },
    fullWidth: {
      width: '100%',
    },
  };
});

const Btn = props => {
  const classes = useStyles();

  const fullWidth = props.fullWidth ? ` ${classes.fullWidth}` : '';
  // cosnt clas = props.fulLWidth ? classes.fullWidth : null
  return (
    <Button className={`${classes[props.bgColor]}${fullWidth}`} {...props}>
      {props.children}
    </Button>
  );
};

export default Btn;
