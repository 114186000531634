import React from 'react';
import { string, shape, bool } from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import styled from 'styled-components/macro';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Flex } from 'rebass';

import { Error, Asterisk } from '../Input';
import Icon from '../../Icon/index';
import colors from '../../../theme/colors';
import Tooltip from '../../Tooltip';

const propTypes = {
  input: shape({}).isRequired,
  meta: shape({}).isRequired,
  label: string,
  id: string,
  mandatoryDesign: bool,
  tooltip: string,
  tooltipIntl: string,
};

const defaultProps = {
  label: '',
  id: 'date-input',
  mandatoryDesign: false,
  tooltip: '',
  tooltipIntl: '',
};

const StyledDatePicker = styled(DatePicker)`
  border: none;
  input {
    ::placeholder {
      opacity: 0.3;
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.lightGray};
    }
  }
`;

export const StyledLabel = styled.label`
  color: ${props => (props.labelColor ? props.labelColor : props.theme.black)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :not(.checkbox) {
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 600;
    margin-bottom: 3px;
  }
`;
const dateFns = new DateFnsUtils();

const DateInput = ({
  input: { value, onChange, onBlur, ...inputRest },
  meta,
  label,
  id,
  mandatoryDesign,
  disabled,
  tooltip,
  tooptipIntl,
}) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Flex flexDirection="column">
      <Flex alignItems="center">
        <StyledLabel htmlFor={id}>
          {label}
          {mandatoryDesign && <Asterisk>*</Asterisk>}
        </StyledLabel>
        {tooltip && <Tooltip tooltip={tooltip} tooltipIntl={tooptipIntl} />}
      </Flex>
      <StyledDatePicker
        disableToolbar
        allowKeyboardControl={!disabled}
        autoOk
        variant="inline"
        format="yyyy-MM-dd"
        margin="none"
        id={id}
        value={value || null}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputRest}
        onChange={(v, str) => {
          onChange(v);
          if (new Date(str)) {
            onBlur();
          }
        }}
        placeholder={dateFns.format(new Date(), 'yyyy-MM-dd')}
        InputProps={{
          disableUnderline: true,
          margin: 'none',
          style: {
            '::placeholder': {
              opacity: 0.1,
            },
          },
        }}
        disabled={disabled}
      />
      <Error>
        {meta && meta.touched && meta.error && (
          <>
            <Icon name="error_outline" color={colors.darkTerracotta} />{' '}
            {meta.error}
          </>
        )}
        &nbsp;
      </Error>
    </Flex>
  </MuiPickersUtilsProvider>
);

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export default DateInput;
