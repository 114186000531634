import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import { bool, string, shape } from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import axios from 'axios';
import { Flex } from 'rebass';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { useMutation } from '@apollo/react-hooks';
import { FieldInputProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { isApolloError } from 'apollo-client';
import withAuth from '../../../containers/withAuth';
import { StyledLabel, Error as InputError, Asterisk } from '../Input';
import Icon from '../../Icon';
import { allColors } from '../../../theme/colors';
import { DELETE_ATTACHMENT } from '../../../store/apollo/mutations';
import { Attachment } from '../../../generated/graphql';
import Tooltip from '../../Tooltip';
import { FILE_UPLOAD_SETTINGS } from '../../../config';

const StyledChip = styled(Chip)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const Container = styled(Flex)`
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const UploadButton = styled(Button)`
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid ${({ theme }) => theme.colors.green};
  background-color: ${({ theme }) => theme.colors.white};

  svg {
    margin-left: 5px;
    margin-right: 5px;
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
  }
`;

type RenderAreaProps = {
  isDragActive: boolean;
  loading: boolean;
  error?: string | null;
  label?: string;
  t: TFunction;
  disabled: boolean;
};
const renderAreaContent = ({
  isDragActive,
  loading = false,
  error = null,
  t,
  disabled,
}: RenderAreaProps) => {
  if (error) {
    return (
      <UploadButton variant="outlined" fullWidth>
        {t('common:buttons.upload', 'Upload')}
        <CloudUploadIcon />
      </UploadButton>
    );
  }
  if (loading) {
    return (
      <Flex style={{ position: 'relative' }}>
        <UploadButton variant="contained" disabled color="inherit" fullWidth>
          {t('common:buttons.uploading', 'Uploading')}
        </UploadButton>
        <CircularProgress
          size={28}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      </Flex>
    );
  }
  if (isDragActive) {
    return (
      <UploadButton variant="contained" color="inherit" fullWidth>
        {t('common:buttons.dropFiles', 'Drop files')}
        <CloudUploadIcon />
      </UploadButton>
    );
  }
  return (
    <Flex style={{ position: 'relative' }}>
      <UploadButton
        variant="contained"
        color="inherit"
        fullWidth
        disabled={disabled}
      >
        {t('common:buttons.upload', 'Upload')}
        <CloudUploadIcon />
      </UploadButton>
    </Flex>
  );
};

renderAreaContent.propTypes = {
  isDragActive: bool.isRequired,
  loading: bool.isRequired,
  error: string,
};

renderAreaContent.defaultProps = {
  error: undefined,
};

const handleDelete = async (
  setValue: any,
  file: Attachment | null,
  mutation: any,
  setLoading: any,
  value: any,
  setError: any,
) => {
  setError(null);
  if (file?.id) {
    setLoading(true);
    try {
      await mutation({
        variables: {
          where: {
            id: { _eq: file.id },
          },
        },
      });
    } catch (e) {
      setLoading(false);
      if (e instanceof Error && isApolloError(e)) {
        setError(e.message);
      }
    }
    setLoading(false);
    setValue(value.filter((item: Attachment) => item.id !== file.id));
    return null;
  }
  setValue(
    value.filter(
      (item: Attachment) => item.originalname !== file?.originalname,
    ),
  );
  return null;
};

type DropZoneProps = {
  input: FieldInputProps<Attachment | undefined>;
  idToken: string;
  label: string;
  tooltip?: string;
  tooltipIntl?: string;
  disabled: boolean;
  mandatoryDesign: boolean;
};
const DropzoneMultiple = ({
  input,
  idToken,
  label,
  tooltip,
  tooltipIntl,
  disabled,
  mandatoryDesign,
}: DropZoneProps) => {
  // Upload states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);
  const onDrop = useCallback(
    // eslint-disable-next-line consistent-return
    async attachments => {
      if (attachments?.length === 0) {
        return;
      }
      const amountOfattachedFiles = Array.isArray(input.value)
        ? input?.value?.length
        : 0;
      if (
        amountOfattachedFiles + attachments?.length >
        FILE_UPLOAD_SETTINGS.maxFiles
      ) {
        setError('Maximum amount of 10 files exceeded');
      } else {
        const formData = new FormData();
        setError(null);
        setLoading(true);
        attachments.map((i: string) => formData.append('attachments', i));
        let res: any;

        try {
          res = await axios.post('/storage/upload', formData, {
            headers: {
              'content-type': 'multipart/form-data',
              authorization: `Bearer ${idToken}`,
            },
          });
        } catch (e) {
          setLoading(false);
          if (axios.isAxiosError(e)) {
            setError(e.response?.data.message);
          } else {
            setError('Error occured');
          }
          return;
        }
        setLoading(false);
        input.onChange(
          input.value && Array.isArray(input.value)
            ? input.value.concat(res.data ? res.data : [])
            : [],
        );
      }
    },
    [idToken, input],
  );

  const { t } = useTranslation('common');

  const {
    getRootProps,
    getInputProps,
    rejectedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: FILE_UPLOAD_SETTINGS.allowedExtensions,
    onDrop,
    multiple: true,
    maxSize: FILE_UPLOAD_SETTINGS.maxFileSize,
    disabled,
  });

  React.useEffect(() => {
    if (rejectedFiles?.length) {
      setError(t('File size exceeds 200Mb'));
    }
  }, [rejectedFiles, t]);

  return (
    <>
      <Container
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} aria-label={label} />
        <Flex alignItems="center">
          <StyledLabel>
            {label}
            {mandatoryDesign && <Asterisk>*</Asterisk>}
          </StyledLabel>
          {tooltip && <Tooltip tooltip={t(tooltipIntl || '', tooltip)} />}
        </Flex>
        {renderAreaContent({
          isDragActive,
          loading,
          error,
          label,
          t,
          disabled,
        })}
        <InputError>
          {error && (
            <>
              <Icon name="error_outline" color={allColors.darkTerracotta} />{' '}
              {error}
            </>
          )}
        </InputError>
      </Container>
      <Flex flexWrap="wrap">
        {input.value &&
          Array.isArray(input.value) &&
          !!input.value.length &&
          input.value.map((file, i) => (
            <StyledChip
              key={file?.id || i}
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              // href={`/storage/assets/${file?.blob}`}
              component="a"
              onClick={async () => {
                // const res = await fetch(`/storage/assets/${file?.blob}`);
                const res = await axios.get(`/storage/assets/${file?.blob}`, {
                  headers: {
                    'content-type': 'multipart/form-data',
                    authorization: `Bearer ${idToken}`,
                  },
                });
                const { data } = res;
                window.location.href = data.link;
              }}
              target="_blank"
              rel="noopener noreferrer"
              label={`${file?.originalname}`}
              onDelete={e => {
                e.preventDefault();
                handleDelete(
                  input.onChange,
                  file || null,
                  deleteAttachment,
                  setLoading,
                  input.value,
                  setError,
                );
              }}
            />
          ))}
      </Flex>
    </>
  );
};

DropzoneMultiple.propTypes = {
  input: shape({}).isRequired,
  idToken: string,
  label: string,
  mandatoryDesign: bool,
};

DropzoneMultiple.defaultProps = {
  idToken: undefined,
  label: '',
  mandatoryDesign: false,
};

export default withAuth(DropzoneMultiple);
