import { string, number, boolean } from 'yup';
import { format, isValid } from 'date-fns';

import Input from '../components/inputs/Input';
import NumberInput from '../components/inputs/NumberInput';
import DropZone from '../components/inputs/DropZoneMultiple';
import Checkbox from '../components/inputs/Checkbox/inbox';
import SelectInput from '../components/inputs/SelectInput';
import Textarea from '../components/inputs/Textarea';
import colors from '../theme/colors';
import NumberOfPieces from '../components/inputs/NumberOfPieces';
import DateInput from '../components/inputs/DateInput';
import ConeMantleConfiguration from '../components/inputs/ConeMantleConfiguration';
import MantleArrayInputDataCollectCC from '../components/inputs/MantleArrayInputDataCollectCC';
import MantleArrayInputDataCollect from '../components/inputs/MantleArrayInputDataCollect';
import PgMantleConfiguration from '../components/inputs/PgMantleConfiguration';
import PgMantlesPerSetForm from '../components/inputs/PgMantlesPerSetForm';
import PgTiersArrayInput from '../components/inputs/PgTiersArrayInput';
import MiningProductsArrayInput from '../components/inputs/MiningProductsArrayInput';
import ImprovementTargetsOtherInfo from '../components/inputs/ImprovementTargetsOtherInfo';
import { roundCurrency, parseNumberInput } from '../utils/formatNumber';
import { FormFieldsInterface } from '../ts/interfaces';
import {
  countryOptions,
  fasteningMethodOptions,
  miningProducts,
  currencyOptions,
  yesNoOptions,
  configrationOptions,
  grindingFactorOptions,
  millingCircuitFactorOptions,
  feedMethodOptions,
  mantlePieceOptions,
} from './selectOptions';
import { parseError } from '../utils/toasts';

export const validate =
  (validation: { validate: (val: string | number | null) => void }) =>
  async (value: string | number | null) => {
    try {
      await validation.validate(value);
      return null;
    } catch (e) {
      return parseError(e);
    }
  };

const getTimezoneOffset = () => {
  function addLeadingZero(n: number) {
    return (n < 10 ? '0' : '') + n;
  }
  let offset = new Date().getTimezoneOffset();
  const sign = offset < 0 ? '+' : '-';
  offset = Math.abs(offset);
  const p = offset / 60;
  return sign + addLeadingZero(p || 0) + addLeadingZero(offset % 60);
};

export const formFields: FormFieldsInterface = {
  pg: [
    // Step 1
    {
      id: 1,
      title: 'Customers and crusher details',
      titleIntl: 'tabs.customerCrusherDetails',
      // paper
      sections: [
        {
          id: 'basicInformation',
          title: 'Basic information',
          titleIntl: 'forms:headings.basicInfo',
          // grid inside paper
          subsections: [
            {
              title: '',
              subtitle: '',
              width: 1,
              id: 'basicInformationSub',
              // actual fields
              fields: [
                {
                  name: 'name',
                  type: 'text',
                  label: 'Case Name',
                  labelIntl: 'forms:labels.name',
                  validate: validate(string().required()),
                  component: Input,
                  autoComplete: 'new-password',
                  width: [1, 1, 1 / 2, 1 / 4],
                  mandatoryDesign: true,
                },
                {
                  name: 'customer.id',
                  label: 'Customer Id',
                  labelIntl: 'forms:labels.customerId',
                  type: 'text',
                  hidden: true,
                  component: Input,
                },
                {
                  name: 'customer.name',
                  type: 'text',
                  label: 'Customer Name',
                  labelIntl: 'forms:labels.customerName',
                  validate: validate(string().nullable()),
                  component: Input,
                  width: [1, 1, 1 / 2, 1 / 4],
                  mandatoryDesign: true,
                },
                {
                  name: 'customer.metsoId',
                  type: 'text',
                  label: 'Metso ID',
                  labelIntl: 'forms:labels.metsoId',
                  validate: validate(string().nullable()),
                  component: Input,
                  width: [1, 1, 1 / 2, 1 / 4],
                },
                {
                  name: 'customer.siteName',
                  type: 'text',
                  label: 'Site Name',
                  labelIntl: 'forms:labels.siteName',
                  validate: validate(string().nullable()),
                  component: Input,
                  width: [1, 1, 1 / 2, 1 / 4],
                },
                {
                  name: 'customer.country',
                  label: 'Country',
                  labelIntl: 'forms:labels.country',
                  width: [1, 1, 1 / 2, 1 / 4],
                  options: countryOptions,
                  validate: validate(string().nullable()),
                  component: SelectInput,
                  placeholder: 'Select one',
                  autoComplete: 'off',
                  format: (val) =>
                    countryOptions.find((item) => item.value === val),
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  mandatoryDesign: true,
                },
                {
                  name: 'currency',
                  label: 'Currency',
                  labelIntl: 'forms:labels.currency',
                  width: [1, 1, 1 / 2, 1 / 4],
                  options: currencyOptions,
                  validate: validate(string().nullable()),
                  component: SelectInput,
                  placeholder: 'Select one',
                  autoComplete: 'new-password',
                  format: (val) =>
                    currencyOptions.find((item) => item.value === val),
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  mandatoryDesign: true,
                },
                {
                  name: 'annualCrushingLineCapacity',
                  type: 'text',
                  label: 'Annual Crushing Line Capacity',
                  labelIntl: 'forms:labels.annualCrushingLineCapacity',
                  width: [1, 1, 1 / 2, 1 / 4],
                  autoComplete: 'new-password',
                  endAdornment: 'metric/tonnes',
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  tooltip:
                    'What is the current annual crushing capacity in the circuit where the crusher is operating? In other words, how many tonnes the crushing line is currently able to produce in a year?',
                  tooltipIntl: 'tooltips:annualCrushingLineCapacity',
                  mandatoryDesign: true,
                },
                {
                  name: 'dueDate',
                  type: 'date',
                  label: 'Due date',
                  labelIntl: 'forms:labels.dueDate',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: DateInput,
                  parse: (val) =>
                    isValid(val) ? format(new Date(val), 'yyyy-MM-dd') : val,
                  format: (val) =>
                    val ? new Date(`${val} GMT${getTimezoneOffset()}`) : null,
                  tooltip:
                    'Please, define the due date during the kick-off meeting with the assigned expert.',
                  tooltipIntl: 'tooltips:dueDate',
                  mandatoryDesign: true,
                },
                {
                  name: 'operatingProfit',
                  type: 'text',
                  label: 'Operating Profit',
                  labelIntl: 'forms:labels.operatingProfit',
                  endAdornment: '%',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().min(0).max(100).nullable()),
                  component: NumberInput,
                  parse: parseNumberInput,
                  mandatoryDesign: true,
                  tooltip:
                    "Often available on the customer's website, especially if it's a stock exchange-listed company. Also, you might get this information from the customer people at the site. Alternatively, you can select to use the minimum value of 15%.",
                  tooltipIntl: 'tooltips:operatingProfit',
                },
                {
                  name: 'salesforceOpportunityNumber',
                  type: 'text',
                  label: 'Salesforce Opportunity number',
                  labelIntl: 'forms:labels.salesforceOpportunityNumber',
                  width: [1, 1, 1 / 2, 1 / 4],
                  autoComplete: 'new-password',
                  component: Input,
                  enabledAfterAnalysis: true,
                },
                {
                  name: 'additionalBasicInformation',
                  type: 'textarea',
                  label: 'Additional Basic Information',
                  labelIntl: 'forms:labels.additionalBasicInformation',
                  width: [1, 1, 1 / 2, 1 / 4],

                  validate: validate(string().nullable()),
                  tooltip: 'Open field for additional information',
                  tooltipIntl: 'tooltips:additionalBasicInformation',
                  component: Input,
                  multiline: true,
                  rows: 5,
                },
              ],
            },
          ],
        },
        {
          id: 'crusherInformation',
          title: 'Crusher information',
          titleIntl: 'forms:headings.crusherInfo',
          subsections: [
            {
              title: '',
              subtitle: '',
              width: 1,
              id: 'crusherInfoSub',
              fields: [
                {
                  name: 'crusherModel',
                  type: 'text',
                  label: 'Crusher Model',
                  labelIntl: 'forms:labels.crusherModel',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: Input,
                  mandatoryDesign: true,
                },
                {
                  name: 'numberOfCrushers',
                  type: 'text',
                  label: 'Number of Crushers',
                  labelIntl: 'forms:labels.numberOfCrushers',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  component: Input,
                  mandatoryDesign: true,
                  tooltip:
                    'Be aware that results are multiplied by the number of crushers set here.',
                  tooltipIntl: 'tooltips:numberOfCrushers',
                },
                {
                  name: 'crusherSerialNumber',
                  type: 'text',
                  label: 'Serial number',
                  labelIntl: 'forms:labels.crusherSerialNumber',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: Input,
                  tooltip:
                    'If there is more than one crusher in scope, separate the serial numbers using "/".',
                  tooltipIntl: 'tooltips:crusherSerialNumber',
                },
                {
                  name: 'crusherCss',
                  type: 'text',
                  label: 'Closed side setting',
                  labelIntl: 'forms:labels.crusherCss',
                  width: [1, 1, 1 / 2, 1 / 4],
                  endAdornment: 'mm',
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                },
                {
                  name: 'crusherOss',
                  type: 'text',
                  label: 'Open side setting',
                  labelIntl: 'forms:labels.crusherOss',
                  width: [1, 1, 1 / 2, 1 / 4],
                  endAdornment: 'mm',
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                },
                {
                  name: 'crusherSpeed',
                  type: 'text',
                  label: 'Speed (mainshaft)',
                  labelIntl: 'forms:labels.crusherSpeed',
                  endAdornment: 'rpm',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                  tooltip: 'It may be found in the machine´s operation manual.',
                  tooltipIntl: 'tooltips:crusherSpeed',
                },
                {
                  name: 'crusherCounterShaftSpeed',
                  type: 'text',
                  label: 'Speed (counter shaft)',
                  labelIntl: 'forms:labels.crusherCounterShaftSpeed',
                  endAdornment: 'rpm',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: false,
                },
                {
                  name: 'crusherStroke',
                  type: 'text',
                  label: 'Stroke',
                  labelIntl: 'forms:labels.crusherStroke',
                  endAdornment: 'mm',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                },
                {
                  name: 'crusherRatedPower',
                  type: 'text',
                  label: 'Rated power',
                  labelIntl: 'forms:labels.crusherRatedPower',
                  endAdornment: 'KW',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                  tooltip: 'The power of the motor installed in the crusher.',
                  tooltipIntl: 'tooltips:crusherRatedPower',
                },
                {
                  name: 'operatingHoursPerDay',
                  type: 'text',
                  label: 'Operating Hours',
                  labelIntl: 'forms:labels.operatingHoursPerDay',
                  endAdornment: 'h/day',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().min(0).max(24).nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                  tooltip:
                    'How many hours per day the crusher is effectively producing? You can inform the average.',
                  tooltipIntl: 'tooltips:operatingHoursPerDay',
                },
                {
                  name: 'operatingDaysPerYear',
                  type: 'text',
                  label: 'Operating Days',
                  labelIntl: 'forms:labels.operatingDaysPerYear',
                  endAdornment: 'days/year',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().min(1).max(366).nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                  tooltip:
                    'How many days per year the crusher is effectively in operation? You can inform the average.',
                  tooltipIntl: 'tooltips:operatingDaysPerYear',
                },
                {
                  name: 'additionalCrusherInformation',
                  type: 'textarea',
                  label: 'Additional Crusher Information',
                  labelIntl: 'forms:labels.additionalCrusherInformation',
                  width: [1, 1, 1 / 2, 1 / 4],

                  validate: validate(string().nullable()),
                  tooltip: 'Open field for additional information',
                  tooltipIntl: 'tooltips:additionalCrusherInformation',
                  component: Input,
                  multiline: true,
                  rows: 5,
                },
              ],
            },
          ],
        },
        {
          id: 'currentCavityInformation',
          title: 'Current chamber information',
          titleIntl: 'forms:headings.chamberInfo',
          subsections: [
            {
              title: '',
              subtitle: '',
              width: 1,
              id: 'currentCavityInformationSub',
              fields: [
                {
                  name: 'crusherCavityType',
                  type: 'text',
                  label: 'Cavity details',
                  labelIntl: 'forms:labels.crusherCavityType',
                  width: [1, 1, 1 / 2, 1 / 3],
                  validate: validate(string().nullable()),
                  component: Input,
                  tooltip:
                    'Provide as many details as possible, such as alloy used, throw and setting combination, whether this is an already optimized cavity, etc.',
                  tooltipIntl: 'tooltips:crusherCavityType',
                },
                {
                  name: 'partSets[0].supplierName',
                  type: 'text',
                  label: 'Wear Part Supplier',
                  labelIntl: 'forms:labels.supplierName',
                  width: [1, 1, 1 / 2, 1 / 3],
                  validate: validate(string().nullable()),
                  component: Input,
                },
                {
                  name: 'partSets[0].id',
                  type: 'text',
                  label: 'id',
                  hidden: true,
                  component: Input,
                },
                {
                  name: 'partSets[0].totalCrushingCapacity',
                  type: 'text',
                  label: 'Single Crusher Capacity',
                  labelIntl: 'forms:labels.totalCrushingCapacity',
                  width: [1, 1, 1 / 2, 1 / 3],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'tonnes/hour',
                  tooltip:
                    'What is the current capacity of the crusher to be optimized? If there are more similar crushers in scope, provide the capacity of a single one.',
                  tooltipIntl: 'tooltips:totalCrushingCapacity',
                  mandatoryDesign: true,
                },
                {
                  name: 'partSets[0].crusherWearLifePerConcaveSetHours',
                  type: 'text',
                  label: 'Wear life per concave sets, hours',
                  labelIntl: 'forms:labels.crusherWearLifePerConcaveSetHours',
                  width: [1, 1, 1 / 2, 1 / 3],
                  subscribeToValues: true,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'hours',
                  mandatoryDesign: true,
                },
                {
                  name: 'partSets[0].crusherWearLifePerConcaveSetTons',
                  type: 'text',
                  label: 'Wear life per concave sets, tonnes',
                  labelIntl: 'forms:labels.crusherWearLifePerConcaveSetTons',
                  width: [1, 1, 1 / 2, 1 / 3],
                  subscribeToValues: true,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'tonnes',
                  mandatoryDesign: true,
                  // disabled: true,
                },
                {
                  name: 'additionalChamberInformation',
                  type: 'textarea',
                  label: 'Additional Chamber Information',
                  labelIntl: 'forms:labels.additionalChamberInformation',
                  width: [1, 1, 1 / 2, 1 / 4],

                  validate: validate(string().nullable()),
                  tooltip: 'Open field for additional information',
                  tooltipIntl: 'tooltips:additionalChamberInformation',
                  component: Input,
                  multiline: true,
                  rows: 5,
                },
              ],
            },
          ],
        },
        {
          id: 'miningProductDetails',
          title: 'Mining product details',
          titleIntl: 'forms:headings.productDetails',
          subsections: [
            {
              id: 'miningProductsDetailsSub',
              subtitle: 'Mining products',
              component: MiningProductsArrayInput,
              width: 1,
              fields: [
                {
                  name: 'miningProducts',
                  type: 'text',
                  label: 'Mining product',
                  labelIntl: 'forms:labels.miningProduct',
                  arrayFields: [
                    {
                      name: 'id',
                      type: 'text',
                      hidden: true,
                      validate: validate(string().nullable()),
                      component: Input,
                    },
                    {
                      name: 'name',
                      type: 'text',
                      label: 'Mining product',
                      labelIntl: 'forms:labels.miningProduct',
                      placeholder: 'Select one',
                      width: [1, 1, 1 / 2, 1 / 5],
                      validate: validate(string().nullable()),
                      component: SelectInput,
                      options: miningProducts,
                      parse: (obj) => obj.value,
                      format: (val) =>
                        miningProducts.find((item) => item.value === val),
                    },
                    {
                      name: 'nameOther',
                      type: 'text',
                      label: 'Product name',
                      labelIntl: 'forms:labels.miningProductNameOther',
                      width: [1, 1, 1 / 2, 1 / 5],
                      validate: validate(string().nullable().required()),
                      component: Input,
                      endAdornment: '',
                    },
                    {
                      name: 'recoveryRatePercent',
                      type: 'text',
                      label: 'Recovery',
                      labelIntl: 'forms:labels.recovery',
                      width: [1, 1, 1 / 2, 1 / 5],
                      validate: validate(number().min(0).max(100).nullable()),
                      parse: parseNumberInput,
                      component: NumberInput,
                      endAdornment: '%',
                      tooltip:
                        'The current mineral recovery percentage, indicates what percentage of valuable product are expected to be available for sale after the refining process has taken place.',
                      tooltipIntl: 'tooltips:recovery',
                      enabledAfterAnalysis: true,
                    },
                    {
                      name: 'price',
                      type: 'text',
                      label: 'Price',
                      labelIntl: 'forms:labels.price',
                      width: [1, 1, 1 / 2, 1 / 5],
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      format: roundCurrency,
                      component: NumberInput,
                      endAdornment: '€/tonne',
                      tooltip:
                        'The current mining product price on the market.',
                      tooltipIntl: 'tooltips:miningProductPrice',
                      enabledAfterAnalysis: true,
                    },
                    {
                      name: 'grade',
                      type: 'text',
                      label: 'Grade',
                      labelIntl: 'forms:labels.grade',
                      width: [1, 1, 1 / 2, 1 / 5],
                      validate: validate(number().min(0).max(100).nullable()),
                      parse: parseNumberInput,
                      component: NumberInput,
                      endAdornment: '%',
                      tooltip:
                        'The current average proportion of valuable product contained in the ore crushed at the site.',
                      tooltipIntl: 'tooltips:grade',
                      enabledAfterAnalysis: true,
                    },
                    {
                      name: 'grading',
                      type: 'text',
                      label: 'Grading',
                      labelIntl: 'forms:labels.grading',
                      width: [1, 1, 1 / 2, 1 / 5],
                      // disabled: true,
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      component: NumberInput,
                      endAdornment: 'gm/tonne',
                      tooltip:
                        'The amount of valuable product calculated per tonne of ore processed.',
                      tooltipIntl: 'tooltips:grading',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    // Step 2
    {
      id: 2,
      title: 'Application data',
      titleIntl: 'tabs.applicationData',
      sections: [
        {
          title: 'Feed information',
          titleIntl: 'forms:headings.feedInfo',
          id: 'feedInformation',
          flexDirection: 'column',
          subsections: [
            {
              width: 1,
              id: 'feedInformationSub',
              fields: [
                {
                  name: 'feedRockTestId',
                  type: 'text',
                  label: 'Rock test id',
                  labelIntl: 'forms:labels.rockTestId',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: Input,
                },
                {
                  name: 'feedAbrasiveness',
                  type: 'text',
                  label: 'Abrasiveness',
                  labelIntl: 'forms:labels.feedAbrasiveness',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'g/t',
                  mandatoryDesign: true,
                  tooltip: 'abrasiveness',
                },
                {
                  name: 'feedCrushabilityPercent',
                  type: 'text',
                  label: 'Crushability',
                  labelIntl: 'forms:labels.feedCrushabilityPercent',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().min(0).max(100).nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: '%',
                  mandatoryDesign: true,
                  tooltip: 'crushability',
                },
                {
                  name: 'feedOn',
                  type: 'text',
                  label: 'On/off feed',
                  labelIntl: 'forms:labels.feedOn',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: SelectInput,
                  options: yesNoOptions,
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  format: (val) =>
                    yesNoOptions.find((item) => item.value === val),
                  tooltip:
                    'Is the crusher trickle fed? Trickle feed = incosistent flow of material',
                  tooltipIntl: 'tooltips:feedOn',
                  mandatoryDesign: true,
                },
                {
                  name: 'feedOreType',
                  type: 'text',
                  label: 'Ore type',
                  labelIntl: 'forms:labels.feedOreType',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: Input,
                  tooltip:
                    'The name of the ore, e.g: hematite, goethite, pyrite, pyrrhotite, etc.',
                  tooltipIntl: 'tooltips:feedOreType',
                },
                {
                  name: 'feedMaxSize',
                  type: 'text',
                  label: 'Max feed size',
                  labelIntl: 'forms:labels.feedMaxSize',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'mm',
                  tooltip:
                    'There are 3 dimesions in a rock (length, width and height), use the 2nd largest here.',
                  tooltipIntl: 'tooltips:feedSize',
                  mandatoryDesign: true,
                },
                {
                  name: 'feedMinSize',
                  type: 'text',
                  label: 'Min feed size',
                  labelIntl: 'forms:labels.feedMinSize',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'mm',
                  tooltip:
                    'There are 3 dimesions in a rock (length, width and height), use the 2nd largest here.',
                  tooltipIntl: 'tooltips:feedSize',
                  mandatoryDesign: true,
                },
                {
                  name: 'feedSolidDensity',
                  type: 'text',
                  label: 'Solid density',
                  labelIntl: 'forms:labels.feedSolidDensity',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'tonnes/m3',
                  mandatoryDesign: true,
                },
                {
                  name: 'feedMoisturePercent',
                  type: 'text',
                  label: 'Moisture',
                  labelIntl: 'forms:labels.feedMoisturePercent',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().min(0).max(100).nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: '%',
                },
                {
                  name: 'feedMethod',
                  type: 'text',
                  label: 'Feed method',
                  labelIntl: 'forms:labels.feedMethod',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: SelectInput,
                  options: feedMethodOptions,
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  format: (val) =>
                    feedMethodOptions.find((item) => item.value === val),
                },
                {
                  name: 'feedCurveAttachments',
                  type: 'file',
                  width: [1, 1, 1 / 2, 1 / 4],
                  label: 'Feed curve',
                  labelIntl: 'forms:labels.feedCurve',
                  component: DropZone,
                  mandatoryDesign: true,
                  tooltip:
                    'Upload here the most recent feed gradation you have. You can upload up to 10 files of any format limited to 200Mb each.',
                  tooltipIntl: 'tooltips:feedCurveAttachments',
                },
                {
                  name: 'caseReportAttachments',
                  type: 'file',
                  width: [1, 1, 1 / 2, 1 / 4],
                  label: 'Case Reports',
                  labelIntl: 'forms:labels.caseReports',
                  component: DropZone,
                  tooltip:
                    'Upload here all the reports and other information you find relevant to inform the expert, not only about feed information. You can upload up to 10 files of any format limited to 200Mb each.',
                  tooltipIntl: 'tooltips:caseReportAttachments',
                },
                {
                  name: 'additionalFeedInformation',
                  type: 'textarea',
                  label: 'Additional Feed Information',
                  labelIntl: 'forms:labels.additionalFeedInformation',
                  width: [1, 1, 1 / 2, 1 / 4],

                  validate: validate(string().nullable()),
                  tooltip: 'Open field for additional information',
                  tooltipIntl: 'tooltips:additionalFeedInformation',
                  component: Input,
                  multiline: true,
                  rows: 5,
                },
              ],
            },
          ],
        },
        {
          flexDirection: ['column', 'row'],
          id: 'attachments',
          subsections: [
            {
              id: 'powerDrawSub',
              title: 'Power draw',
              titleIntl: 'forms:labels.powerDraw',
              flexDirection: 'column',
              width: [1, 1 / 2, 1 / 2, 1 / 4],
              fields: [
                {
                  name: 'powerDrawAvg',
                  type: 'text',
                  label: 'Average value',
                  labelIntl: 'forms:labels.averageValue',
                  width: 1,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: `kW`,
                  mandatoryDesign: true,
                },
                {
                  name: 'powerDrawPeak',
                  type: 'text',
                  label: 'Peak value',
                  labelIntl: 'forms:labels.peakValue',
                  width: 1,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: `kW`,
                  mandatoryDesign: true,
                },
                {
                  name: 'powerDrawAttachments',
                  type: 'file',
                  width: 1,
                  label: 'Power Draw',
                  labelIntl: 'forms:labels.powerDraw',
                  component: DropZone,
                  tooltip:
                    'Upload here the data downloaded from the automation panel or datalogger. You can upload up to 10 files of any format limited to 200Mb each.',
                  tooltipIntl: 'tooltips:powerDrawAttachments',
                },
              ],
            },
            {
              flexDirection: 'column',
              title: 'Pressure',
              titleIntl: 'forms:labels.pistonPressure',
              id: 'pressureSub',
              width: [1, 1 / 2, 1 / 2, 1 / 4],
              fields: [
                {
                  name: 'pistonPressureAvg',
                  type: 'text',
                  label: 'Average value',
                  labelIntl: 'forms:labels.averageValue',
                  width: [1 / 1],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'bar',
                },
                {
                  name: 'pistonPressurePeak',
                  type: 'text',
                  label: 'Peak value',
                  labelIntl: 'forms:labels.peakValue',
                  width: [1 / 1],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'bar',
                },
                {
                  name: 'pistonPressureAttachments',
                  type: 'file',
                  width: 1,
                  label: 'Piston Pressure',
                  labelIntl: 'forms:labels.pistonPressure',
                  component: DropZone,
                  tooltip:
                    'Upload here the data downloaded from the automation panel, pressure issues report and any related information. You can upload up to 10 files of any format limited to 200Mb each.',
                  tooltipIntl: 'tooltips:pistonPressureAttachments',
                },
              ],
            },
            {
              flexDirection: 'column',
              title: 'Throughput',
              titleIntl: 'forms:labels.throughput',
              id: 'throughputSub',
              width: [1, 1 / 2, 1 / 2, 1 / 4],
              fields: [
                {
                  name: 'throughputAvg',
                  type: 'text',
                  label: 'Average value',
                  labelIntl: 'forms:labels.averageValue',
                  width: [1 / 1],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'tonnes/hour',
                  mandatoryDesign: true,
                },
                {
                  name: 'throughputPeak',
                  type: 'text',
                  label: 'Peak value',
                  labelIntl: 'forms:labels.peakValue',
                  width: [1 / 1],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'tonnes/hour',
                  mandatoryDesign: true,
                },
                {
                  name: 'throughputAttachments',
                  type: 'file',
                  width: 1,
                  label: 'Throughput',
                  labelIntl: 'forms:labels.throughput',
                  component: DropZone,
                  tooltip:
                    'Upload here any data, file and report related to the throughput you have. You can upload up to 10 files of any format limited to 200Mb each.',
                  tooltipIntl: 'tooltips:throughputAttachments',
                },
              ],
            },
            {
              title: 'Flowsheet',
              titleIntl: 'forms:labels.flowsheet',
              id: 'flowsheetSub',
              flexDirection: 'column',
              width: [1, 1 / 2, 1 / 2, 1 / 4],
              subtitle: 'If you have the flowsheet, please upload it here.',
              subtitleIntl: 'forms:labels.flowsheetText',
              fields: [
                {
                  name: 'flowsheetAttachments',
                  type: 'file',
                  width: 1,
                  label: 'Flowsheet',
                  labelIntl: 'forms:labels.flowsheet',
                  component: DropZone,
                  tooltip:
                    'Upload here Bruno´s file, flow charts, pictures and any other file that helps the expert to understand the crushing process. You can upload up to 10 files of any format limited to 200Mb each.',
                  tooltipIntl: 'tooltips:flowsheetAttachments',
                },
              ],
            },
            {
              title: 'Additional Information',
              titleIntl: 'forms:labels.additionalAttachmentInformation',
              id: 'additional-informationflowsheetSub',
              flexDirection: 'column',
              width: [1, 1 / 2, 1 / 2, 1 / 2],
              fields: [
                {
                  name: 'additionalAttachmentInformation',
                  type: 'textarea',
                  label: 'Additional Attachment Information',
                  labelIntl: 'forms:labels.additionalAttachmentInformation',
                  width: [1],

                  validate: validate(string().nullable()),
                  tooltip: 'Open field for additional information',
                  tooltipIntl: 'tooltips:additionalAttachmentInformation',
                  component: Input,
                  multiline: true,
                  rows: 8,
                },
              ],
            },
          ],
        },
      ],
    },
    // Step 3
    {
      id: 3,
      title: 'Improvement targets',
      titleIntl: 'tabs.improvementTargets',
      sections: [
        {
          width: 1,
          id: 'improvementTargets',
          flexDirection: 'row',
          subsections: [
            {
              id: 'improvementTargets',
              title: 'Improvement targets',
              titleIntl: 'forms:headings.improvementTargets',
              width: [1, 1 / 2, 1 / 4],
              flexDirection: 'column',
              mandatoryDesign: true,
              fields: [
                {
                  name: 'improvementTargets.longerLifetimeOfLiners',
                  type: 'checkbox',
                  label: 'Longer lifetime of liners',
                  labelIntl:
                    'forms:labels.improvementTargets.longerLifetimeOfLiners',
                  validate: validate(boolean()),
                  component: Checkbox,
                  width: 1,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.optimizedLifetimeOfLiners',
                  type: 'checkbox',
                  label: 'Optimized lifetime of liners',
                  labelIntl:
                    'forms:labels.improvementTargets.optimizedLifetimeOfLiners',
                  validate: validate(boolean()),
                  component: Checkbox,
                  width: 1,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.higherCrusherThroughput',
                  type: 'checkbox',
                  label: 'Higher crusher throughput',
                  labelIntl:
                    'forms:labels.improvementTargets.higherCrusherThroughput',
                  validate: validate(boolean()),
                  component: Checkbox,
                  width: 1,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.higherOverallProduction',
                  type: 'checkbox',
                  label: 'Higher overall production',
                  labelIntl:
                    'forms:labels.improvementTargets.higherOverallProduction',
                  validate: validate(boolean()),
                  component: Checkbox,
                  width: 1,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.finerProduct',
                  type: 'checkbox',
                  label: 'Finer product',
                  labelIntl: 'forms:labels.improvementTargets.finerProduct',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.smallerP80',
                  type: 'checkbox',
                  label: 'Smaller P80',
                  labelIntl: 'forms:labels.improvementTargets.smallerP80',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.lessFinesInProduct',
                  type: 'checkbox',
                  label: 'Less fines in product',
                  labelIntl:
                    'forms:labels.improvementTargets.lessFinesInProduct',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.moreLumpsInProduct',
                  type: 'checkbox',
                  label: 'More lumps in product',
                  labelIntl:
                    'forms:labels.improvementTargets.moreLumpsInProduct',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.fasterRelines',
                  type: 'checkbox',
                  label: 'Faster relines',
                  labelIntl: 'forms:labels.improvementTargets.fasterRelines',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.saferRelines',
                  type: 'checkbox',
                  label: 'Safer relines',
                  labelIntl: 'forms:labels.improvementTargets.saferRelines',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.lowerCostPerTonne',
                  type: 'checkbox',
                  label: 'Lower cost per tonne',
                  labelIntl:
                    'forms:labels.improvementTargets.lowerCostPerTonne',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
              ],
            },
            {
              title: 'Current problems',
              titleIntl: 'forms:headings.currentProblems',
              id: 'currentProblemsSub',
              flexDirection: 'column',
              width: [1, 1 / 2, 1 / 4],
              fields: [
                {
                  name: 'improvementTargets.poorWearShape',
                  type: 'checkbox',
                  label: 'Poor wear shape',
                  labelIntl: 'forms:labels.improvementTargets.poorWearShape',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.ringBounce',
                  type: 'checkbox',
                  label: 'Ring bounce',
                  labelIntl: 'forms:labels.improvementTargets.ringBounce',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.powerSpikes',
                  type: 'checkbox',
                  label: 'Power spikes',
                  labelIntl: 'forms:labels.improvementTargets.powerSpikes',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.pressureIssues',
                  type: 'checkbox',
                  label: 'Pressure issues',
                  labelIntl: 'forms:labels.improvementTargets.pressureIssues',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.linersAreCracking',
                  type: 'checkbox',
                  label: 'Liners are cracking',
                  labelIntl:
                    'forms:labels.improvementTargets.linersAreCracking',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
              ],
            },
          ],
        },
        {
          flexDirection: 'column',
          width: 1,
          id: 'duties',
          subsections: [
            {
              id: 'crusherDuty',
              title: 'Crusher Duty',
              titleIntl: 'forms:headings.crusherDuty',
              flexDirection: 'column',
              width: 1,
              fields: [
                {
                  name: 'improvementTargetsCrusherDuty',
                  type: 'textarea',
                  label: `Please describe the crusher’s role in the process.
                     What kind of product should the crusher be producing?
                     What is it producing now?
                     What is the required product for the next process stage?
                     What is the running method of the crusher, is it against full power or against CSS?`,
                  labelIntl: 'forms:labels.improvementTargetsCrusherDuty',
                  validate: validate(string().nullable()),
                  component: Textarea,
                  width: 1,
                },
              ],
            },
            {
              id: 'otherInformationSubsection',
              flexDirection: 'column',
              component: ImprovementTargetsOtherInfo,
              width: 1,
              fields: [
                {
                  name: 'improvementTargetsOtherInfo.expandOperations',
                  type: 'checkbox',
                  label:
                    'Are they looking to expand operations in the next years?',
                  labelIntl:
                    'forms:labels.improvementTargetsOtherInfo.expandOpearations',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                  invertedCheckbox: true,
                },
                {
                  name: 'improvementTargetsOtherInfo.expandOperationsInfo',
                  type: 'textarea',
                  label: `Please specify`,
                  labelIntl: 'forms:labels.improvementTargetsOtherInfo.specify',
                  validate: validate(string().nullable()),
                  component: Textarea,
                  width: 1,
                  condition: 'improvementTargetsOtherInfo.expandOperations',
                },
                {
                  name: 'improvementTargetsOtherInfo.increaseProduction',
                  type: 'checkbox',
                  label: 'Do they want to increase overall production?',
                  labelIntl:
                    'forms:labels.improvementTargetsOtherInfo.increaseProduction',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                  invertedCheckbox: true,
                },
                {
                  name: 'improvementTargetsOtherInfo.increaseProductionInfo',
                  type: 'textarea',
                  label: `Please specify`,
                  labelIntl: 'forms:labels.improvementTargetsOtherInfo.specify',
                  validate: validate(string().nullable()),
                  component: Textarea,
                  width: 1,
                  condition: 'improvementTargetsOtherInfo.increaseProduction',
                },
                {
                  name: 'improvementTargetsOtherInfo.reduceOperatingCosts',
                  type: 'checkbox',
                  label: 'Is there pressure to reduce operating costs?',
                  labelIntl:
                    'forms:labels.improvementTargetsOtherInfo.reduceOperatingCosts',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                  invertedCheckbox: true,
                },
                {
                  name: 'improvementTargetsOtherInfo.reduceOperatingCostsInfo',
                  type: 'textarea',
                  label: `Please specify`,
                  labelIntl: 'forms:labels.improvementTargetsOtherInfo.specify',
                  validate: validate(string().nullable()),
                  component: Textarea,
                  width: 1,
                  condition: 'improvementTargetsOtherInfo.reduceOperatingCosts',
                },
                {
                  name: 'improvementTargetsOtherInfo.increaseEfficiency',
                  type: 'checkbox',
                  label:
                    'Are they seeking to increase the efficiency of a certain process stage?',
                  labelIntl:
                    'forms:labels.improvementTargetsOtherInfo.increaseEfficiency',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                  invertedCheckbox: true,
                },
                {
                  name: 'improvementTargetsOtherInfo.increaseEfficiencyInfo',
                  type: 'textarea',
                  label: `Please specify`,
                  labelIntl: 'forms:labels.improvementTargetsOtherInfo.specify',
                  validate: validate(string().nullable()),
                  component: Textarea,
                  width: 1,
                  condition: 'improvementTargetsOtherInfo.increaseEfficiency',
                },
              ],
            },
            {
              id: 'additionalInformationSubsection',
              fields: [
                {
                  name: 'improvementTargetsAdditionalInfo',
                  type: 'textarea',
                  label: `Please describe the customer’s targets in more detail.`,
                  labelIntl: 'forms:labels.improvementTargetsAdditionalInfo',
                  validate: validate(string().nullable()),
                  component: Textarea,
                  width: 1,
                },
              ],
            },
          ],
        },
      ],
    },
    // step 4
    {
      id: 4,
      title: 'Mantle details',
      titleIntl: 'tabs.mantleDetails',
      mantle: true,
      sections: [
        {
          id: 'pgMantleConfiguration',
          title: 'Mantle configuration',
          titleIntl: 'forms:headings.mantleConfiguration',
          component: PgMantleConfiguration,
          subsections: [
            {
              id: 'numberOfMantlePieces',
              title: 'Choose num of pieces',
              fields: [
                {
                  name: 'partSets[0].numberOfMantlePieces',
                  type: 'text',
                  label: 'Choose number of pieces',
                  labelIntl: 'forms:labels.numberOfMantlePieces',
                  component: NumberOfPieces,
                  maxNumber: 3,
                  alignment: 'left',
                  validate: validate(string().nullable()),
                  width: 'auto',
                  marginBottom: 0,
                  mandatoryDesign: true,
                  tooltip: 'How many pieces does the mantle consist of?',
                  tooltipIntl: 'tooltips:numberOfMantlePieces',
                },
              ],
            },
            {
              id: 'pgMantlesPerSetForm',
              component: PgMantlesPerSetForm,
              fields: [
                {
                  id: 'upperMantlesPerSet',
                  name: 'partSets[0].upperMantlesPerSet',
                  label: 'Upper mantles per concave set',
                  labelIntl: 'forms:labels.upperMantlesPerSet',
                  component: SelectInput,
                  options: mantlePieceOptions,
                  parse: (obj) => (obj.value === 0 ? null : obj.value),
                  format: (val) =>
                    mantlePieceOptions.find((item) => item.value === val),
                  validate: validate(string().nullable()),
                  width: 1,
                  mandatoryDesign: true,
                },
                {
                  id: 'middleMantlesPerSet',
                  name: 'partSets[0].middleMantlesPerSet',
                  label: 'Middle mantles per concave set',
                  labelIntl: 'forms:labels.middleMantlesPerSet',
                  labelSingle: 'Mantles per concave set',
                  labelIntlSingle: 'forms:labels.mantlesPerSet',
                  component: SelectInput,
                  options: mantlePieceOptions,
                  parse: (obj) => (obj.value === 0 ? null : obj.value),
                  format: (val) =>
                    mantlePieceOptions.find((item) => item.value === val),
                  validate: validate(string().nullable()),
                  width: 1,
                  mandatoryDesign: true,
                },
                {
                  id: 'lowerMantlesPerSet',
                  name: 'partSets[0].lowerMantlesPerSet',
                  label: 'Lower mantles per concave set',
                  labelIntl: 'forms:labels.lowerMantlesPerSet',
                  component: SelectInput,
                  options: mantlePieceOptions,
                  parse: (obj) => (obj.value === 0 ? null : obj.value),
                  format: (val) =>
                    mantlePieceOptions.find((item) => item.value === val),
                  validate: validate(string().nullable()),
                  width: 1,
                  mandatoryDesign: true,
                },
              ],
            },
          ],
        },
        {
          id: 'pgMantleArrayFields',
          noPadding: true,
          noMargin: true,
          backgroundColor: 'lightGray',
          subsections: [
            {
              id: 'mantlePiecesSub',
              label: 'Mantle',
              labelIntl: 'forms:labels.mantle',
              component: MantleArrayInputDataCollect,
              fields: [
                {
                  name: 'partSets[0].mantlePieces',
                  arrayFields: [
                    {
                      name: 'partNumber',
                      type: 'text',
                      label: 'Part number',
                      labelIntl: 'forms:labels.partNumber',
                      width: [1, 1, 1 / 2, 1 / 3],
                      component: Input,
                      validate: validate(string().nullable()),
                      mandatoryDesign: true,
                    },
                    {
                      name: 'description',
                      type: 'text',
                      label: 'Type/Profile',
                      labelIntl: 'forms:labels.typeProfile',
                      width: [1, 1, 1 / 2, 1 / 3],
                      validate: validate(string().nullable()),
                      component: Input,
                      mandatoryDesign: true,
                    },
                    {
                      name: 'material',
                      type: 'text',
                      label: 'Material',
                      labelIntl: 'forms:labels.material',
                      width: [1, 1, 1 / 2, 1 / 3],
                      component: Input,
                      validate: validate(string().nullable()),
                      mandatoryDesign: true,
                    },
                    {
                      name: 'weight',
                      type: 'text',
                      label: 'Weight',
                      labelIntl: 'forms:labels.weight',
                      width: [1, 1, 1 / 2, 1 / 3],
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      component: NumberInput,
                      endAdornment: 'kg',
                      mandatoryDesign: true,
                    },
                    {
                      name: 'pricePerKg',
                      type: 'text',
                      label: 'Price per kg',
                      labelIntl: 'forms:labels.pricePerKg',
                      width: [1, 1, 1 / 2, 1 / 3],
                      component: NumberInput,
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      format: roundCurrency,
                      endAdornment: '€/kg',
                    },
                    {
                      name: 'price',
                      type: 'text',
                      label: 'Price',
                      labelIntl: 'forms:labels.price',
                      width: [1, 1, 1 / 2, 1 / 3],
                      component: NumberInput,
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      format: roundCurrency,
                      endAdornment: '€',
                      disabled: true,
                    },
                    {
                      name: 'type',
                      type: 'text',
                      hidden: true,
                      label: 'Type',
                      component: Input,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Change out cost',
          titleIntl: 'forms:headings.changeOut',
          id: 'changeoutcosts',
          width: 1,
          subsections: [
            {
              id: 'changeOutCost',
              fields: [
                {
                  name: 'partSets[0].id',
                  hidden: true,
                  component: Input,
                  label: 'PartSet DB Id',
                },
                {
                  name: 'partSets[0].changeOutCostMantleToShaft',
                  label: 'Mantle to shaft',
                  labelIntl: 'forms:labels.changeOutCostMantleToShaft',
                  type: 'text',
                  subscribeToValues: true,
                  width: [1, 1, 1 / 2, 1 / 3],
                  endAdornment: '€',
                  parse: parseNumberInput,
                  format: roundCurrency,
                  component: NumberInput,
                  tooltip:
                    'The cost of removing a worn mantle from the shaft and installing a new mantle onto the shaft, including backing cost.',
                  tooltipIntl: 'tooltips:changeOutCostMantleToShaft',
                },
                {
                  name: 'partSets[0].changeOutCostShaftChange',
                  label: 'Shaft change',
                  labelIntl: 'forms:labels.changeOutCostShaftChange',
                  type: 'text',
                  subscribeToValues: true,
                  width: [1, 1, 1 / 2, 1 / 3],
                  endAdornment: '€',
                  parse: parseNumberInput,
                  format: roundCurrency,
                  component: NumberInput,
                  tooltip:
                    'The cost of removing the shaft with a worn mantle from the crusher and installing another shaft with a new mantle to crusher. For example,  changing a shaft with a worn STD mantle to a shaft with a new OS mantle. NOTE! Concave segment replacement NOT included.',
                  tooltipIntl: 'tooltips:changeOutCostShaftChange',
                },
                {
                  name: 'partSets[0].changeOutShaftChangeDowntime',
                  label: 'Shaft change downtime',
                  labelIntl: 'forms:labels.changeOutShaftChangeDowntime',
                  type: 'text',
                  width: [1, 1, 1 / 2, 1 / 3],
                  endAdornment: 'hours',
                  parse: parseNumberInput,
                  component: NumberInput,
                  tooltip:
                    'The downtime required to remove the shaft with a worn mantle from the crusher and install another shaft with a new mantle to crusher.',
                  tooltipIntl: 'tooltips:changeOutShaftChangeDowntime',
                },
              ],
            },
          ],
        },
      ],
    },
    // step 5
    {
      id: 5,
      title: 'Concave details',
      titleIntl: 'tabs.concaveDetails',
      sections: [
        {
          noMargin: true,
          id: 'concaveConfig',
          subsections: [
            {
              id: 'concaveConfigSub',
              width: 1,
              fields: [
                {
                  name: 'partSets[0].id',
                  label: 'Part Set id',
                  hidden: true,
                  component: Input,
                  validate: validate(number().nullable()),
                },
                {
                  name: 'partSets[0].numberOfConcaveTiers',
                  label: 'Choose number of tiers',
                  labelIntl: 'forms:labels.numberOfConcaveTiers',
                  type: 'tiersSelect',
                  minNumber: 1,
                  maxNumber: 6,
                  alignment: ['left', 'center'],
                  component: NumberOfPieces,
                  mandatoryDesign: true,
                  validate: validate(number().min(1).max(6)),
                  tooltip:
                    'The numbering runs bottom up; Tier 1 is the lowest, Tier 2 the second lowest…',
                  tooltipIntl: 'tooltips:numberOfConcaveTiers',
                },
              ],
            },
            {
              width: 1,
              name: 'numberOfTiers',
              id: 'partSetsConcavePiecesSub',
              label: 'Tier',
              type: 'array',
              subscribeToValues: true,
              component: PgTiersArrayInput,
              fields: [
                {
                  id: 'concavePiecesArray',
                  name: 'partSets[0].concavePieces',
                  subscribeToValues: true,
                  label: 'Tier',
                  labelIntl: 'forms:labels.tier',
                  arrayFields: [
                    {
                      name: 'id',
                      type: 'text',
                      label: 'Id',
                      hidden: true,
                      component: Input,
                      validate: validate(string().nullable()),
                    },
                    {
                      name: 'partNumber',
                      type: 'text',
                      label: 'Part number',
                      labelIntl: 'forms:labels.partNumber',
                      width: [1, 1, 1 / 2, 1 / 4],
                      component: Input,
                      validate: validate(string().nullable()),
                      mandatoryDesign: true,
                    },
                    {
                      name: 'material',
                      type: 'text',
                      label: 'Material',
                      labelIntl: 'forms:labels.material',
                      width: [1, 1, 1 / 2, 1 / 4],
                      component: Input,
                      validate: validate(string().nullable()),
                      mandatoryDesign: true,
                    },
                    {
                      name: 'configuration',
                      type: 'text',
                      label: 'Configuration',
                      labelIntl: 'forms:labels.configuration',
                      width: [1, 1, 1 / 2, 1 / 4],
                      component: SelectInput,
                      placeholder: 'Select one',
                      autoComplete: 'new-password',
                      options: configrationOptions,
                      format: (val) =>
                        configrationOptions.find((item) => item.value === val),
                      parse: (obj) => (obj.value === '' ? null : obj.value),
                      validate: validate(string().nullable()),
                    },
                    {
                      name: 'fasteningMethod',
                      type: 'text',
                      label: 'Fastening Method',
                      labelIntl: 'forms:labels.fasteningMethod',
                      width: [1, 1, 1 / 2, 1 / 4],
                      component: SelectInput,
                      placeholder: 'Select one',
                      autoComplete: 'new-password',
                      options: fasteningMethodOptions,
                      format: (val) =>
                        fasteningMethodOptions.find(
                          (item) => item.value === val,
                        ),
                      parse: (obj) => (obj.value === '' ? null : obj.value),

                      validate: validate(string().nullable()),
                    },
                    {
                      name: 'numberOfSegments',
                      type: 'text',
                      label: 'Number of segments',
                      labelIntl: 'forms:labels.numberOfSegments',
                      width: [1, 1, 1 / 2, 1 / 4],
                      component: NumberInput,
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      mandatoryDesign: true,
                    },
                    {
                      name: 'segmentWeight',
                      type: 'text',
                      label: 'Segment weight',
                      labelIntl: 'forms:labels.segmentWeight',
                      width: [1, 1, 1 / 2, 1 / 4],
                      component: NumberInput,
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      endAdornment: 'kg',
                      mandatoryDesign: true,
                    },
                    {
                      name: 'pricePerKg',
                      type: 'text',
                      label: 'Price per kg',
                      labelIntl: 'forms:labels.pricePerKg',
                      width: [1, 1, 1 / 2, 1 / 4],
                      component: NumberInput,
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      format: roundCurrency,
                      endAdornment: '€/kg',
                    },
                    {
                      name: 'price',
                      type: 'text',
                      label: 'Price of tier',
                      labelIntl: 'forms:labels.price',
                      width: [1, 1, 1 / 2, 1 / 4],
                      component: NumberInput,
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      format: roundCurrency,
                      endAdornment: '€',
                      disabled: true,
                      tooltip: 'Calculated total price of the tier',
                      tooltipIntl: 'tooltips:tierPrice',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: '',
          backgroundColor: colors.bgExtraLightGray,
          noMargin: false,
          id: 'partSets',
          width: 1,
          flexDirection: 'row',
          subsections: [
            {
              width: [1, 1, 1 / 2, 1 / 3],
              id: 'partsetSub',
              fields: [
                {
                  name: 'partSets[0].totalCostOfConcaveSegmentSet',
                  type: 'text',
                  label: 'Total cost of a concave segment set',
                  labelIntl: 'forms:labels.totalCostOfConcaveSegmentSet',
                  width: 1,
                  subscribeToValues: true,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  format: roundCurrency,
                  component: NumberInput,
                  endAdornment: '€',
                  tooltip:
                    'What is the total price of concave segments required to cover the entire frame of the crusher, excluding backing, pins, etc?',
                  tooltipIntl: 'tooltips:totalCostOfConcaveSegmentSet',
                  disabled: true,
                },
              ],
            },
            {
              width: [1, 1, 1 / 2, 1 / 3],
              id: 'partsetSub2',
              fields: [
                {
                  name: 'partSets[0].additionalInfoForTiers',
                  type: 'textarea',
                  label: 'Additional Info For Tiers',
                  labelIntl: 'forms:labels.additionalInfoForTiers',
                  width: 1,
                  validate: validate(string().nullable()),
                  component: Input,
                  multiline: true,
                  rows: 5,
                  tooltip:
                    'If you have additional info about the tiers write it here',
                  tooltipIntl: 'tooltips:additionalInfoForTiers',
                  disabled: false,
                },
              ],
            },
          ],
        },
        {
          title: 'Change-out costs for concave segments',
          titleIntl: 'forms:headings:changeOutCostConcave',
          id: 'changeOutCostsForConcaveSegments',
          subsections: [
            {
              id: 'changeOutCostsForConcaveSegmentsSub',
              width: 1,
              fields: [
                {
                  name: 'partSets[0].changeOutCostOfSegment',
                  type: 'text',
                  label: 'Change-out cost, direct',
                  labelIntl: 'forms:labels.changeOutCostOfSegment',
                  width: [1, 1, 1 / 2, 1 / 3],
                  subscribeToValues: true,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  format: roundCurrency,
                  component: NumberInput,
                  endAdornment: '€',
                  tooltip:
                    'The total cost to remove the worn concaves and to install the new concaves, including materials like backing, pins, etc.',
                  tooltipIntl: 'tooltips:changeOutCostOfSegment',
                },
                {
                  name: 'partSets[0].otherCostsOfSegmentChange',
                  type: 'text',
                  label: 'Other related costs',
                  labelIntl: 'forms:labels:otherCostsOfSegmentChange',
                  width: [1, 1, 1 / 2, 1 / 3],
                  subscribeToValues: true,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  format: roundCurrency,
                  component: NumberInput,
                  endAdornment: '€',
                  tooltip:
                    'Other costs related to a segment change-out can include costs like hiring a contractor to crush during the shutdown, hiring a crane, or frequent capital spares costs caused by power spike or load issues.',
                  tooltipIntl: 'tooltips:otherCostsOfSegmentChange',
                },
                {
                  name: 'partSets[0].segmentChangeOutDowntime',
                  type: 'text',
                  label: 'Downtime',
                  labelIntl: 'forms:labels:segmentChangeOutDowntime',
                  width: [1, 1, 1 / 2, 1 / 3],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'hours',
                  tooltip:
                    'How many hours does it take to replace all concave segments? In other words, for how many hours is the crusher out of operation?',
                  tooltipIntl: 'tooltips:segmentChangeOutDowntime',
                },
                {
                  name: 'partSets[0].carouselInUse',
                  type: 'input',
                  component: SelectInput,
                  label: 'Carousel in Use',
                  labelIntl: 'forms:labels:carouselInUse',
                  width: [1, 1, 1 / 2, 1 / 3],
                  validate: validate(boolean().nullable()),
                  options: yesNoOptions,
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  format: (val) =>
                    yesNoOptions.find((item) => item.value === val),
                  mandatoryDesign: true,
                  tooltip:
                    'A carousel is a lifting device used to speed up the installation process for concave segments',
                  tooltipIntl: 'tooltips:carouselInUse',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  cc: [
    // Step 1
    {
      id: 1,
      title: 'Customers and crusher details',
      titleIntl: 'tabs.customerCrusherDetails',
      // paper
      sections: [
        {
          id: 'basicInformation',
          title: 'Basic information',
          titleIntl: 'forms:headings.basicInfo',
          // grid inside paper
          subsections: [
            {
              title: '',
              subtitle: '',
              width: 1,
              id: 'basicInformationSub',
              // actual fields
              fields: [
                {
                  name: 'name',
                  type: 'text',
                  label: 'Case Name',
                  labelIntl: 'forms:labels.name',
                  validate: validate(string().required()),
                  component: Input,
                  autoComplete: 'new-password',
                  width: [1, 1, 1 / 2, 1 / 4],
                  mandatoryDesign: true,
                },
                {
                  name: 'customer.id',
                  type: 'text',
                  label: 'Customer ID',
                  hidden: true,
                  validate: validate(string().nullable()),
                  component: Input,
                },
                {
                  name: 'customer.name',
                  type: 'text',
                  label: 'Customer Name',
                  labelIntl: 'forms:labels.customerName',
                  validate: validate(string().nullable()),
                  component: Input,
                  width: [1, 1, 1 / 2, 1 / 4],
                  mandatoryDesign: true,
                },
                {
                  name: 'customer.metsoId',
                  type: 'text',
                  label: 'Metso ID',
                  labelIntl: 'forms:labels.metsoId',
                  validate: validate(string().nullable()),
                  component: Input,
                  width: [1, 1, 1 / 2, 1 / 4],
                },
                {
                  name: 'customer.siteName',
                  type: 'text',
                  label: 'Site Name',
                  labelIntl: 'forms:labels.siteName',
                  validate: validate(string().nullable()),
                  component: Input,
                  width: [1, 1, 1 / 2, 1 / 4],
                },
                {
                  name: 'customer.country',
                  label: 'Country',
                  labelIntl: 'forms:labels.country',
                  width: [1, 1, 1 / 2, 1 / 4],
                  options: countryOptions,
                  validate: validate(string().nullable()),
                  component: SelectInput,
                  placeholder: 'Select one',
                  autoComplete: 'off',
                  format: (val) =>
                    countryOptions.find((item) => item.value === val),
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  mandatoryDesign: true,
                },
                {
                  name: 'currency',
                  label: 'Currency',
                  labelIntl: 'forms:labels.currency',
                  width: [1, 1, 1 / 2, 1 / 4],
                  options: currencyOptions,
                  validate: validate(string().nullable()),
                  component: SelectInput,
                  placeholder: 'Select one',
                  autoComplete: 'new-password',
                  format: (val) =>
                    currencyOptions.find((item) => item.value === val),
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  mandatoryDesign: true,
                },
                {
                  name: 'annualCrushingLineCapacity',
                  type: 'text',
                  label: 'Annual Crushing Line Capacity',
                  labelIntl: 'forms:labels.annualCrushingLineCapacity',
                  width: [1, 1, 1 / 2, 1 / 4],
                  autoComplete: 'new-password',
                  endAdornment: 'metric/tonnes',
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  tooltip:
                    'What is the current annual crushing capacity in the circuit where the crusher is operating? In other words, how many tonnes the crushing line is currently able to produce in a year?',
                  tooltipIntl: 'tooltips:annualCrushingLineCapacity',
                  mandatoryDesign: true,
                },
                {
                  name: 'dueDate',
                  type: 'date',
                  label: 'Due date',
                  labelIntl: 'forms:labels.dueDate',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: DateInput,
                  parse: (val) =>
                    isValid(val) ? format(new Date(val), 'yyyy-MM-dd') : val,
                  format: (val) =>
                    val ? new Date(`${val} GMT${getTimezoneOffset()}`) : null,
                  tooltip:
                    'Please, define the due date during the kick-off meeting with the assigned expert.',
                  tooltipIntl: 'tooltips:dueDate',
                  mandatoryDesign: true,
                },
                {
                  name: 'operatingProfit',
                  type: 'text',
                  label: 'Operating Profit',
                  labelIntl: 'forms:labels.operatingProfit',
                  endAdornment: '%',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().min(0).max(100).nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                  tooltip:
                    "Often available on the customer's website, especially if it's a stock exchange listed company. Also, you might get this information from the customer people at the site. Alternatively, you can select to use the minimum value of 15%.",
                  tooltipIntl: 'tooltips:operatingProfit',
                },
                {
                  name: 'salesforceOpportunityNumber',
                  type: 'text',
                  label: 'Salesforce Opportunity number',
                  labelIntl: 'forms:labels.salesforceOpportunityNumber',
                  width: [1, 1, 1 / 2, 1 / 4],
                  autoComplete: 'new-password',
                  component: Input,
                  enabledAfterAnalysis: true,
                },
                {
                  name: 'additionalBasicInformation',
                  type: 'textarea',
                  label: 'Additional Basic Information',
                  labelIntl: 'forms:labels.additionalBasicInformation',
                  width: [1, 1, 1 / 2, 1 / 4],

                  validate: validate(string().nullable()),
                  tooltip: 'Open field for additional information',
                  tooltipIntl: 'tooltips:additionalBasicInformation',
                  component: Input,
                  multiline: true,
                  rows: 5,
                },
              ],
            },
          ],
        },
        {
          id: 'crusherInformation',
          title: 'Crusher information',
          titleIntl: 'forms:headings.crusherInfo',
          subsections: [
            {
              title: '',
              subtitle: '',
              width: 1,
              id: 'crusherInfoSub',
              fields: [
                {
                  name: 'crusherModel',
                  type: 'text',
                  label: 'Crusher Model',
                  labelIntl: 'forms:labels.crusherModel',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: Input,
                  mandatoryDesign: true,
                },
                {
                  name: 'numberOfCrushers',
                  type: 'text',
                  label: 'Number of Crushers',
                  labelIntl: 'forms:labels.numberOfCrushers',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: (val) => (Number(val) ? Number(val) : val),
                  component: Input,
                  mandatoryDesign: true,
                  tooltip:
                    'Be aware that results are multiplied by the number of crushers set here.',
                  tooltipIntl: 'tooltips:numberOfCrushers',
                },
                {
                  name: 'crusherSerialNumber',
                  type: 'text',
                  label: 'Serial number',
                  labelIntl: 'forms:labels.crusherSerialNumber',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: Input,
                  tooltip:
                    'If there is more than one crusher in scope, separate the serial numbers using "/".',
                  tooltipIntl: 'tooltips:crusherSerialNumber',
                },
                {
                  name: 'crusherCss',
                  type: 'text',
                  label: 'Closed side setting',
                  labelIntl: 'forms:labels.crusherCss',
                  width: [1, 1, 1 / 2, 1 / 4],
                  endAdornment: 'mm',
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                },
                {
                  name: 'crusherOss',
                  type: 'text',
                  label: 'Open side setting',
                  labelIntl: 'forms:labels.crusherOss',
                  width: [1, 1, 1 / 2, 1 / 4],
                  endAdornment: 'mm',
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                },
                {
                  name: 'crusherSpeed',
                  type: 'text',
                  label: 'Speed (main shaft)',
                  labelIntl: 'forms:labels.crusherSpeed',
                  endAdornment: 'rpm',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                  tooltip: 'It may be found in the machine´s operation manual.',
                  tooltipIntl: 'tooltips:crusherSpeed',
                },
                {
                  name: 'crusherCounterShaftSpeed',
                  type: 'text',
                  label: 'Speed (counter shaft)',
                  labelIntl: 'forms:labels.crusherCounterShaftSpeed',
                  endAdornment: 'rpm',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: false,
                },
                {
                  name: 'crusherStroke',
                  type: 'text',
                  label: 'Stroke',
                  labelIntl: 'forms:labels.crusherStroke',
                  endAdornment: 'mm',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                },
                {
                  name: 'crusherRatedPower',
                  type: 'text',
                  label: 'Rated Power',
                  labelIntl: 'forms:labels.crusherRatedPower',
                  endAdornment: 'KW',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                  tooltip: 'The power of the motor installed in the crusher.',
                  tooltipIntl: 'tooltips:crusherRatedPower',
                },
                {
                  name: 'operatingHoursPerDay',
                  type: 'text',
                  label: 'Operating Hours',
                  labelIntl: 'forms:labels.operatingHoursPerDay',
                  endAdornment: 'h/day',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().min(0).max(24).nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                  tooltip:
                    'How many hours per day the crusher is effectively producing? You can inform the average.',
                  tooltipIntl: 'tooltips:operatingHoursPerDay',
                },
                {
                  name: 'operatingDaysPerYear',
                  type: 'text',
                  label: 'Operating Days',
                  labelIntl: 'forms:labels.operatingDaysPerYear',
                  endAdornment: 'days/year',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().min(1).max(366).nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  mandatoryDesign: true,
                  tooltip:
                    'How many days per year the crusher is effectively in operation? You can inform the average.',
                  tooltipIntl: 'tooltips:operatingDaysPerYear',
                },
                {
                  name: 'additionalCrusherInformation',
                  type: 'textarea',
                  label: 'Additional Crusher Information',
                  labelIntl: 'forms:labels.additionalCrusherInformation',
                  width: [1, 1, 1 / 2, 1 / 4],

                  validate: validate(string().nullable()),
                  tooltip: 'Open field for additional information',
                  tooltipIntl: 'tooltips:additionalCrusherInformation',
                  component: Input,
                  multiline: true,
                  rows: 5,
                },
              ],
            },
          ],
        },
        {
          title: 'Current chamber information',
          titleIntl: 'forms:headings.chamberInfo',
          id: 'currentCavityInformation',
          subsections: [
            {
              title: '',
              subtitle: '',
              width: 1,
              id: 'currentCavityInformationSub',
              fields: [
                {
                  name: 'crusherCavityType',
                  type: 'text',
                  label: 'Cavity details',
                  labelIntl: 'forms:labels.crusherCavityType',
                  width: [1, 1, 1 / 2, 1 / 3],
                  validate: validate(string().nullable()),
                  component: Input,
                  tooltip:
                    'Provide as many details as possible, such as alloy used, throw and setting combination, whether this is an already optimized cavity, etc.',
                  tooltipIntl: 'tooltips:crusherCavityType',
                },
                {
                  name: 'partSets[0].supplierName',
                  type: 'text',
                  label: 'Wear Part Supplier',
                  labelIntl: 'forms:labels.supplierName',
                  width: [1, 1, 1 / 2, 1 / 3],
                  validate: validate(string().nullable()),
                  component: Input,
                },
                {
                  name: 'partSets[0].id',
                  type: 'text',
                  hidden: true,
                  component: Input,
                },
                {
                  name: 'partSets[0].totalCrushingCapacity',
                  type: 'text',
                  label: 'Single Crusher Capacity',
                  labelIntl: 'forms:labels.totalCrushingCapacity',
                  width: [1, 1, 1 / 2, 1 / 3],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'tonnes/hour',
                  tooltip:
                    'What is the current capacity of the crusher to be optimized? If there are more similar crushers in scope, provide the capacity of a single one.',
                  tooltipIntl: 'tooltips:totalCrushingCapacity',
                  mandatoryDesign: true,
                },
                {
                  name: 'additionalChamberInformation',
                  type: 'textarea',
                  label: 'Additional Chamber Information',
                  labelIntl: 'forms:labels.additionalChamberInformation',
                  width: [1, 1, 1 / 2, 1 / 3],

                  validate: validate(string().nullable()),
                  tooltip: 'Open field for additional information',
                  tooltipIntl: 'tooltips:additionalChamberInformation',
                  component: Input,
                  multiline: true,
                  rows: 5,
                },
              ],
            },
          ],
        },
        {
          id: 'miningProductDetails',
          title: 'Mining product details',
          titleIntl: 'forms:headings.productDetails',
          subsections: [
            {
              id: 'miningProductsDetailsSub',
              subtitle: 'Mining products',
              component: MiningProductsArrayInput,
              width: 1,
              fields: [
                {
                  name: 'miningProducts',
                  type: 'text',
                  label: 'Mining product',
                  labelIntl: 'forms:labels.miningProduct',
                  arrayFields: [
                    {
                      name: 'id',
                      type: 'text',
                      hidden: true,
                      validate: validate(string().nullable()),
                      component: Input,
                    },
                    {
                      name: 'name',
                      type: 'text',
                      label: 'Mining product',
                      labelIntl: 'forms:labels.miningProduct',
                      placeholder: 'Select one',
                      width: [1, 1, 1 / 2, 1 / 5],
                      validate: validate(string().nullable()),
                      component: SelectInput,
                      options: miningProducts,
                      parse: (obj) => obj.value,
                      format: (val) =>
                        miningProducts.find((item) => item.value === val),
                    },
                    {
                      name: 'nameOther',
                      type: 'text',
                      label: 'Product name',
                      labelIntl: 'forms:labels.miningProductNameOther',
                      width: [1, 1, 1 / 2, 1 / 5],
                      validate: validate(string().nullable().required()),
                      component: Input,
                      endAdornment: '',
                    },
                    {
                      name: 'recoveryRatePercent',
                      type: 'text',
                      label: 'Recovery',
                      labelIntl: 'forms:labels.recovery',
                      width: [1, 1, 1 / 2, 1 / 5],
                      validate: validate(number().min(0).max(100).nullable()),
                      parse: parseNumberInput,
                      component: NumberInput,
                      endAdornment: '%',
                      tooltip:
                        'The current mineral recovery percentage, indicates what percentage of valuable product are expected to be available for sale after the refining process has taken place.',
                      tooltipIntl: 'tooltips:recovery',
                      enabledAfterAnalysis: true,
                    },
                    {
                      name: 'price',
                      type: 'text',
                      label: 'Price',
                      labelIntl: 'forms:labels.price',
                      width: [1, 1, 1 / 2, 1 / 5],
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      format: roundCurrency,
                      component: NumberInput,
                      endAdornment: '€/tonne',
                      tooltip:
                        'The current mining product price on the market.',
                      tooltipIntl: 'tooltips:miningProductPrice',
                      enabledAfterAnalysis: true,
                    },
                    {
                      name: 'grade',
                      type: 'text',
                      label: 'Grade',
                      labelIntl: 'forms:labels.grade',
                      width: [1, 1, 1 / 2, 1 / 5],
                      validate: validate(number().min(0).max(100).nullable()),
                      parse: parseNumberInput,
                      component: NumberInput,
                      endAdornment: '%',
                      tooltip:
                        'The current average proportion of valuable product contained in the ore crushed at the site.',
                      tooltipIntl: 'tooltips:grade',
                      enabledAfterAnalysis: true,
                    },
                    {
                      name: 'grading',
                      type: 'text',
                      label: 'Grading',
                      labelIntl: 'forms:labels.grading',
                      width: [1, 1, 1 / 2, 1 / 5],
                      // disabled: true,
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      component: NumberInput,
                      endAdornment: 'gm/tonne',
                      tooltip:
                        'The amount of valuable product calculated per tonne of ore processed.',
                      tooltipIntl: 'tooltips:grading',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    // Step 2
    {
      id: 2,
      title: 'Application data',
      titleIntl: 'tabs.applicationData',
      sections: [
        {
          title: 'Feed information',
          titleIntl: 'forms:headings.feedInfo',
          id: 'feedInformation',
          flexDirection: 'column',
          subsections: [
            {
              width: 1,
              id: 'feedInformationSub',
              fields: [
                {
                  name: 'feedRockTestId',
                  type: 'text',
                  label: 'Rock test id',
                  labelIntl: 'forms:labels.rockTestId',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: Input,
                },
                {
                  name: 'feedAbrasiveness',
                  type: 'text',
                  label: 'Abrasiveness',
                  labelIntl: 'forms:labels.feedAbrasiveness',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'g/t',
                  mandatoryDesign: true,
                  tooltip: 'abrasiveness',
                },
                {
                  name: 'feedCrushabilityPercent',
                  type: 'text',
                  label: 'Crushability',
                  labelIntl: 'forms:labels.feedCrushabilityPercent',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().min(0).max(100).nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: '%',
                  mandatoryDesign: true,
                  tooltip: 'crushability',
                },
                {
                  name: 'feedOn',
                  type: 'text',
                  label: 'On/off feed',
                  labelIntl: 'forms:labels.feedOn',
                  placeholder: 'YES/NO',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: SelectInput,
                  options: yesNoOptions,
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  format: (val) =>
                    yesNoOptions.find((item) => item.value === val),
                  tooltip:
                    'Is the crusher trickle fed? Trickle feed = incosistent flow of material',
                  tooltipIntl: 'tooltips:feedOn',
                  mandatoryDesign: true,
                },
                {
                  name: 'feedOreType',
                  type: 'text',
                  label: 'Ore type',
                  labelIntl: 'forms:labels.feedOreType',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: Input,
                  tooltip:
                    'The name of the ore, e.g: hematite, goethite, pyrite, pyrrhotite, etc.',
                  tooltipIntl: 'tooltips:feedOreType',
                },
                {
                  name: 'feedMaxSize',
                  type: 'text',
                  label: 'Max feed size',
                  labelIntl: 'forms:labels.feedMaxSize',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'mm',
                  tooltip:
                    'There are 3 dimesions in a rock (length, width and height), use the 2nd largest here.',
                  tooltipIntl: 'tooltips:feedSize',
                  mandatoryDesign: true,
                },
                {
                  name: 'feedMinSize',
                  type: 'text',
                  label: 'Min feed size',
                  labelIntl: 'forms:labels.feedMinSize',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'mm',
                  tooltip:
                    'There are 3 dimesions in a rock (length, width and height), use the 2nd largest here.',
                  tooltipIntl: 'tooltips:feedSize',
                  mandatoryDesign: true,
                },
                {
                  name: 'ringbounce',
                  label: 'Ringbounce',
                  labelIntl: 'forms:labels.ringbounce',
                  width: [1, 1, 1 / 2, 1 / 4],
                  component: SelectInput,
                  options: yesNoOptions,
                  placeholder: 'YES/NO',
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  format: (val) =>
                    yesNoOptions.find((item) => item.value === val),
                },
                {
                  name: 'feedSolidDensity',
                  type: 'text',
                  label: 'Solid density',
                  labelIntl: 'forms:labels.feedSolidDensity',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'tonnes/m3',
                  mandatoryDesign: true,
                },
                {
                  name: 'feedMoisturePercent',
                  type: 'text',
                  label: 'Moisture',
                  labelIntl: 'forms:labels.feedMoisturePercent',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().min(0).max(100).nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: '%',
                },
                {
                  name: 'chokeFeed',
                  label: 'Choke feed',
                  labelIntl: 'forms:labels.chokeFeed',
                  component: SelectInput,
                  options: yesNoOptions,
                  placeholder: 'YES/NO',
                  width: [1, 1, 1 / 2, 1 / 4],
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  format: (val) =>
                    yesNoOptions.find((item) => item.value === val),
                },
                {
                  name: 'feedMethod',
                  type: 'text',
                  label: 'Feed method',
                  labelIntl: 'forms:labels.feedMethod',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(string().nullable()),
                  component: SelectInput,
                  options: feedMethodOptions,
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  format: (val) =>
                    feedMethodOptions.find((item) => item.value === val),
                },
                {
                  name: 'feedCurveAttachments',
                  type: 'file',
                  width: [1, 1, 1 / 2, 1 / 4],
                  label: 'Feed curve',
                  labelIntl: 'forms:labels.feedCurve',
                  component: DropZone,
                  mandatoryDesign: true,
                  tooltip:
                    'Upload here the most recent feed gradation you have. You can upload up to 10 files of any format limited to 200Mb each.',
                  tooltipIntl: 'tooltips:feedCurveAttachments',
                },
                {
                  name: 'caseReportAttachments',
                  type: 'file',
                  width: [1, 1, 1 / 2, 1 / 4],
                  label: 'Case Reports',
                  labelIntl: 'forms:labels.caseReports',
                  component: DropZone,
                  tooltip:
                    'Upload here all the reports and other information you find relevant to inform the expert, not only about feed information. You can upload up to 10 files of any format limited to 200Mb each.',
                  tooltipIntl: 'tooltips:caseReportAttachments',
                },
                {
                  name: 'additionalFeedInformation',
                  type: 'textarea',
                  label: 'Additional Feed Information',
                  labelIntl: 'forms:labels.additionalFeedInformation',
                  width: [1, 1, 1 / 2, 1 / 4],

                  validate: validate(string().nullable()),
                  tooltip: 'Open field for additional information',
                  tooltipIntl: 'tooltips:additionalFeedInformation',
                  component: Input,
                  multiline: true,
                  rows: 5,
                },
              ],
            },
          ],
        },
        {
          flexDirection: 'row',
          id: 'attachments',
          subsections: [
            {
              id: 'powerDrawSub',
              title: 'Power draw',
              titleIntl: 'forms:labels.powerDraw',
              flexDirection: 'column',
              width: [1, 1, 1 / 2, 1 / 4],
              fields: [
                {
                  name: 'powerDrawAvg',
                  type: 'text',
                  label: 'Average value',
                  labelIntl: 'forms:labels.averageValue',
                  width: 1,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: `kW`,
                  mandatoryDesign: true,
                },
                {
                  name: 'powerDrawPeak',
                  type: 'text',
                  label: 'Peak value',
                  labelIntl: 'forms:labels.peakValue',
                  width: 1,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: `kW`,
                  mandatoryDesign: true,
                },
                {
                  name: 'powerDrawAttachments',
                  type: 'file',
                  width: 1,
                  label: 'Power Draw',
                  labelIntl: 'forms:labels.powerDraw',
                  component: DropZone,
                  tooltip:
                    'Upload here the data downloaded from the automation panel or datalogger. You can upload up to 10 files of any format limited to 200Mb each.',
                  tooltipIntl: 'tooltips:powerDrawAttachments',
                },
              ],
            },
            {
              flexDirection: 'column',
              title: 'Pressure',
              titleIntl: 'forms:labels.pistonPressure',
              id: 'pressureSub',
              width: [1, 1, 1 / 2, 1 / 4],
              fields: [
                {
                  name: 'pistonPressureAvg',
                  type: 'text',
                  label: 'Average value',
                  labelIntl: 'forms:labels.averageValue',
                  width: 1,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'bar',
                },
                {
                  name: 'pistonPressurePeak',
                  type: 'text',
                  label: 'Peak value',
                  labelIntl: 'forms:labels.peakValue',
                  width: 1,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'bar',
                },
                {
                  name: 'pistonPressureAttachments',
                  type: 'file',
                  width: 1,
                  label: 'Piston Pressure',
                  labelIntl: 'forms:labels.pistonPressure',
                  component: DropZone,
                  tooltip:
                    'Upload here the data downloaded from the automation panel, pressure issues report and any related information. You can upload up to 10 files of any format limited to 200Mb each.',
                  tooltipIntl: 'tooltips:pistonPressureAttachments',
                },
              ],
            },
            {
              flexDirection: 'column',
              title: 'Throughput',
              titleIntl: 'forms:labels.throughput',
              id: 'throughputSub',
              width: [1, 1, 1 / 2, 1 / 4],
              fields: [
                {
                  name: 'throughputAvg',
                  type: 'text',
                  label: 'Average value',
                  labelIntl: 'forms:labels.averageValue',
                  width: 1,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'tonnes/hour',
                  mandatoryDesign: true,
                },
                {
                  name: 'throughputPeak',
                  type: 'text',
                  label: 'Peak value',
                  labelIntl: 'forms:labels.peakValue',
                  width: 1,
                  validate: validate(string().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'tonnes/hour',
                  mandatoryDesign: true,
                },
                {
                  name: 'throughputAttachments',
                  type: 'file',
                  width: 1,
                  label: 'Throughput',
                  labelIntl: 'forms:labels.throughput',
                  component: DropZone,
                  tooltip:
                    'Upload here any data, file and report related to the throughput you have. You can upload up to 10 files of any format limited to 200Mb each.',
                  tooltipIntl: 'tooltips:throughputAttachments',
                },
              ],
            },
            {
              title: 'Flowsheet',
              titleIntl: 'forms:labels.flowsheet',
              id: 'flowsheetSub',
              flexDirection: 'column',
              width: [1, 1, 1 / 2, 1 / 4],
              subtitle: 'If you have the flowsheet, please upload it here.',
              subtitleIntl: 'forms:labels.flowsheetText',
              fields: [
                {
                  name: 'flowsheetAttachments',
                  type: 'file',
                  width: 1,
                  label: 'flowsheet',
                  labelIntl: 'forms:labels.flowsheet',
                  component: DropZone,
                  tooltip:
                    'Upload here Bruno´s file, flow charts, pictures and any other file that helps the expert to understand the crushing process. You can upload up to 10 files of any format limited to 200Mb each.',
                  tooltipIntl: 'tooltips:flowsheetAttachments',
                },
              ],
            },
            {
              title: 'Additional Information',
              titleIntl: 'forms:labels.additionalAttachmentInformation',
              id: 'additional-informationflowsheetSub',
              flexDirection: 'column',
              width: [1, 1 / 2, 1 / 2, 1 / 2],
              fields: [
                {
                  name: 'additionalAttachmentInformation',
                  type: 'textarea',
                  label: 'Additional Attachment Information',
                  labelIntl: 'forms:labels.additionalAttachmentInformation',
                  width: [1],

                  validate: validate(string().nullable()),
                  tooltip: 'Open field for additional information',
                  tooltipIntl: 'tooltips:additionalAttachmentInformation',
                  component: Input,
                  multiline: true,
                  rows: 8,
                },
              ],
            },
          ],
        },
      ],
    },
    // Step 3
    {
      id: 3,
      title: 'Improvement targets',
      titleIntl: 'tabs.improvementTargets',
      sections: [
        {
          width: 1,
          id: 'improvementTargets',
          flexDirection: 'row',
          subsections: [
            {
              id: 'improvementTargets',
              title: 'Improvement targets',
              titleIntl: 'forms:headings.improvementTargets',
              width: [1, 1 / 2, 1 / 4],
              flexDirection: 'column',
              mandatoryDesign: true,
              fields: [
                {
                  name: 'improvementTargets.longerLifetimeOfLiners',
                  type: 'checkbox',
                  label: 'Longer lifetime of liners',
                  labelIntl:
                    'forms:labels.improvementTargets.longerLifetimeOfLiners',
                  validate: validate(boolean()),
                  component: Checkbox,
                  width: 1,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.optimizedLifetimeOfLiners',
                  type: 'checkbox',
                  label: 'Optimized lifetime of liners',
                  labelIntl:
                    'forms:labels.improvementTargets.optimizedLifetimeOfLiners',
                  validate: validate(boolean()),
                  component: Checkbox,
                  width: 1,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.higherCrusherThroughput',
                  type: 'checkbox',
                  label: 'Higher crusher throughput',
                  labelIntl:
                    'forms:labels.improvementTargets.higherCrusherThroughput',
                  validate: validate(boolean()),
                  component: Checkbox,
                  width: 1,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.higherOverallProduction',
                  type: 'checkbox',
                  label: 'Higher overall production',
                  labelIntl:
                    'forms:labels.improvementTargets.higherOverallProduction',
                  validate: validate(boolean()),
                  component: Checkbox,
                  width: 1,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.finerProduct',
                  type: 'checkbox',
                  label: 'Finer product',
                  labelIntl: 'forms:labels.improvementTargets.finerProduct',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.smallerP80',
                  type: 'checkbox',
                  label: 'Smaller P80',
                  labelIntl: 'forms:labels.improvementTargets.smallerP80',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.lessFinesInProduct',
                  type: 'checkbox',
                  label: 'Less fines in product',
                  labelIntl:
                    'forms:labels.improvementTargets.lessFinesInProduct',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.moreLumpsInProduct',
                  type: 'checkbox',
                  label: 'More lumps in product',
                  labelIntl:
                    'forms:labels.improvementTargets.moreLumpsInProduct',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.fasterRelines',
                  type: 'checkbox',
                  label: 'Faster relines',
                  labelIntl: 'forms:labels.improvementTargets.fasterRelines',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.saferRelines',
                  type: 'checkbox',
                  label: 'Safer relines',
                  labelIntl: 'forms:labels.improvementTargets.saferRelines',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.lowerCostPerTonne',
                  type: 'checkbox',
                  label: 'Lower cost per tonne',
                  labelIntl:
                    'forms:labels.improvementTargets.lowerCostPerTonne',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
              ],
            },
            {
              title: 'Current problems',
              titleIntl: 'forms:headings.currentProblems',
              id: 'currentProblemsSub',
              flexDirection: 'column',
              width: [1, 1 / 2, 1 / 4],
              fields: [
                {
                  name: 'improvementTargets.poorWearShape',
                  type: 'checkbox',
                  label: 'Poor wear shape',
                  labelIntl: 'forms:labels.improvementTargets.poorWearShape',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.ringBounce',
                  type: 'checkbox',
                  label: 'Ring bounce',
                  labelIntl: 'forms:labels.improvementTargets.ringBounce',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.powerSpikes',
                  type: 'checkbox',
                  label: 'Power spikes',
                  labelIntl: 'forms:labels.improvementTargets.powerSpikes',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.pressureIssues',
                  type: 'checkbox',
                  label: 'Pressure issues',
                  labelIntl: 'forms:labels.improvementTargets.pressureIssues',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
                {
                  name: 'improvementTargets.linersAreCracking',
                  type: 'checkbox',
                  label: 'Liners are cracking',
                  labelIntl:
                    'forms:labels.improvementTargets.linersAreCracking',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                },
              ],
            },
          ],
        },
        {
          flexDirection: 'column',
          width: 1,
          id: 'duties',
          subsections: [
            {
              id: 'crusherDuty',
              title: 'Crusher Duty',
              titleIntl: 'forms:headings.crusherDuty',
              flexDirection: 'column',
              width: 1,
              fields: [
                {
                  name: 'improvementTargetsCrusherDuty',
                  type: 'textarea',
                  label: `Please describe the crusher’s role in the process.
                     What kind of product should the crusher be producing?
                     What is it producing now?
                     What is the required product for the next process stage?
                     What is the running method of the crusher, is it against full power or against CSS?`,
                  labelIntl: 'forms:labels.improvementTargetsCrusherDuty',
                  validate: validate(string().nullable()),
                  component: Textarea,
                  width: 1,
                },
              ],
            },
            {
              id: 'otherInformationSubsection',
              flexDirection: 'column',
              component: ImprovementTargetsOtherInfo,
              width: 1,
              fields: [
                {
                  name: 'improvementTargetsOtherInfo.expandOperations',
                  type: 'checkbox',
                  label:
                    'Are they looking to expand operations in the next years?',
                  labelIntl:
                    'forms:labels.improvementTargetsOtherInfo.expandOperations',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                  invertedCheckbox: true,
                },
                {
                  name: 'improvementTargetsOtherInfo.expandOperationsInfo',
                  type: 'textarea',
                  label: `Please specify`,
                  labelIntl: 'forms:labels.improvementTargetsOtherInfo.specify',
                  validate: validate(string().nullable()),
                  component: Textarea,
                  width: 1,
                  condition: 'improvementTargetsOtherInfo.expandOperations',
                },
                {
                  name: 'improvementTargetsOtherInfo.increaseProduction',
                  type: 'checkbox',
                  label: 'Do they want to increase overall production?',
                  labelIntl:
                    'forms:labels.improvementTargetsOtherInfo.increaseProduction',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                  invertedCheckbox: true,
                },
                {
                  name: 'improvementTargetsOtherInfo.increaseProductionInfo',
                  type: 'textarea',
                  label: `Please specify`,
                  labelIntl: 'forms:labels.improvementTargetsOtherInfo.specify',
                  validate: validate(string().nullable()),
                  component: Textarea,
                  width: 1,
                  condition: 'improvementTargetsOtherInfo.increaseProduction',
                },
                {
                  name: 'improvementTargetsOtherInfo.reduceOperatingCosts',
                  type: 'checkbox',
                  label: 'Is there pressure to reduce operating costs?',
                  labelIntl:
                    'forms:labels.improvementTargetsOtherInfo.reduceOperatingCosts',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                  invertedCheckbox: true,
                },
                {
                  name: 'improvementTargetsOtherInfo.reduceOperatingCostsInfo',
                  type: 'textarea',
                  label: `Please specify`,
                  labelIntl: 'forms:labels.improvementTargetsOtherInfo.specify',
                  validate: validate(string().nullable()),
                  component: Textarea,
                  width: 1,
                  condition: 'improvementTargetsOtherInfo.reduceOperatingCosts',
                },
                {
                  name: 'improvementTargetsOtherInfo.increaseEfficiency',
                  type: 'checkbox',
                  label:
                    'Are they seeking to increase the efficiency of a certain process stage?',
                  labelIntl:
                    'forms:labels.improvementTargetsOtherInfo.increaseEfficiency',
                  validate: validate(boolean()),
                  component: Checkbox,
                  parse: (val) => val,
                  format: (val) => val,
                  invertedCheckbox: true,
                },
                {
                  name: 'improvementTargetsOtherInfo.increaseEfficiencyInfo',
                  type: 'textarea',
                  label: `Please specify`,
                  labelIntl: 'forms:labels.improvementTargetsOtherInfo.specify',
                  validate: validate(string().nullable()),
                  component: Textarea,
                  width: 1,
                  condition: 'improvementTargetsOtherInfo.increaseEfficiency',
                },
              ],
            },
            {
              id: 'additionalInformationSubsection',
              fields: [
                {
                  name: 'improvementTargetsAdditionalInfo',
                  type: 'textarea',
                  label: `Please describe the customer’s targets in more detail.`,
                  labelIntl: 'forms:labels.improvementTargetsAdditionalInfo',
                  validate: validate(string().nullable()),
                  component: Textarea,
                  width: 1,
                },
              ],
            },
          ],
        },
      ],
    },
    // step 4
    {
      id: 4,
      title: 'Wear part details',
      titleIntl: 'tabs.wearPartDetails',
      mantle: true,
      sections: [
        {
          id: 'wearPartInfo',
          title: 'General information',
          titleIntl: 'common:headings.generalInfo',
          subsections: [
            {
              id: 'generalInfo',
              fields: [
                {
                  name: 'partSets[0].desiredEndProduct',
                  type: 'text',
                  label: 'Desired end product',
                  labelIntl: 'forms:labels.desiredEndProduct',
                  width: [1, 1, 1 / 2, 1 / 4],
                  parse: parseNumberInput,
                  component: NumberInput,
                  validate: validate(number().min(0).max(100).nullable()),
                  endAdornment: '%',
                  mandatoryDesign: true,
                  tooltip:
                    'How much (%) of the end product currently meets the customer’s requirement?',
                  tooltipIntl: 'tooltips:desiredEndProduct',
                },
                {
                  name: 'partSets[0].wearLifeOfPartSets',
                  labelIntl: 'forms:labels.wearLifeOfPartSets',
                  type: 'text',
                  label: 'Wear life of part sets',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'hours',
                  mandatoryDesign: true,
                },
                {
                  name: 'partSets[0].p80Crusher',
                  type: 'text',
                  label: 'P80 from crusher',
                  labelIntl: 'forms:labels.p80Crusher',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'mm',
                  tooltip:
                    'The size (mm) through which 80% of the crusher end product passes. For example, a P80 of 50 mm means that 80% of the crusher’s end product is smaller than 50 mm.',
                  tooltipIntl: 'tooltips:p80Crusher',
                },
                {
                  name: 'partSets[0].p80Screen',
                  type: 'text',
                  label: 'P80 from screen',
                  labelIntl: 'forms:labels.p80Screen',
                  width: [1, 1, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'mm',
                },
              ],
            },
          ],
        },
        {
          id: 'mantleConfig',
          title: 'Mantle',
          titleIntl: 'forms:labels.mantle',
          component: ConeMantleConfiguration,
          subsections: [
            {
              id: 'mantleConfigSub',
              fields: [
                {
                  name: 'partSets[0].numberOfMantlePieces',
                  label: 'Choose number of pieces',
                  labelIntl: 'forms:labels.numberOfMantlePieces',
                  component: NumberOfPieces,
                  maxNumber: 2,
                  alignment: 'space-between',
                  tooltip: 'How many pieces does the mantle consist of?',
                  tooltipIntl: 'tooltips:numberOfMantlePieces',
                },
                {
                  id: 'mantlePiecesSub',
                  name: 'partSets[0].mantlePieces',
                  label: 'Mantle',
                  labelIntl: 'forms:labels.mantle',
                  type: 'array',
                  component: MantleArrayInputDataCollectCC,
                  subscribeToValues: true,
                  flexDirection: 'row',
                  arrayFields: [
                    {
                      name: 'partNumber',
                      type: 'text',
                      label: 'Part number',
                      labelIntl: 'forms:labels.partNumber',
                      width: [1, 1, 1 / 2, 1 / 2, 1 / 3],
                      component: Input,
                      validate: validate(string().nullable()),
                      mandatoryDesign: true,
                    },
                    {
                      name: 'description',
                      type: 'text',
                      label: 'Type/Profile',
                      labelIntl: 'forms:labels.typeProfile',
                      width: [1, 1, 1 / 2, 1 / 2, 1 / 3],
                      validate: validate(string().nullable()),
                      component: Input,
                      mandatoryDesign: true,
                    },
                    {
                      name: 'material',
                      type: 'text',
                      label: 'Material',
                      labelIntl: 'forms:labels.material',
                      width: [1, 1, 1 / 2, 1 / 2, 1 / 3],
                      component: Input,
                      validate: validate(string().nullable()),
                      mandatoryDesign: true,
                    },
                    {
                      name: 'weight',
                      type: 'text',
                      label: 'Weight',
                      labelIntl: 'forms:labels.weight',
                      width: [1, 1, 1 / 2, 1 / 2, 1 / 3],
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      component: NumberInput,
                      endAdornment: 'kg',
                      mandatoryDesign: true,
                    },
                    {
                      name: 'pricePerKg',
                      type: 'text',
                      label: 'Price per kg',
                      labelIntl: 'forms:labels.pricePerKg',
                      width: [1, 1, 1 / 2, 1 / 2, 1 / 3],
                      component: NumberInput,
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      format: roundCurrency,
                      endAdornment: '€/kg',
                    },
                    {
                      name: 'price',
                      type: 'text',
                      label: 'Price',
                      labelIntl: 'forms:labels.price',
                      width: [1, 1, 1 / 2, 1 / 2, 1 / 3],
                      validate: validate(number().nullable()),
                      parse: parseNumberInput,
                      format: roundCurrency,
                      component: NumberInput,
                      endAdornment: '€',
                      disabled: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'bowLiner',
          title: 'Bowl liner',
          titleIntl: 'forms:headings.bowlLiner',
          subsections: [
            {
              id: 'bowLinerSub',
              fields: [
                {
                  name: 'partSets[0].bowlLinerPartNumber',
                  label: 'Part number',
                  labelIntl: 'forms:labels.partNumber',
                  type: 'text',
                  component: Input,
                  width: [1, 1, 1 / 2, 1 / 3],
                  mandatoryDesign: true,
                },
                {
                  name: 'partSets[0].bowlLinerDescription',
                  type: 'text',
                  label: 'Type/profile',
                  labelIntl: 'forms:labels.typeProfile',
                  width: [1, 1, 1 / 2, 1 / 3],
                  validate: validate(string().nullable()),
                  component: Input,
                  mandatoryDesign: true,
                },
                {
                  name: 'partSets[0].bowlLinerMaterial',
                  label: 'Material',
                  labelIntl: 'forms:labels.material',
                  type: 'text',
                  component: Input,
                  width: [1, 1, 1 / 2, 1 / 3],
                  mandatoryDesign: true,
                },
                {
                  name: 'partSets[0].bowlLinerWeight',
                  type: 'text',
                  label: 'Weight',
                  labelIntl: 'forms:labels.weight',
                  width: [1, 1, 1 / 2, 1 / 3],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'kg',
                  mandatoryDesign: true,
                },
                {
                  name: 'partSets[0].bowlLinerPricePerKg',
                  type: 'text',
                  label: 'Price per kg',
                  labelIntl: 'forms:labels.pricePerKg',
                  width: [1, 1, 1 / 2, 1 / 3],
                  component: NumberInput,
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  format: roundCurrency,
                  subscribeToValues: true,
                  endAdornment: '€/kg',
                },
                {
                  name: 'partSets[0].bowlLinerPrice',
                  label: 'Price',
                  labelIntl: 'forms:labels.price',
                  type: 'text',
                  subscribeToValues: true,
                  width: [1, 1, 1 / 2, 1 / 3],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  format: roundCurrency,
                  component: NumberInput,
                  endAdornment: '€',
                  disabled: true,
                },
              ],
            },
          ],
        },
        {
          id: 'changeoutcosts',
          title: 'Change out cost',
          titleIntl: 'forms:headings.changeOut',
          width: 1,
          subsections: [
            {
              id: 'changeOutCost',
              fields: [
                {
                  name: 'partSets[0].id',
                  hidden: true,
                  component: Input,
                },
                {
                  name: 'partSets[0].changeOutCostOfSegment',
                  label: 'Change out costs',
                  labelIntl: 'forms:labels.changeOutCostOfSegment',
                  type: 'text',
                  subscribeToValues: true,
                  width: [1, 1, 1 / 2, 1 / 3],
                  parse: parseNumberInput,
                  format: roundCurrency,
                  component: NumberInput,
                  endAdornment: '€',
                  tooltip:
                    'The total cost of replacing the mantle and bowl liner, including labor and materials like backing.',
                  tooltipIntl: 'tooltips:changeOutCostOfSegmentCC',
                },
                {
                  name: 'partSets[0].otherCostsOfSegmentChange',
                  label: 'Other changeout costs',
                  labelIntl: 'forms:labels.otherCostsOfSegmentChange',
                  type: 'text',
                  subscribeToValues: true,
                  width: [1, 1, 1 / 2, 1 / 3],
                  parse: parseNumberInput,
                  format: roundCurrency,
                  component: NumberInput,
                  endAdornment: '€',
                  tooltip:
                    'Indirect costs, such as hiring a crane for lifting or a contractor to do the crushing.',
                  tooltipIntl: 'tooltips:otherCostsOfSegmentChangeCC',
                },
                {
                  name: 'partSets[0].segmentChangeOutDowntime',
                  label: 'Downtime',
                  labelIntl: 'forms:labels.segmentChangeOutDowntime',
                  type: 'text',
                  width: [1, 1, 1 / 2, 1 / 3],
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'hours',
                  tooltip:
                    'How many hours does it take to replace the mantle and the bowl liner? In other words, for how many hours is the crusher out of operation?',
                  tooltipIntl: 'tooltips:segmentChangeOutDowntimeCC',
                },
              ],
            },
          ],
        },
      ],
    },
    // step 5
    {
      id: 5,
      title: 'Grinding process',
      titleIntl: 'tabs.grinding',
      sections: [
        {
          id: 'grindingProcess',
          subsections: [
            {
              id: 'grindingProcessSub',
              width: 1,
              fields: [
                {
                  name: 'grinding.id',
                  type: 'text',
                  hidden: true,
                  component: Input,
                },
                {
                  name: 'grinding.millOperatingHoursPerDay',
                  label: 'Mill operating hours',
                  labelIntl: 'forms:labels.grinding.millOperatingHoursPerDay',
                  type: 'text',
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'hours/day',
                  width: [1, 1 / 2, 1 / 2, 1 / 4],
                  validate: validate(number().min(0).max(24).nullable()),
                },
                {
                  name: 'grinding.millOperatingDaysPerYear',
                  label: 'Mill operating days',
                  labelIntl: 'forms:labels.grinding.millOperatingDaysPerYear',
                  type: 'text',
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: 'days/year',
                  width: [1, 1 / 2, 1 / 2, 1 / 4],
                  validate: validate(number().min(0).max(366).nullable()),
                },
                {
                  name: 'grinding.energyCost',
                  label: 'Energy Cost',
                  labelIntl: 'forms:labels.grinding.energyCost',
                  type: 'text',
                  subscribeToValues: true,
                  endAdornment: '€/kWh',
                  width: [1, 1 / 2, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  format: roundCurrency,
                  component: NumberInput,
                  tooltip: 'What is the cost of electric energy for this site?',
                  tooltipIntl: 'tooltips:energyCost',
                  enabledAfterAnalysis: true,
                },
                {
                  name: 'grinding.millLiningGrindingMediaCosts',
                  label: 'Mill lining/grinding media costs',
                  labelIntl:
                    'forms:labels.grinding.millLiningGrindingMediaCosts',
                  type: 'text',
                  subscribeToValues: true,
                  endAdornment: '€/year',
                  width: [1, 1 / 2, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  format: roundCurrency,
                  component: NumberInput,
                },
                {
                  name: 'grinding.capacityOfMills',
                  label: 'Capacity of mill(s)',
                  labelIntl: 'forms:labels.grinding.capacityOfMills',
                  type: 'text',
                  endAdornment: 'tonnes/hour',
                  width: [1, 1 / 2, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  tooltip: 'What is the current throughput of the mill(s)?',
                  tooltipIntl: 'tooltips:capacityOfMills',
                },
                {
                  name: 'grinding.bondWorkIndexOfOre',
                  label: 'Bond work index of ore',
                  labelIntl: 'forms:labels.grinding.bondWorkIndexOfOre',
                  type: 'text',
                  endAdornment: 'kWh/Mt',
                  width: [1, 1 / 2, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                  tooltip: 'bondWorkIndexOfOre',
                },
                {
                  name: 'grinding.p80ProductGradation',
                  label: 'p80 product gradation',
                  labelIntl: 'forms:labels.grinding.p80ProductGradation',
                  type: 'text',
                  endAdornment: 'microns',
                  width: [1, 1 / 2, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                },
                {
                  name: 'grinding.grindingFactor',
                  label: 'Grinding factor',
                  labelIntl: 'forms:labels.grinding.grindingFactor',
                  type: 'text',
                  options: grindingFactorOptions,
                  validate: validate(string().nullable()),
                  component: SelectInput,
                  placeholder: 'Select one',
                  autoComplete: 'off',
                  format: (val) =>
                    grindingFactorOptions.find((item) => item.value === val),
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  width: [1, 1 / 2, 1 / 2, 1 / 4],
                },
                {
                  name: 'grinding.millingCircuitFactor',
                  label: 'Milling circuit factor',
                  labelIntl: 'forms:labels.grinding.millingCircuitFactor',
                  type: 'text',
                  options: millingCircuitFactorOptions,
                  validate: validate(string().nullable()),
                  component: SelectInput,
                  placeholder: 'Select one',
                  autoComplete: 'off',
                  format: (val) =>
                    millingCircuitFactorOptions.find(
                      (item) => item.value === val,
                    ),
                  parse: (obj) => (obj.value === '' ? null : obj.value),
                  width: [1, 1 / 2, 1 / 2, 1 / 4],
                },
                {
                  name: 'grinding.safetyFactor',
                  label: 'Safety factor',
                  labelIntl: 'forms:labels.grinding.safetyFactor',
                  type: 'text',
                  width: [1, 1 / 2, 1 / 2, 1 / 4],
                  validate: validate(number().nullable()),
                  parse: parseNumberInput,
                  component: NumberInput,
                },
                {
                  name: 'grinding.shareOfEnergySavings',
                  label: 'Share of energy savings',
                  labelIntl: 'forms:labels.grinding.shareOfEnergySavings',
                  type: 'text',
                  parse: parseNumberInput,
                  component: NumberInput,
                  endAdornment: '%',
                  width: [1, 1 / 2, 1 / 2, 1 / 4],
                  validate: validate(number().min(0).max(100).nullable()),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
