import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Flex } from 'rebass';
import { useFormState } from 'react-final-form';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { OnChange } from 'react-final-form-listeners';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import RenderField from '../../RenderField';
import { Subsection } from '../../../ts/interfaces';
import { formatNumber } from '../../../utils/formatNumber';
import { MantlePiece } from '../../../generated/graphql';

const Label = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeH4};
  letter-spacing: 0.06em;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.textBlack};
  font-weight: bolder;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-bottom: 0;
  }
`;

const P = styled.p`
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 1rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-bottom: 0;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledPaper = styled(({ formExpanded, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Paper {...props} />
))`
  padding: ${props => props.theme.muiTheme.spacing(2)}px;
  padding-top: ${props => props.theme.muiTheme.spacing(2)}px;
  margin-bottom: ${props =>
    props.noMargin ? 0 : props.theme.muiTheme.spacing(2)}px;

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    padding: ${props => props.theme.muiTheme.spacing(3)}px;
    padding-top: ${props => props.theme.muiTheme.spacing(2)}px;
    padding-bottom: ${props =>
      props.formExpanded ? props.theme.muiTheme.spacing(2) : 0}px;
    margin-bottom: ${props =>
      props.noMargin ? 0 : props.theme.muiTheme.spacing(4)}px;
    width: 100%;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ConfigTitleWrapper = styled(({ formExpanded, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Flex {...props} />
))`
  border-bottom: 1px solid
    ${({ formExpanded, theme }) =>
      formExpanded ? theme.colors.divider : 'transparent'};
  padding-bottom: ${({ theme }) => theme.muiTheme.spacing(2)}px;
  margin-bottom: ${({ formExpanded, theme }) =>
    formExpanded ? theme.muiTheme.spacing(4) : 4}px;
`;

interface PgMantleConfigurationProps {
  title: string;
  titleIntl: string;
  subsections: Subsection[];
  push: (val: string) => void;
  pop: (val: string) => void;
  setValue: (name: string, value: string | number) => void;
  setArrayInputFieldValue: (
    name: string,
    numOfRows: number,
    type: string,
  ) => void;
  disabledAfterAnalysis: boolean;
}

const PgMantleConfiguration = ({
  title,
  titleIntl,
  subsections,
  setValue,
  setArrayInputFieldValue,
  disabledAfterAnalysis,
}: PgMantleConfigurationProps) => {
  const { t } = useTranslation('forms');
  const numberOfMantlePiecesSubsection = subsections.find(
    subsection => subsection.id === 'numberOfMantlePieces',
  );

  const formSubsection = subsections.find(
    subsection => subsection.id === 'pgMantlesPerSetForm',
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { fields, component: PgMantlesPerSetForm, ...rest } =
    formSubsection || {};
  const { values } = useFormState();

  const numberOfMantlePieces = values?.partSets?.[0]?.numberOfMantlePieces || 0;

  const totalMantlePiecesPrice = (
    values?.partSets?.[0]?.mantlePieces || []
  ).reduce((total: number, item: MantlePiece) => {
    return total + (item?.price || 0);
  }, 0);

  return (
    <StyledPaper elevation={0}>
      {numberOfMantlePiecesSubsection && (
        <ConfigTitleWrapper
          justifyContent="space-between"
          alignItems="center"
          formExpanded={!!numberOfMantlePieces}
          flexDirection={['column', 'column', 'row']}
          mb={[2, 0]}
        >
          <Label>{t(titleIntl || '', title)}</Label>
          <P>
            {t(
              'forms:labels.totalPriceOfMantlesSet',
              'Total price of mantles set',
            )}{' '}
            {formatNumber(totalMantlePiecesPrice, {
              currency: values?.currency,
            })}
          </P>
          {numberOfMantlePiecesSubsection?.fields?.[0] && (
            <RenderField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...numberOfMantlePiecesSubsection?.fields?.[0]}
              disabled={disabledAfterAnalysis}
            />
          )}
        </ConfigTitleWrapper>
      )}

      {formSubsection && (
        <PgMantlesPerSetForm
          selectFields={fields}
          numberOfMantlePieces={numberOfMantlePieces}
          disabledAfterAnalysis={disabledAfterAnalysis}
        />
      )}

      <OnChange name="partSets[0].upperMantlesPerSet">
        {(value: number) => {
          setArrayInputFieldValue('partSets[0].mantlePieces', value, 'upper');
        }}
      </OnChange>
      <OnChange name="partSets[0].middleMantlesPerSet">
        {(value: number) => {
          setArrayInputFieldValue('partSets[0].mantlePieces', value, 'middle');
        }}
      </OnChange>
      <OnChange name="partSets[0].lowerMantlesPerSet">
        {(value: number) => {
          setArrayInputFieldValue('partSets[0].mantlePieces', value, 'lower');
        }}
      </OnChange>

      <OnChange name="partSets[0].numberOfMantlePieces">
        {(value: number) => {
          // Clear mantle pieces if number of mantles changes.
          if (value === 1) {
            // remove upper and lower mantle pieces
            setValue('partSets[0].upperMantlesPerSet', undefined!);
            setValue('partSets[0].lowerMantlesPerSet', undefined!);
          }

          if (value === 2) {
            // remove middle mantle pieces
            setValue('partSets[0].middleMantlesPerSet', undefined!);
          }
        }}
      </OnChange>
    </StyledPaper>
  );
};

export default PgMantleConfiguration;
