import { createMuiTheme } from '@material-ui/core/styles';
import { lighten } from 'polished';
import colors, { allColors } from './colors';

export default createMuiTheme({
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    primary: {
      main: colors.purple,
      contrastText: '#fff',
    },
    secondary: {
      light: '#000000',
      main: colors.green,
      dark: '#000000',
      contrastText: '#fff',
    },
  },
  colors: {
    black: colors.black,
    orange: colors.orange,
    green: colors.green,
    gray: colors.gray,
    turquoise: colors.turquoise,
    lightGray: colors.lightGray,
  },
  Button: {
    boxShadow: 'none',
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        '@media (min-width: 40em)': {
          paddingLeft: '3rem',
          paddingRight: '3rem',
        },
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiInputBase: {
      input: {
        padding: `10px 10px 10px 10px`,
        '&::-webkit-input-placeholder': {
          opacity: 0.5,
        },
        '&::-moz-placeholder': {
          opacity: 0.5,
        }, // Firefox 19+
        '&:-ms-input-placeholder': {
          opacity: 0.5,
        }, // IE 11
        '&::-ms-input-placeholder': {
          opacity: 0.5,
        }, // Edge
        'label[data-shrink=false] + $formControl &': {
          '&::-webkit-input-placeholder': {
            opacity: 0.5,
          },
          '&::-moz-placeholder': {
            opacity: 0.5,
          }, // Firefox 19+
          '&:-ms-input-placeholder': {
            opacity: 0.5,
          }, // IE 11
          '&::-ms-input-placeholder': {
            opacity: 0.5,
          }, // Edge
          '&:focus::-webkit-input-placeholder': {
            opacity: 0.5,
          },
          '&:focus::-moz-placeholder': {
            opacity: 0.5,
          }, // Firefox 19+
          '&:focus:-ms-input-placeholder': {
            opacity: 0.5,
          }, // IE 11
          '&:focus::-ms-input-placeholder': {
            opacity: 0.5,
          }, // Edge
        },
      },
      multiline: {
        padding: `10px 10px 10px 10px`,
        '&::-webkit-input-placeholder': {
          opacity: 0.5,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: 'black',
        fontSize: '1rem',
        textTransform: 'uppercase',
        fontWeight: '600',
        letterSpacing: '0.06em',
        marginBottom: '0px',
        position: 'relative',
        transform: 'none',
        '&$focused': {
          color: colors.black,
        },
      },
      formControl: {
        position: 'relative',
        marginBottom: '0px',
        // slight alteration to spec spacing to match visual spec result
        transform: 'translate(0) scale(1)',
      },
      shrink: {
        position: 'relative',
        marginBottom: '0px',
        // slight alteration to spec spacing to match visual spec result
        transform: 'translate(0) scale(1)',
      },
    },
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: 5,
        },
      },
      root: {
        marginTop: '5px',
        border: `1px solid ${colors.lightGray}`,
        '&$focused': {
          border: `1px solid ${colors.gray}`,
        },
        '&$error': {
          border: `1px solid ${allColors.red}`,
          backgroundColor: lighten(0.5, allColors.red),
        },
      },
    },
    MuiSelect: {
      root: {
        borderBottom: 0,
        paddingRight: 10,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: colors.bgExtraLightGray,
        boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.0449785)',
        color: colors.black,
        fontSize: '1rem',
        fontWeight: 'normal',
      },
    },
  },
});
