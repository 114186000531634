export default {
  h1FontSizeDesktop: '5rem',
  h1FontSizeTablet: '3.75rem',
  h1FontSizeMobile: '2.875rem',
  h2FontSizeDesktop: '2.25rem',
  h2FontSizeTablet: '2rem',
  h2FontSizeMobile: '1.75rem',
  h3FontSizeDesktop: '1.875rem',
  h3FontSizeTablet: '1.75rem',
  h3FontSizeMobile: '1.625rem',
  h4FontSizeDesktop: '1.5rem',
  h4FontSizeTablet: '1.25rem',
  h4FontSizeMobile: '1.125rem',
  subHeadingSizeDesktop: '1.375rem',
  subHeadingSizeTablet: '1.25rem',
  subHeadingSizeMobile: '1.125rem',
  ctaDesktop: '1rem',
  ctaTablet: '1rem',
  ctaMobile: '1rem',
  fontFamilySansSerif: `"Arial", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,

  // CNSMIN fontSizes
  lineHeight: 1.12,
  fontSizeH1: '3.75rem', // 60px
  fontSizeHeading900: '2.1rem', // 36px
  fontSizeHeading850: '2rem', // 34px
  fontSizeHeading800: '1.8rem', // 32px
  fontSizeH3: '1.65rem', // 28px
  fontSizeHeading300: '1.4rem', // 24px
  fontSizeH4: '1.15rem', // 20px,
  fontSizeMediaLiftup: '1rem', // 16px,
  fontSizeLiftupBody: '1.0rem', // 18px,
  fontSizeTag: '0.875rem', // 14px,
  fontSizeSmall100: '0.75rem', // 12px
};
