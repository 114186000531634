import React, { Fragment } from 'react';
import { Flex, Text } from 'rebass';
import { useTranslation } from 'react-i18next';

import ConditionalField from '../ConditionalField';
import RenderField from '../../RenderField';
import { Field as FieldInterface } from '../../../ts/interfaces';

interface Props {
  fields: FieldInterface[];
}

const ImprovementTargetsOtherInfo = ({ fields }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" mt={[3, 4]}>
      <Text
        px={2}
        mb={3}
        fontSize="1.75rem"
        fontFamily="Arial"
        fontWeight="lighter"
        color="green"
      >
        {t('forms:headings.otherInfo', 'Other information')}
      </Text>
      {fields.map(({ condition, ...field }: FieldInterface) => (
        <Fragment key={field?.name}>
          {condition ? (
            <ConditionalField when={condition} is>
              <RenderField {...field} flexDirection="column" />
            </ConditionalField>
          ) : (
            <RenderField {...field} withoutLine />
          )}
        </Fragment>
      ))}
    </Flex>
  );
};

export default ImprovementTargetsOtherInfo;
