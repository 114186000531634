/* eslint-disable no-param-reassign */
import { Action, action } from 'easy-peasy';

type Nav = {
  content: object | null;
  buttons: object | null;
  showHamburger: boolean;
  backUrl: string | null;
  backButton: any | null;
};

export interface Model {
  nav: Nav;
  updateNav: Action<Model, any>;
  crusherType: string;
  setCrusherType: Action<Model, string>;
}

const model: Model = {
  nav: {
    content: null,
    buttons: null,
    showHamburger: true,
    backUrl: null,
    backButton: null,
  },
  updateNav: action((state, payload) => {
    state.nav = payload;
  }),
  crusherType: 'pg',
  setCrusherType: action((state, payload) => {
    state.crusherType = payload;
  }),
};

export default model;
