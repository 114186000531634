import styled from 'styled-components';
export { default as Button } from './Button';
export { default as Wrapper } from './Wrapper';
export { default as NumberButton } from './NumberButton';

export const Tab = styled.p`
  color: ${({ active, theme }) =>
    active ? theme.colors.orange : theme.colors.gray};
  text-transform: uppercase;
  padding: 15px 20px;
  border-bottom: 4px solid transparent;
  border-color: ${({ active, theme }) =>
    active ? theme.colors.orange : 'transparent'};
  cursor: pointer;
  margin: 0;
`;
