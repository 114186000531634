import React from 'react';
import { string, shape, bool } from 'prop-types';
import styled from 'styled-components/macro';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Text, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';

import { allColors } from '../../../theme/colors';

const HorizontalLine = styled.div`
  width: 220px;
  background: ${allColors.lineLight};
  padding: 0.9px;
`;

const InvertedLabel = styled(FormControlLabel)`
  margin-bottom: 0;
`;

const OrangeCheckbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: allColors.brightOrange,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CustomCheckbox = ({
  input: { checked, onChange, ...restInput },
  meta,
  label,
  endAdornment,
  withoutLine,
  invertedCheckbox,
  ...rest
}) => {
  const { t } = useTranslation('forms');

  if (invertedCheckbox) {
    return (
      <Flex alignItems="center">
        <Text fontWeight="lighter" fontSize=".9rem" mr={[2, 3]}>
          {label}
        </Text>
        <InvertedLabel
          label={
            <Text fontWeight="lighter" fontSize=".9rem">
              {t('options.yes', 'Yes')}
            </Text>
          }
          control={
            <OrangeCheckbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              {...rest}
              inputProps={restInput}
              onChange={onChange}
              checked={Boolean(checked)}
            />
          }
        />
      </Flex>
    );
  }
  return (
    <Flex flexDirection="column">
      <FormControlLabel
        label={
          <Text fontWeight="lighter" fontSize=".9rem">
            {label}
          </Text>
        }
        control={
          <OrangeCheckbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            {...rest}
            inputProps={restInput}
            onChange={onChange}
            checked={Boolean(checked)}
          />
        }
      />
      {!withoutLine && <HorizontalLine />}
    </Flex>
  );
};

CustomCheckbox.defaultProps = {
  type: 'checkbox',
  error: null,
  label: '',
  input: undefined,
  meta: null,
  endAdornment: '',
  withoutLine: undefined,
  invertedCheckbox: false,
};

CustomCheckbox.propTypes = {
  type: string,
  error: string,
  meta: shape({}),
  input: shape({}),
  label: string,
  endAdornment: string,
  withoutLine: bool,
  invertedCheckbox: bool,
};

export default CustomCheckbox;
