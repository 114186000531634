import React from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components/macro';
import { Flex } from 'rebass';
import { useTranslation } from 'react-i18next';

import colors from '../../../theme/colors';
import Icon from '../../Icon';
import Tooltip from '../../Tooltip';
import { Asterisk } from '../Input';
import { Option } from '../../../data/selectOptions';
import { getUser } from '../../../utils/auth';

interface SelectInputProps {
  color?: string;
  options?: Record<string, any>[];
  errorMessage?: string;
  meta?: {
    touched: boolean;
    error: string;
  };
  input?: any;
  name?: string;
  value: any;
  onChange?: (args: any) => any;
  label?: string;
  styles?: Record<string, any>;
  isClearable?: boolean;
  tooltip?: string;
  tooltipIntl?: string;
  mandatoryDesign?: boolean;
  mandatoryValueStudy?: boolean;
  placeholder?: string;
}

const StyledSelect = styled(Select)`
  padding: 5px 0 0 0;
  color: ${props => (props.color ? props.color : colors.black)};
  display: flex;
  flex-direction: column;
  & > div {
    box-shadow: none;
    border: 1px solid
      ${props => (props.borderColor ? props.borderColor : colors.divider)};
    border-radius: 0px;
    width: 100%;
    font-size: 0.875rem;
    &:hover {
      cursor: pointer;
      border: 1px solid
        ${props => (props.borderColor ? props.borderColor : colors.bgLightGray)};
    }
    &:disabled {
      border: 1px solid
        ${props => (props.borderColor ? props.borderColor : colors.divider)};
      background: ${props =>
        props.backgroundColor ? props.backgroundColor : colors.lightGray};
    }
  }
  & > span + div {
    background: ${props =>
      props.error ? colors.lightTerracotta : colors.white};
    border: 1px solid
      ${props => (props.borderColor ? props.borderColor : colors.divider)};
    ${props => props.error && `border-color: ${colors.darkTerracotta};`};
  }
`;

export const StyledLabel = styled.label`
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 600;
  margin-bottom: 3px;
`;

const customStyles = {
  option: (base: object, state: { isFocused: boolean }) => ({
    ...base,
    backgroundColor: state.isFocused ? `${colors.extraLightGray}` : 'white',
    color: `${colors.black}`,
  }),
  dropdownIndicator: (base: object, state: { isFocused: boolean }) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: () => ({
    opacity: 0.3,
  }),
  control: (base: any, state: { isDisabled: boolean }) => ({
    ...base,
    ...(state.isDisabled && { backgroundColor: colors.lightGray }),
  }),
};

const Error = styled.p`
  i {
    font-size: 1.2rem;
    margin-right: 5px;
  }
  display: flex;
  align-items: center;
  color: ${colors.darkTerracotta};
  margin-bottom: 8px;
  font-size: 0.75rem;
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
// eslint-disable-next-line react/jsx-props-no-spreading
const Input = ({ ...props }) => <components.Input {...props} />;

const SelectInput: React.FC<SelectInputProps> = ({
  color = `${colors.black}`,
  meta = {},
  input = {},
  label,
  styles,
  tooltip,
  tooltipIntl,
  mandatoryDesign,
  mandatoryValueStudy,
  options = [],
  ...rest
}) => {
  const { t } = useTranslation(['forms', 'tooltips']);
  let filteredOptions = options;
  const { user } = getUser();

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (
    input &&
    input?.name === 'customer.country' &&
    user.role === 'marketArea'
  ) {
    const { allowedCountries } = user;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (input.value && input.value.value) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      allowedCountries.push(input.value.value);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filteredCountries = options?.filter((option: any) =>
      allowedCountries.includes(option?.value),
    );
    filteredOptions = filteredCountries || options;
  }
  return (
    <Flex flexDirection="column">
      <Flex alignItems="center">
        <StyledLabel htmlFor={input?.name}>{label}</StyledLabel>
        {mandatoryDesign && <Asterisk>*</Asterisk>}
        {mandatoryValueStudy && <Asterisk color="green">*</Asterisk>}
        {tooltip && <Tooltip tooltip={t(tooltipIntl || '', tooltip)} />}
      </Flex>
      <StyledSelect
        inputId={input?.name}
        error={meta && meta.error && meta.touched}
        color={color}
        styles={{ ...customStyles, styles }}
        blurInputOnSelect
        options={
          filteredOptions && filteredOptions.length
            ? filteredOptions.map(
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                ({ labelIntl, label: optionLabel, value }: Option) => ({
                  label: t(labelIntl || '', optionLabel),
                  value,
                }),
              )
            : []
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        // @ts-ignore
        value={{
          label: t(
            input?.value?.labelIntl || 'forms:options.selectOne',
            input?.value?.label,
          ),
          value: input?.value?.value || '',
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        components={{ Input }}
        // @ts-ignore
        isDisabled={rest?.disabled}
      />
      <Error>
        {meta && meta.touched && meta.error && (
          <>
            <Icon name="error_outline" color={colors.darkTerracotta} />{' '}
            {meta.error}
          </>
        )}
        &nbsp;
      </Error>
    </Flex>
  );
};

export default SelectInput;
