import React, { useState } from 'react';
import { Paper, IconButton } from '@material-ui/core';
import {
  shape,
  arrayOf,
  string,
  element,
  number,
  oneOfType,
  func,
} from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
// @ts-ignore
import { OnChange } from 'react-final-form-listeners';
import { Box, Flex } from 'rebass';
import styled from 'styled-components/macro';
import { DeleteSharp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import onePieceMantleIconSrc from '../../../images/thumbs/1-pieces-upper-mantle.svg';
import twoPiecesUpperMantleIconSrc from '../../../images/thumbs/2-pieces-upper-mantle.svg';
import twoPiecesLowerMantleIconSrc from '../../../images/thumbs/2-pieces-lower-mantle.svg';
import threePiecesUpperMantleIconSrc from '../../../images/thumbs/3-pieces-upper-mantle.svg';
import threePiecesMiddleMantleIconSrc from '../../../images/thumbs/3-pieces-middle-mantle.svg';
import threePiecesLowerMantleIconSrc from '../../../images/thumbs/3-pieces-lower-mantle.svg';
import { Field as FieldInterface } from '../../../ts/interfaces';
import { MantlePiece, Case } from '../../../generated/graphql';

const propTypes = {
  fields: arrayOf(
    shape({
      name: string.isRequired,
      arrayFields: arrayOf(
        shape({
          name: string,
          placeholder: string,
          type: string,
          component: oneOfType([element, func]),
          width: arrayOf(number),
        }),
      ),
    }),
  ).isRequired,
  label: string,
};

const defaultProps = {
  label: '',
};

const MantlePaper = styled(Paper)`
  margin-bottom: ${({ theme }) => theme.muiTheme.spacing(2)}px;

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    margin-bottom: ${({ theme }) => theme.muiTheme.spacing(4)}px;
  }
`;

const MantleLabel = styled.p`
  text-transform: uppercase;
  letter-spacing: 0.06em;
  margin-bottom: 0;
`;

const MantleRow = styled(Flex)`
  &:nth-child(even) {
    background: ${({ theme }) => theme.colors.bgExtraLightGray};
  }
`;

const SectionTitle = styled.span`
  letter-spacing: 1.5px;
  font-size: ${({ theme }) => theme.typography.fontSizeH4};
  font-weight: bolder;
  white-space: nowrap;
`;

interface MantleArrayInput {
  fields: FieldInterface[];
  label: string;
  labelIntl?: string;
  remove: (name: string, index: number) => void;
  reduceByOne: (name: string) => void;
  disabledAfterAnalysis: boolean;
}

const MantleArrayInput = ({
  fields: subsectionFields,
  label,
  labelIntl,
  remove,
  reduceByOne,
  disabledAfterAnalysis,
}: MantleArrayInput) => {
  const [numberOfPieces, setNumberOfPieces] = useState(0);
  const { t } = useTranslation('forms');
  const mantleIcons = {
    upper:
      numberOfPieces === 2
        ? twoPiecesUpperMantleIconSrc
        : threePiecesUpperMantleIconSrc,
    middle:
      numberOfPieces === 1
        ? onePieceMantleIconSrc
        : threePiecesMiddleMantleIconSrc,
    lower:
      numberOfPieces === 2
        ? twoPiecesLowerMantleIconSrc
        : threePiecesLowerMantleIconSrc,
  };

  const handleDeletePiece = (type: string, index: number) => {
    remove('partSets[0].mantlePieces', index);

    if (type === 'upper') {
      reduceByOne(`partSets[0].upperMantlesPerSet`);
    }

    if (type === 'middle') {
      reduceByOne(`partSets[0].middleMantlesPerSet`);
    }

    if (type === 'lower') {
      reduceByOne(`partSets[0].lowerMantlesPerSet`);
    }
  };

  const renderMantles = (
    mantlePiecesArray: MantlePiece[],
    arrayFields: FieldInterface[],
    values: Partial<Case>,
    type: 'upper' | 'middle' | 'lower',
    startIndex: number,
  ) =>
    mantlePiecesArray?.length ? (
      <MantlePaper elevation={0} key={type}>
        <Flex flexDirection="column" width={1}>
          <Flex justifyContent="space-between" alignItems="center" px={[2, 4]}>
            <Flex alignItems="center">
              <img src={mantleIcons[type]} alt="mantle icon" />
              <MantleLabel>
                {numberOfPieces === 1
                  ? t(labelIntl || '', label)
                  : `${t(`forms:labels.${type}`, type)} ${t(
                      labelIntl || '',
                      label,
                    )}`}
              </MantleLabel>
            </Flex>
            <MantleLabel>
              {t('common:strings.pieceCount', 'Piece count')}{' '}
              {mantlePiecesArray.length}
            </MantleLabel>
          </Flex>

          {mantlePiecesArray.map((piece, index) => (
            <MantleRow
              width={1}
              flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}
              key={piece?.id || index}
              px={[1, 4]}
              pt={[3, 3, 3]}
              alignItems="center"
            >
              <Box px={2} width={[1, 1, 1, 'auto']}>
                <SectionTitle>
                  {t(labelIntl || '', label)}
                  {` ${index + 1}`}
                </SectionTitle>
              </Box>
              <Flex flexWrap="wrap" width={1}>
                {arrayFields.map(
                  ({
                    name: fieldName,
                    label: fieldLabel,
                    labelIntl: fieldLabelIntl,
                    endAdornment,
                    disabled,
                    ...i
                  }) => (
                    <Box
                      key={i.id || fieldName}
                      p={2}
                      width={i.width}
                      style={{ display: i.hidden ? 'none' : 'block' }}
                    >
                      <Field
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...i}
                        label={t(fieldLabelIntl || '', fieldLabel)}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(endAdornment &&
                        endAdornment.includes('€') &&
                        values?.currency
                          ? {
                              endAdornment: t(
                                `forms:adornments.${endAdornment}`,
                                endAdornment,
                              ).replace('€', values.currency),
                            }
                          : {
                              endAdornment:
                                endAdornment &&
                                t(
                                  `forms:adornments.${endAdornment}`,
                                  endAdornment,
                                ),
                            })}
                        name={`partSets[0].mantlePieces[${startIndex +
                          index}].${fieldName}`}
                        disabled={disabled || disabledAfterAnalysis}
                      />
                    </Box>
                  ),
                )}
              </Flex>
              <IconButton
                type="button"
                onClick={() => {
                  handleDeletePiece(type, startIndex + index);
                }}
                disabled={disabledAfterAnalysis}
              >
                <DeleteSharp />
              </IconButton>
            </MantleRow>
          ))}
        </Flex>
      </MantlePaper>
    ) : null;

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <OnChange name="partSets[0].numberOfMantlePieces">
        {(value: number, previous: number) => {
          if (value !== previous) {
            setNumberOfPieces(value);
          }
        }}
      </OnChange>
      <FormSpy subscription={{ values: true }}>
        {({ values }) => (
          <>
            {subsectionFields.map(({ name, arrayFields }) => (
              <FieldArray key={name} name={name}>
                {({ fields }) => {
                  const upperMantles = fields?.value?.filter(
                    item => item.type === 'upper',
                  );
                  const middleMantles = fields?.value?.filter(
                    item => item.type === 'middle',
                  );
                  const lowerMantles = fields?.value?.filter(
                    item => item.type === 'lower',
                  );
                  return (
                    <>
                      {renderMantles(
                        upperMantles,
                        arrayFields!,
                        values,
                        'upper',
                        0,
                      )}
                      {renderMantles(
                        middleMantles,
                        arrayFields!,
                        values,
                        'middle',
                        upperMantles?.length || 0,
                      )}
                      {renderMantles(
                        lowerMantles,
                        arrayFields!,
                        values,
                        'lower',
                        upperMantles?.length + middleMantles?.length,
                      )}
                    </>
                  );
                }}
              </FieldArray>
            ))}
          </>
        )}
      </FormSpy>
    </>
  );
};

MantleArrayInput.propTypes = propTypes;
MantleArrayInput.defaultProps = defaultProps;

export default MantleArrayInput;
