import { ApolloClient } from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { ApolloLink, Observable, split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import { getTokens, setSession, ID_TOKEN, logOut } from '../../utils/auth';

const urlPrefix =
  process.env.NODE_ENV !== 'production' ? 'http://localhost:5002' : '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetcher = (...args: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return window.fetch(...args);
};

const cache = new InMemoryCache();

const httpUrl = `${
  process.env.NODE_ENV !== 'production' ? 'http://' : 'https://'
}${process.env.REACT_APP_HASURA_ENDPOINT_URL}/v1/graphql`;
const wsUrl = `${process.env.NODE_ENV !== 'production' ? 'ws://' : 'wss://'}${
  process.env.REACT_APP_HASURA_ENDPOINT_URL
}/v1/graphql`;

const wsLink = new WebSocketLink(
  new SubscriptionClient(wsUrl, {
    reconnect: true,
    connectionParams: () => {
      const tokens = getTokens();
      const idToken = tokens[ID_TOKEN] || '';
      return { headers: { Authorization: `Bearer ${idToken}` } };
    },
  }),
);

const httpLink = new HttpLink({
  uri: httpUrl,
  fetch: fetcher,
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation }: any = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const request = async (operation: any) => {
  // eslint-disable-next-line prefer-const
  let { idToken, ...rest } = getTokens();
  if (!idToken) {
    return logOut();
  }
  const encodedToken = jwt.decode(idToken, { json: true });
  // If token has less than half an hour time left lets try to renew it
  if (
    typeof encodedToken !== 'string' &&
    encodedToken &&
    encodedToken.exp &&
    Math.floor(Date.now() / 1000) + 1800 > encodedToken.exp
  ) {
    try {
      const newToken = await axios.post(`${urlPrefix}/auth/metso/refresh`, {
        idToken,
      });
      const { expiresAt } = (newToken && newToken.data) || {
        expiresAt: rest.expiresAt,
        idToken: encodedToken,
      };
      idToken = newToken && newToken.data && newToken.data.idToken;
      setSession({ idToken, expiresAt, user: rest.user });
    } catch (err) {
      logOut();
    }
  }
  return operation.setContext({
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
};

const renewAutomatically = () => {
  setInterval(async () => {
    // eslint-disable-next-line prefer-const
    let { idToken, ...rest } = getTokens();
    if (!idToken) {
      return logOut();
    }
    const encodedToken = jwt.decode(idToken, { json: true });
    // If token has less than half an hour time left lets try to renew it
    if (
      typeof encodedToken !== 'string' &&
      encodedToken &&
      encodedToken.exp &&
      Math.floor(Date.now() / 1000) + 1800 > encodedToken.exp
    ) {
      try {
        const newToken = await axios.post(`${urlPrefix}/auth/metso/refresh`, {
          idToken,
        });
        const { expiresAt } = (newToken && newToken.data) || {
          expiresAt: rest.expiresAt,
          idToken: encodedToken,
        };
        idToken = newToken && newToken.data && newToken.data.idToken;
        setSession({ idToken, expiresAt, user: rest.user });
      } catch (err) {
        logOut();
      }
    }
    // run every 15 minutes
  }, 1000 * 60 * 15);
};
renewAutomatically();

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    }),
);

const client = new ApolloClient({
  link: ApolloLink.from([requestLink, link]),
  cache,
  // typeDefs,
  // resolvers,
  defaultOptions: {
    // graphql() hoc uses watchQuery
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'cache-first',
    },
  },
});

export default client;
