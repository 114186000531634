import React from 'react';
import {
  number,
  func,
  shape,
  bool,
  string,
  oneOfType,
  arrayOf,
} from 'prop-types';
import { Flex, Box } from 'rebass';
import styled from 'styled-components/macro';
import { FieldRenderProps } from 'react-final-form';

import { NumberButton } from '../../common';
import { Error, Asterisk } from '../Input';
import colors from '../../../theme/colors';
import Icon from '../../Icon';
import Tooltip from '../../Tooltip';

const propTypes = {
  minNumber: number,
  maxNumber: oneOfType([number, string]),
  label: string,
  input: shape({
    value: oneOfType([number, string]),
    onChange: func.isRequired,
  }),
  marginBottom: number,
  alignment: oneOfType([string, arrayOf(string)]),
  disabled: bool,
};

const defaultProps = {
  minNumber: 0,
  maxNumber: 1,
  input: {
    value: 1,
  },
  label: '',
  marginBottom: 5,
  alignment: 'center',
  disabled: false,
};

const Label = styled.p`
  text-transform: uppercase;
  letter-spacing: 0.06em;
  margin-bottom: ${({ theme }) => theme.muiTheme.spacing(2)}px;
  color: ${({ theme }) => theme.colors.gray};
  font-weight: 600;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    margin-bottom: 0;
  }
`;

interface NumberOfPiecesProps
  extends FieldRenderProps<string, HTMLInputElement> {
  minNumber?: number;
  maxNumber: number;
  label: string;
  marginBottom?: number | number[];
  alignment?: string | string[];
  mandatoryDesign?: boolean;
  tooltip?: string;
  tooltipIntl?: string;
  disabled?: boolean;
}

const NumberOfPieces = ({
  minNumber,
  maxNumber,
  input,
  label,
  marginBottom,
  alignment,
  mandatoryDesign,
  meta,
  tooltip,
  tooltipIntl,
  disabled,
}: NumberOfPiecesProps) => {
  return (
    <Box mb={marginBottom}>
      <Flex
        alignItems={['center']}
        justifyContent={alignment}
        width={1}
        flexWrap="wrap"
      >
        <Flex alignItems={['center']} justifyContent={['start']}>
          {label && (
            <Label>
              {label}
              {mandatoryDesign && <Asterisk>*</Asterisk>}
            </Label>
          )}
          {tooltip && <Tooltip tooltip={tooltip} tooltipIntl={tooltipIntl} />}
        </Flex>
        <Flex flexWrap="wrap">
          {Array.from({ length: maxNumber - (minNumber || 0) }, (_, i) => (
            <NumberButton
              key={i + 1}
              selected={+input.value === (minNumber || 0) + i + 1}
              onClick={e => {
                e.preventDefault();
                input.onChange((minNumber || 0) + i + 1);
              }}
              disabled={disabled}
            >
              {(minNumber || 0) + i + 1}
            </NumberButton>
          ))}
        </Flex>
      </Flex>
      <Error hidden={!meta?.error}>
        {meta?.error && (
          <>
            <Icon name="error_outline" color={colors.darkTerracotta} />{' '}
            {meta.error}
          </>
        )}
        &nbsp;
      </Error>
    </Box>
  );
};

NumberOfPieces.propTypes = propTypes;
NumberOfPieces.defaultProps = defaultProps;

export default NumberOfPieces;
