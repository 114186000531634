import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components/macro';
import { Box, Flex } from 'rebass';
import { func, arrayOf, shape } from 'prop-types';
// @ts-ignore
import { OnChange } from 'react-final-form-listeners';
import { IconButton } from '@material-ui/core';
import { DeleteSharp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { Field as FieldInterface } from '../../../ts/interfaces';

const propTypes = {
  fields: arrayOf(shape({})).isRequired,
  setArrayInputFieldValue: func.isRequired,
};

const TierTitle = styled.p`
  align-self: center;
  letter-spacing: 1.5px;
  font-size: ${({ theme }) => theme.typography.fontSizeH4};
  font-weight: bolder;
  white-space: nowrap;
  padding-left: ${({ theme }) => theme.muiTheme.spacing(2)}px;
  margin-bottom: 0;

  span {
    display: block;
    font-weight: lighter;
    font-size: ${({ theme }) => theme.typography.fontSizeMediaLiftup};
    letter-spacing: normal;
  }
`;

const FlexWithBorder = styled(Flex)`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.bgExtraLightGray};
  }
`;

interface PgTiersArrayInput {
  fields: FieldInterface[];
  setArrayInputFieldValue: (name: string, numberOfRows: number) => void;
  remove: (name: string, index: number) => void;
  reduceByOne: (name: string) => void;
  disabledAfterAnalysis?: boolean;
}
const PgTiersArrayInput = ({
  fields: topFields,
  setArrayInputFieldValue,
  remove,
  reduceByOne,
  disabledAfterAnalysis,
}: PgTiersArrayInput) => {
  const { t } = useTranslation('forms');
  const handleDeletePiece = (index: number) => {
    remove('partSets[0].concavePieces', index);
    reduceByOne(`partSets[0].numberOfConcaveTiers`);
  };

  return (
    <>
      <OnChange name="partSets[0].numberOfConcaveTiers">
        {(value: number) => {
          setArrayInputFieldValue('partSets[0].concavePieces', value);
        }}
      </OnChange>
      {topFields.map(({ name, label, labelIntl, arrayFields }) => (
        <FieldArray name={name} key={name}>
          {({ fields }) =>
            fields.map((cname, index) => (
              <FormSpy subscription={{ values: true }} key={cname}>
                {({ values }) => (
                  <FlexWithBorder
                    width={1}
                    flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}
                    key={cname}
                    pt={3}
                    alignItems="center"
                  >
                    <Box width={[1, 1, 'auto']}>
                      <TierTitle>
                        {t(labelIntl || '', label)}
                        {` ${index + 1}`}
                        {index === 0 && (
                          <span>
                            ({t('common:strings.bottomTier', 'bottom tier')})
                          </span>
                        )}
                      </TierTitle>
                    </Box>
                    <Flex flexWrap="wrap">
                      {arrayFields &&
                        arrayFields.map(
                          ({
                            name: fieldName,
                            label: fieldLabel,
                            labelIntl: fieldLabelIntl,
                            endAdornment,
                            disabled,
                            ...i
                          }) => (
                            <Box
                              key={i.id || fieldName}
                              p={2}
                              width={i.width}
                              style={{ display: i.hidden ? 'none' : 'block' }}
                            >
                              <Field
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...i}
                                label={t(fieldLabelIntl || '', fieldLabel)}
                                name={`${cname}.${fieldName}`}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...(endAdornment &&
                                endAdornment.includes('€') &&
                                values &&
                                values.currency
                                  ? {
                                      endAdornment: t(
                                        `forms:adornments.${endAdornment}`,
                                        endAdornment,
                                      ).replace('€', values.currency),
                                    }
                                  : {
                                      endAdornment:
                                        endAdornment &&
                                        t(
                                          `forms:adornments.${endAdornment}`,
                                          endAdornment,
                                        ),
                                    })}
                                disabled={disabled || disabledAfterAnalysis}
                              />
                            </Box>
                          ),
                        )}
                    </Flex>
                    <IconButton
                      onClick={() => handleDeletePiece(index)}
                      style={{
                        visibility:
                          Number(fields?.length) < 3 ? 'hidden' : 'visible',
                      }}
                      disabled={disabledAfterAnalysis}
                    >
                      <DeleteSharp />
                    </IconButton>
                  </FlexWithBorder>
                )}
              </FormSpy>
            ))
          }
        </FieldArray>
      ))}
    </>
  );
};

PgTiersArrayInput.propTypes = propTypes;

export default PgTiersArrayInput;
