import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StoreProvider } from 'easy-peasy';
import { ThemeProvider } from 'styled-components';
import * as LogRocket from 'logrocket';
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from '@material-ui/styles';

import { muiTheme, styledTheme } from './theme';
import GlobalStyles from './global-styles';
import client from './store/apollo';
import store from './store/easypeasy';
import PrivateRoute from './components/PrivateRoute';
import routes from './routes';
import ScrollToTop from './components/ScrollToTop';
import NavBar from './components/NavBar';
import Loading from './components/Loading';
import Layout from './components/Layout';
import MetsoToast from './components/MetsoToasts';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('metso/min-value-optimizer-app', {
    network: {
      requestSanitizer: (request: any) => {
        // if the url contains 'callback'
        if (request.url.toLowerCase().includes('callback')) {
          // eslint-disable-next-line
          request.url = request.url.split('callback')[0];
        }

        if (request.headers.Authorization) {
          request.headers.Authorization = null;
        }

        if (request.headers.authorization) {
          request.headers.authorization = null;
        }

        if (request.body && request.body.idToken) {
          request.body.idToken = null;
        }

        // otherwise log the request normally
        return request;
      },
    },
  });
}

const App = () => (
  <StylesProvider injectFirst>
    <ApolloProvider client={client}>
      <StoreProvider store={store}>
        <ThemeProvider theme={styledTheme}>
          <MuiThemeProvider theme={muiTheme}>
            <GlobalStyles />
            <Router>
              <ScrollToTop />
              <Suspense fallback={<Loading />}>
                <Route path="*" render={props => <NavBar {...props} />} />
                <Switch>
                  <Layout>
                    {routes.map(
                      ({ private: isPrivate, path, exact, component }) =>
                        isPrivate ? (
                          <PrivateRoute
                            key={path}
                            path={path}
                            exact={exact}
                            component={component}
                          />
                        ) : (
                          <Route
                            key={path}
                            path={path}
                            exact={exact}
                            component={component}
                          />
                        ),
                    )}
                  </Layout>
                </Switch>
              </Suspense>
            </Router>
            <MetsoToast />
          </MuiThemeProvider>
        </ThemeProvider>
      </StoreProvider>
    </ApolloProvider>
  </StylesProvider>
);

export default App;
